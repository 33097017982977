import React from 'react';
import c from 'classnames';
import _ from 'underscore';
import { withDefaultStyles } from '@cosman/functions';
import { Loading } from '@cosman/shared-components';
import { getStyles } from './index.styles';

const AuthenticatingPagePure = (props) => {
  const { classes } = props;

  return React.createElement(
    'div',
    { className: c(classes.welcomeMessage, 'welcome-component') },
    React.createElement('p', null, 'Welcome to Cosman!'),
    React.createElement(Loading, { isLoading: true }),
  );
};

const wrap = _.compose(
  (Component) => withDefaultStyles(Component, getStyles),
);

export const AuthenticatingPage = wrap(AuthenticatingPagePure);
