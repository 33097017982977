import { mockDataActions } from '@cosman/mock';
import { mockPolicy } from '../../mock/cogs-insight/mock-policy';
import { BaseService } from '../base-service';

export class CogsInsightService extends BaseService {
  constructor() {
    super({ serviceName: 'CogsInsight' });
  }

  injectMockPolicy() {
    mockDataActions(this.http, mockPolicy);
  }
}

export const cogsInsightService = new CogsInsightService();
