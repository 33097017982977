import { useEffect, useState } from 'react';
import { cosmanService as http } from '../../../services';

const useData = (url, alias) => {
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const ref = { cancelled: false };
    const callback = () => { ref.cancelled = true; };

    http.get(url, { params: { alias } })
      .then((response) => {
        if (ref.cancelled) {
          return;
        }

        setData(response);
        setIsLoading(false);
      })
      .catch(() => {
        if (!ref.cancelled) {
          setData();
          setIsLoading(false);
        }
      });
    return callback;
  }, [url, alias]);

  return { data, isLoading };
};

export const useOwnServices = () => {
  return useData('/v1.0/home/services/own');
};

export const useSubscribedServices = () => {
  return useData('/v1.0/home/services/subscription');
};

export const useOwnDataAssets = () => {
  return useData('/v1.0/home/data-assets/own');
};

export const useSubscribedDataAssets = () => {
  return useData('/v1.0/home/data-assets/subscription');
};

export const useNews = () => {
  return useData('/v1.0/configurations/news');
};

export const updateDatAssetSubscription = (dataAsset, subscribe) => {
  return http.post('/v1.0/home/data-assets/subscription', { dataAsset, subscribe });
};
