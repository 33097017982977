import { mockDataActions } from '@cosman/mock';
import { mockPolicy } from '../../mock/cosman/mock-policy';
import { BaseService } from '../base-service';

export class CosmanService extends BaseService {
  constructor() {
    super({ serviceName: 'Cosman' });
  }

  injectMockPolicy() {
    mockDataActions(this.http, mockPolicy);
  }
}

export const cosmanService = new CosmanService();
