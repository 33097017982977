import { mockDataActions } from '@cosman/mock';
import { mockPolicy } from '../../mock/efficiency-engine/mock-policy';
import { BaseService } from '../base-service';

export class EfficiencyEngineService extends BaseService {
  constructor() {
    super({ serviceName: 'EfficiencyEngine' });
  }

  injectMockPolicy() {
    mockDataActions(this.http, mockPolicy);
  }
}

export const efficiencyEngineService = new EfficiencyEngineService();
