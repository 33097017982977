import _ from 'underscore';
import { useEffect, useState } from 'react';

export const usePreference = (key, defaultValue) => {
  const storageValue = JSON.parse(localStorage.getItem(key));
  const [stateValue, setStateValue] = useState(_.isUndefined(storageValue) ? defaultValue : storageValue);

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(stateValue));
  }, [key, stateValue]);

  return [stateValue, setStateValue];
};
