import s from 'underscore.string';

export const formatNumber = (value, precision = 2) => {
  if (value < 0) {
    return `-${formatNumber(-value, precision)}`;
  }
  const abbreviations = ['', 'K', 'M', 'G', 'T', 'P'];
  let index = 0;
  while (value >= 1000 && index < abbreviations.length - 1) {
    // eslint-disable-next-line no-param-reassign
    value /= 1000;
    index += 1;
  }
  return `${s.numberFormat(value, precision)}${abbreviations[index]}`;
};
