export const getStyles = () => ({
  '& .error-wrapper': {
    alignItems: 'center',
    color: '#0078d4',
    display: 'flex',
    fontSize: '14px',
    height: '22px',
  },

  '& .error-wrapper .icon': {
    marginTop: '1px',
  },

  '& .error-wrapper .message': {
    marginLeft: '10px',
  },

  '& .error-wrapper.error': {
    color: '#ff0000',
  },

  '& .error-wrapper.warning': {
    color: '#ffae42',
  },
});
