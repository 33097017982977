import _ from 'underscore';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useRef, useState } from 'react';
import { Callout, DirectionalHint } from '@fluentui/react';
import { h, c } from '@cosman/utils';
import { useResize } from '@cosman/hooks';
import { withStyles } from '@cosman/functions';
import { Icon } from '../../basic';
import { SelectOptions } from './components';
import { getStyles } from './index.styles';

const SelectPure = (props) => {
  const {
    className,
    options,
    value,
    disabled,
    onChange,
  } = props;

  const labelRef = useRef(null);
  const [labelWidth] = useResize(labelRef);
  const [optionsVisible, setOptionsVisible] = useState(false);
  const [focusIndex, setFocusIndex] = useState(null);

  const selectedOptions = useMemo(() => {
    const selected = _.filter(options, (option) => _.any(value, (name) => name === option.name));
    const result = _.isEmpty(selected) ? (_.isEmpty(value) ? [] : [{ name: _.first(value), label: _.first(value), warning: true }]) : selected;

    return result;
  }, [options, value]);

  const label = useMemo(() => {
    if (_.isEmpty(selectedOptions)) {
      return null;
    }

    if (selectedOptions.length === 1) {
      return selectedOptions[0].label;
    }

    return `${selectedOptions.length} options selected`;
  }, [selectedOptions]);

  const handleToggleCollapse = useCallback(() => {
    if (!disabled) {
      setOptionsVisible(!optionsVisible);
    }
  }, [disabled, optionsVisible]);

  const handleChange = useCallback((event) => {
    setOptionsVisible(false);
    setFocusIndex(event.index);
    onChange({ options: event.options });
  }, [onChange]);

  const handleKeyDown = useCallback((event) => {
    if (event.key === 'Enter') {
      handleToggleCollapse();
    }

    if (!optionsVisible) {
      return;
    }

    if (event.key === 'ArrowUp') {
      event.preventDefault();
      setFocusIndex((prev) => (prev === null ? options.length - 1 : Math.max(0, prev - 1)));
    }

    if (event.key === 'ArrowDown') {
      event.preventDefault();
      setFocusIndex((prev) => (prev === null ? 0 : Math.min(options.length - 1, prev + 1)));
    }

    if (focusIndex === null) {
      return;
    }

    if (event.key === 'Escape') {
      setOptionsVisible(false);
    } else if (event.key === 'Enter') {
      onChange({ options: [options[focusIndex]] });
      setOptionsVisible(false);
    }
  }, [focusIndex, onChange, options, optionsVisible, handleToggleCollapse]);

  return h(
    'div',
    { className: c(className, 'select-component', { disabled }) },
    h(
      'div',
      {
        className: 'select-label',
        role: 'button',
        tabIndex: disabled ? -1 : 0,
        onClick: handleToggleCollapse,
        onKeyDown: handleKeyDown,
        ref: labelRef,
      },
      h('span', {}, label),
      h(Icon, { iconName: optionsVisible ? 'ChevronUp' : 'ChevronDown' }),
    ),
    optionsVisible && h(
      Callout,
      {
        className: 'select-options',
        target: `.${className.replace(/\s+/g, '.')} .select-label`,
        beakWidth: 0,
        gapSpace: 0,
        calloutWidth: labelWidth,
        onDismiss: handleToggleCollapse,
        directionalHint: DirectionalHint.bottomLeftEdge,
      },
      h(
        SelectOptions,
        {
          options,
          focusIndex,
          value,
          onChange: handleChange,
        },
      ),
    ),
  );
};

SelectPure.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
  })),
  value: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

SelectPure.defaultProps = {
  options: [],
  value: [],
  disabled: false,
  onChange: _.noop,
};

const wrap = _.compose(
  (Component) => withStyles(Component, getStyles),
);

export const Select = wrap(SelectPure);
