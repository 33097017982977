import _ from 'underscore';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { h } from '@cosman/utils';
import { withStyles } from '@cosman/functions';
import { dataTypes } from '../../../../config';
import { FilterGroup } from '../../../../controls';
import { getStyles } from './index.styles';

const TableFilterPure = (props) => {
  const {
    className,
    columns,
    data,
    filters,
    onChange,
  } = props;

  const fields = useMemo(() => {
    return _.chain(columns).filter((column) => {
      return !_.isUndefined(column.dataType) && !_.isNull(column.dataType);
    }).map((column) => {
      const { name, label, dataType } = column;
      const { operators } = dataTypes[dataType] || {};
      const options = dataType === 'enum' ? _.chain(data).map(name).uniq().value() : [];

      return {
        name,
        label,
        dataType,
        operators,
        options,
      };
    }).value();
  }, [columns, data]);

  const handleChange = useCallback((event) => {
    onChange({ filters: event.value, filterHandler: event.filter });
  }, [onChange]);

  return h(
    'div',
    { className },
    h(FilterGroup, { fields, value: filters, onChange: handleChange }),
  );
};

TableFilterPure.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
    aggregation: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    renderContent: PropTypes.func,
    tooltip: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    sortable: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  })).isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  filters: PropTypes.arrayOf(PropTypes.shape({
    field: PropTypes.string,
    operator: PropTypes.string,
    value: PropTypes.any,
  })),
  onChange: PropTypes.func,
};

TableFilterPure.defaultProps = {
  data: [],
  filters: [],
  onChange: _.noop,
};

const wrap = _.compose(
  (Component) => withStyles(Component, getStyles),
);

export const TableFilter = wrap(TableFilterPure);
