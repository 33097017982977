export const getStyles = () => ({
  '& .filter-group': {
    display: 'flex',
    flexFlow: 'wrap',
    margin: '0px -10px -10px 0px',
  },

  '& .filter-item': {
    margin: '0px 10px 10px 0px',
  },
});
