/* eslint-disable no-unused-vars */
/* eslint-disable global-require */

export const mockPolicy = (http) => {
  // http.get('/api/graph/environment/Cosmos/type/Job', { params: { type: 'Job',
  //   environment: 'Cosmos',
  //   includeCrossEnvironment: 'true',
  //   firstLevelResourcePool: 'Cluster-1',
  //   secondLevelResourcePool: 'VirtualCluster-1',
  //   name: 'Job-1' } }).reply(200, require('./data/search-response').default);
  // http.get('/api/graph/environment/Cosmos/type/Stream', { params: { type: 'Stream',
  //   environment: 'Cosmos',
  //   includeCrossEnvironment: 'true',
  //   firstLevelResourcePool: 'Cluster-1',
  //   secondLevelResourcePool: 'VirtualCluster-1',
  //   name: 'ddd' } }).reply(400, { message: 'Couldn\'t find the node! Please ensure the search value is correct' });
  // http.get('/api/graph/Stream-5', { params: {
  //   depth: 1,
  //   includeCrossEnvironment: 'true',
  // },
  // }).reply(200, require('./data/searchDirect/Stream5').default);
  // http.get('/api/graph/Job-2', { params: {
  //   depth: 1,
  //   includeCrossEnvironment: 'true',
  // },
  // }).reply(200, require('./data/searchDirect/Job2').default);

  // http.get('/api/graph/Job-2', { params: {
  //   depth: 3,
  //   includeCrossEnvironment: 'true',
  // },
  // }).reply(200, require('./data/searchMore/Job2').default);
  // http.get('/api/graph/Stream-1', { params: {
  //   depth: 1,
  //   includeCrossEnvironment: 'true',
  // },
  // }).reply(200, require('./data/searchDirect/Stream1').default);

  // http.get('/api/graph/resource-attributes').reply(200, require('./data/resource-pools').default);
};
