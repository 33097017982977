export const getStyles = () => ({
  fontSize: '14px',

  display: 'flex',
  alignItems: 'center',
  '& .color-strip': {
    width: '5px',
    height: '50px',
  },

  '& .metric-info': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    marginLeft: '10px',
  },

  '& .metric-value': {
    fontSize: '20px',
    fontFamily: 'Segoe UI',
  },

  '& .metric-description': {
    fontSize: '14px',
    fontFamily: 'Segoe UI',
  },
});
