import React from 'react';
import injectSheet, { ThemeProvider } from 'react-jss';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { colors as colorsDef } from '@cosman/config';

const stylesDef = {
  shadow: '2px 2px 2px rgba(0, 0, 0, 0.25)',
};

const theme = {
  colors: colorsDef,
  styles: stylesDef,
};

export function withDefaultStyles(Component, getStyles = () => ({})) {
  const StyledComponent = injectSheet(({
    colors = {},
    components = {},
    styles = {},
    core = {},
  }) => getStyles({
    colors,
    components,
    styles,
    core,
  }))(Component);

  const Wrapper = (props) => React.createElement(
    ThemeProvider,
    { theme },
    React.createElement(StyledComponent, { ...props }),
  );

  hoistNonReactStatics(Wrapper, Component);

  return Wrapper;
}
