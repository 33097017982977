import React from 'react';
import PropTypes from 'prop-types';

import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { context as httpContext } from '@cosman/data-actions';
import { SecureApp } from '../SecureApp';
import { AuthenticatingPage } from '../AuthenticatingPage';
import { authRequest, msalConfig } from '../../config/msalconfig';

const pca = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!pca.getActiveAccount() && pca.getAllAccounts().length > 0) {
  pca.setActiveAccount(pca.getAllAccounts()[0]);
}

// Once user sign out from one tab, the other tabs will be notified and redirect to login page
// pca.enableAccountStorageEvents();

// Set active account
pca.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const { account } = event.payload;
    pca.setActiveAccount(account);
  } else if (event.eventType === EventType.SSO_SILENT_FAILURE && event.error && event.error.errorCode === 'monitor_window_timeout') {
    pca.acquireTokenRedirect({
      ...authRequest,
      prompt: 'select_account',
      redirectStartPage: window.location.origin,
    });
  }
});

pca.handleRedirectPromise().then((response) => {
  if (response) {
    pca.setActiveAccount(response.account);
  }
});

// Add interceptor outside react component to make sure the registration runs only once
httpContext.injectTokens(() => localStorage.getItem('accessToken'));

export const tokens = {
  bearerToken: () => localStorage.getItem('accessToken'),
};

export const SecureAppProvider = (props) => {
  const { children, loadingPage } = props;
  const pending = window.location.search.indexOf('pending=true') !== -1;

  if (pending) {
    return loadingPage;
  }

  return React.createElement(
    MsalProvider,
    { instance: pca },
    React.createElement(
      SecureApp,
      { loadingPage },
      children,
    ),
  );
};

SecureAppProvider.propTypes = {
  loadingPage: PropTypes.object,
};

SecureAppProvider.defaultProps = {
  loadingPage: React.createElement(AuthenticatingPage),
};
