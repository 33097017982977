const { useCallback, useRef, useState } = require('react');

export const useFleetingState = (delay) => {
  const ref = useRef(null);
  const [state, setState] = useState(null);

  const handleSetState = useCallback((value) => {
    setState(value);
    clearTimeout(ref.current);
    ref.current = setTimeout(() => { setState(null); }, delay || 5000);
  }, [delay]);

  return [state, handleSetState];
};
