import _ from 'underscore';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { Callout, DirectionalHint } from '@fluentui/react';
import { h } from '@cosman/utils';
import { withStyles } from '@cosman/functions';
import { Button } from '../../../../basic';
import { TableColumnSelectorOptions } from '../TableColumnSelectorOptions';
import { getStyles } from './index.styles';

const TableColumnSelectorPure = (props) => {
  const {
    className,
    columns,
    selectedColumns,
    onChange,
  } = props;

  const [opened, setOpened] = useState(false);

  const handleToggleDialogue = useCallback(() => {
    setOpened((previous) => !previous);
  }, []);

  const handleSave = useCallback((event) => {
    setOpened(false);
    onChange({ columns: event.columns });
  }, [onChange]);

  return h(
    'div',
    { className },
    h(Button, {
      className: 'toggle-button',
      iconName: 'Settings',
      label: 'Column options',
      onClick: handleToggleDialogue,
    }),
    opened && h(
      Callout,
      {
        className: 'select-options',
        target: `.${className.replace(/\s+/g, '.')} .toggle-button`,
        gapSpace: 0,
        calloutWidth: 480,
        onDismiss: handleToggleDialogue,
        directionalHint: DirectionalHint.bottomRightEdge,
      },
      h(TableColumnSelectorOptions, {
        columns,
        selectedColumns,
        onSave: handleSave,
        onCancel: handleToggleDialogue,
      }),
    ),
  );
};

TableColumnSelectorPure.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  selectedColumns: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};

TableColumnSelectorPure.defaultProps = {
  columns: [],
  selectedColumns: [],
  onChange: _.noop,
};

const wrap = _.compose(
  (Component) => withStyles(Component, getStyles),
);

export const TableColumnSelector = wrap(TableColumnSelectorPure);
