export const getStyles = () => ({
  padding: '15px',

  '& .field-selector-options': {
    marginTop: '10px',
  },

  '& .field-selector-actions': {
    display: 'flex',
    marginTop: '15px',
  },
});
