import _ from 'underscore';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import { useTable } from 'react-table';
import { h, c } from '@cosman/utils';
import { withStyles } from '@cosman/functions';
import { Copiable } from '../../../../basic';
import { getStyles } from './index.styles';
import { TableHeaderColumn } from '../TableHeaderColumn';

const TableInstancePure = (props) => {
  const {
    data,
    columns: originalColumns,
    className,
  } = props;

  const convertColumns = useCallback((original) => _.map(original, ({
    name,
    label,
    dataType,
    renderContent,
    tooltip,
    copiable,
    width,
    minWidth,
    maxWidth,
  }) => _.assign({}, {
    accessor: name,
    Header: _.isFunction(label) ? label() : label,
    dataType,
    tooltip,
    copiable,
    width,
    minWidth,
    maxWidth,
    Cell: ({ value, row }) => {
      const content = _.isFunction(renderContent) ? renderContent(value, row.original) : value;
      const cell = copiable !== true || _.isEmpty(content) ? content : h(Copiable, { title: value }, content);

      return cell;
    },
  })), []);

  const columns = useMemo(() => {
    return convertColumns(originalColumns);
  }, [convertColumns, originalColumns]);

  const handleStopPropagation = useCallback((event) => {
    event.stopPropagation();
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    data,
    columns,
  });

  return h(
    'div',
    {
      className: c(className, 'table-component'),
      onClick: handleStopPropagation,
    },
    h(
      'div',
      { className: 'table-wrapper' },
      h(
        'table',
        getTableProps(),
        h(
          'thead',
          {},
          _.map(headerGroups, (headerGroup) => h(
            'tr',
            headerGroup.getHeaderGroupProps(),
            _.map(headerGroup.headers, (column) => h(
              'th',
              column.getHeaderProps({ style: { width: column.width } }),
              h(TableHeaderColumn, { column }),
            )),
          )),
        ),
        h(
          'tbody',
          getTableBodyProps(),
          _.isEmpty(rows) && h(
            'tr',
            { className: 'empty' },
            h(
              'td',
              { colSpan: _.size(columns) },
              'No data',
            ),
          ),
          _.map(rows, (row, index) => prepareRow(row) || h(
            'tr',
            { key: index },
            _.map(row.cells, (cell) => h(
              'td',
              cell.getCellProps({ className: c({ 'align-right': cell.column.dataType === 'number' }), title: cell.value }),
              cell.render('Cell'),
            )),
          )),
        ),
      ),
    ),
  );
};

TableInstancePure.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
    aggregation: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    renderContent: PropTypes.func,
    tooltip: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    sortable: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  })).isRequired,
};

TableInstancePure.defaultProps = {
};

const wrap = _.compose(
  (Component) => withStyles(Component, getStyles),
);

export const TableInstance = wrap(TableInstancePure);
