import { cosmanCopilotService as http } from '../../services/cosman-copilot';

// Only use the stateless chat API
export const cosmanCopilotApi = () => {
  const chatStateless = ({ input, history, onData, getCancel }) => {
    return http.postWithSSEResponse('/chat/stateless', { input, history }, { }, onData, getCancel);
  };

  const chatFeedback = ({ payload }) => {
    return http.post('/chat/feedback', payload);
  };

  const chatV2 = ({ pageContext, chatHistory, onData, getCancel }) => {
    return http.postWithSSEResponse('/chat/v2.0', { pageContext, chatHistory }, { }, onData, getCancel);
  };

  const feedbackV2 = ({ pageContext, chatHistory, feedback }) => {
    return http.post('/chat/v2.0/feedback', { pageContext, chatHistory, feedback });
  };

  const inlineSuggestion = ({ pageContext, chatHistory }) => {
    return http.post('/chat/v2.0/inline-suggestion', { pageContext, chatHistory });
  };

  const responseSuggestion = ({ pageContext, chatHistory }) => {
    return http.post('/chat/v2.0/response-suggestion', { pageContext, chatHistory });
  };

  return {
    chatStateless,
    chatFeedback,
    chatV2,
    feedbackV2,
    inlineSuggestion,
    responseSuggestion,
  };
};
