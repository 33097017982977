const chartColors = [
  '#5c87b2',
  '#ffa500',
  '#ffe153',
  '#ffaf60',
  '#000000',
  '#00bb00',
  '#737300',
  '#ff0000',
  '#d26900',
  '#0090d0',
  '#272727',
  '#009393',
  '#20b2aa',
  '#9932cc',
  '#b22222',
  '#00bfff',
  '#ff69b4',
  '#777777',
  '#B3523D',
  '#FFE61C',
  '#28dc79',
  '#a67059',
  '#f1ee5b',
  '#ee3f47',
  '#d37469', // light red
];

export const pieChartDefaultColors10 = [
  '#5470c6',
  '#91cc75',
  '#fac858',
  '#ee6666',
  '#73c0de',
  '#3ba272',
  '#fc8452',
  '#9a60b4',
  '#ea7ccc',
  '#774499',
];

export const heatmapDefaultColors = [
  '#bf444c',
  '#d88273',
  '#f6efa6',
  '#ffffff',
];

export const colors = {
  primary: '#5279a0',
  secondary: '#e8eef4',
  tertiary: '#e0e0e0',
  quaternary: '#ff8f59',
  textPrimary: '#000000',
  textSecondary: '#fefefe',
  textTertiary: '#5279a0',
  textQuaternary: '#f0f0f0',
  textGray: '#aaaaaa',
  textBlue: '#006cbf',
  textDarkGray: '#555555',
  pipelineCentralNodeColor: '#000000',
  pipelineUpstreamNodeColor: '#CD5C5C',
  pipelineDownstreamNodeColor: '#4682B4',
  shadowBackGroundColor: '#f6f6f6',
  severeWarning: '#d83b01',
  errorBlock: '#a80000',

  chartColors,

  getChartColor: (index) => {
    return chartColors[index % chartColors.length];
  },

  getPieChartColor: (index) => {
    return pieChartDefaultColors10[index % pieChartDefaultColors10.length];
  },
};
