import { useAccount, useMsal } from '@azure/msal-react';
import { authRequest } from '../config/msalconfig';

export const useAuth = () => {
  const { instance, accounts, inProgress } = useMsal();
  const activeAccount = useAccount(instance.getActiveAccount() || accounts[0] || {});
  const signoutCallback = () => {
    localStorage.removeItem('accessToken');
    instance.logoutRedirect();
  };

  const selectAccountRequest = {
    ...authRequest,
    prompt: 'select_account',
  };

  const signinCallback = () => {
    instance.loginRedirect(selectAccountRequest);
  };

  const setActiveAccount = (account) => {
    instance.setActiveAccount(account);
  };

  return {
    alias: getAlias(activeAccount.username),
    instance,
    accounts,
    activeAccount,
    inProgress,
    setActiveAccount,
    signoutCallback,
    signinCallback,
  };
};

const getAlias = (email) => {
  const matches = /^(.+?)(?:_debug|_jit)?@(.+)$/.exec(email);
  const [, alias] = matches || [];
  return alias || '';
};
