import _ from 'underscore';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { h, c } from '@cosman/utils';
import { withStyles } from '@cosman/functions';
import { Icon } from '../../basic';
import { getStyles } from './index.styles';

const CheckboxPure = (props) => {
  const {
    className,
    name,
    value,
    label,
    checked,
    partial,
    disabled,
    onClick,
    onChange,
  } = props;

  const handleChange = useCallback(() => {
    if (disabled) {
      return;
    }

    onChange({ value });
  }, [disabled, onChange, value]);

  return h(
    'div',
    {
      className: c(className, 'checkbox-component', {
        disabled,
        checked: checked || partial,
      }),
    },
    h(
      'label',
      {
        className: 'checkbox-label',
        onClick,
      },
      h(
        'input', {
          type: 'checkbox',
          name,
          value: value || '',
          checked: !!checked,
          onChange: handleChange,
          style: { display: 'none' },
        },
      ),
      h(Icon, { className: 'checkbox-icon', iconName: partial ? 'SkypeCircleMinus' : (checked ? 'SkypeCircleCheck' : 'CircleRing') }),
      _.isEmpty(label) || h('span', { className: 'checkbox-text' }, label),
    ),
  );
};

CheckboxPure.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  checked: PropTypes.bool,
  partial: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
};

CheckboxPure.defaultProps = {
  name: null,
  value: null,
  label: null,
  checked: false,
  partial: false,
  disabled: false,
  onClick: _.noop,
  onChange: _.noop,
};

const wrap = _.compose(
  (Component) => withStyles(Component, getStyles),
);

export const Checkbox = wrap(CheckboxPure);
