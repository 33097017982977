export const getStyles = () => ({
  fontSize: '14px',

  '& .button-label': {
    alignItems: 'center',
    background: 'rgba(0, 0, 0, 0.05)',
    border: 'solid 2px transparent',
    borderRadius: '2px',
    color: 'inherit',
    display: 'flex',
    fontSize: 'inherit',
    fontWeight: 'inherit',
    height: '32px',
    justifyContent: 'center',
    minWidth: '32px',
    outline: 'none',
    overflow: 'hidden',
    padding: '0px',
  },

  '& .button-label .icon-component': {
    fontSize: 'inherit',
    height: '32px',
    margin: '-2px',
    width: '32px',
  },

  '& .button-label .icon-text': {
    paddingRight: '10px',
  },

  '& .button-label .plain-text': {
    padding: '0px 10px',
  },

  '& .button-label.icon-right .icon-text': {
    paddingLeft: '10px',
    paddingRight: '0px',
  },

  '& .button-label:hover': {
    background: 'rgba(0, 0, 0, 0.15)',
  },

  '& .button-label:focus': {
    borderColor: '#0078d4',
    borderRadius: '0px',
  },

  '&.primary': {
    background: '#0078d4',
    color: '#ffffff',
  },

  '&.disabled .button-label': {
    borderColor: 'transparent',
    color: '#d0d0d0',
  },

  '&.disabled .button-label:hover': {
    background: 'rgba(0, 0, 0, 0.05)',
  },
});
