import _ from 'underscore';
import { colors } from '@cosman/config';

const getColor = (chartColorMapping, name) => {
  if (!_.isEmpty(chartColorMapping) && chartColorMapping[name]) {
    return chartColorMapping[name];
  }
  const color = colors.getPieChartColor(_.size(chartColorMapping));
  // eslint-disable-next-line no-param-reassign
  chartColorMapping[name] = color;
  return color;
};

export const circleDataConverter = (chartData, chartColorMapping = {}, unit = '$') => {
  if (chartData && chartData.seriesList && !_.isEmpty(chartData.seriesList)) {
    let data = [];
    const mappedColors = [];
    data = _.map(chartData.seriesList, (item) => {
      mappedColors.push(getColor(chartColorMapping, item.name));
      return {
        name: item.name,
        value: item.value,
      };
    });
    return {
      title: chartData.title,
      option: {
        legend: {
          type: 'scroll',
          orient: 'vertical',
          right: 0,
          top: 'middle',
          itemHeight: 10,
          itemWidth: 12,
          textStyle: {
            fontSize: 10,
            lineHeight: 10,
            overflow: 'break',
            width: 120,
          },
        },
        colors: mappedColors,
        series: [
          {
            right: 150,
            name: unit,
            data,
          },
        ],
      },
    };
  }
  // show no data
  return {
    option: {
      series: [],
    },
  };
};
