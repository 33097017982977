import { useEffect, useRef, useState } from 'react';

export const useStateWithCallback = (initialState) => {
  const ref = useRef(null);
  const [state, setState] = useState(initialState);

  const handleSetState = (s, callback) => {
    ref.current = callback;
    setState(s);
  };

  useEffect(() => {
    if (ref.current) {
      ref.current(state);
      ref.current = null;
    }
  }, [state]);

  return [state, handleSetState];
};
