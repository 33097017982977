export const getStyles = () => ({
  alignItems: 'center',
  display: 'flex',
  height: 'inherit',

  '& .wrapper': {
    alignItems: 'center',
    boxSizing: 'border-box',
    display: 'flex',
    flexWrap: 'nowrap',
    overflow: 'hidden',
    paddingLeft: '8px',
    width: '100%',
  },

  '& .action-spacer': {
    marginLeft: 'auto',
  },

  '& .sortable:hover': {
    background: 'rgba(0, 0, 0, 0.05)',
  },

  '& .sortable:hover .sorting': {
    display: 'flex',
  },

  '& .header-content': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },

  '& .tooltip': {
    color: 'rgba(0, 0, 0, 0.5)',
    cursor: 'default',
    margin: '0px 5px 0px 5px',
  },

  '& .tooltip:hover': {
    color: 'rgba(0, 0, 0, 1)',
  },

  '& .sorting': {
    color: 'rgba(0, 0, 0, 0.5)',
    cursor: 'default',
    display: 'none',
    height: 'inherit',
    marginRight: '5px',
    userSelect: 'none',
  },

  '& .sorting.sorted': {
    color: 'rgba(0, 0, 0, 1)',
    display: 'flex',
  },

  '& .sorting:hover': {
    color: 'rgba(0, 0, 0, 0.75)',
  },

  '& .sizing': {
    background: 'rgba(0, 0, 0, 0.15)',
    boxSizing: 'border-box',
    height: '20px',
    padding: '6px 0px',
    userSelect: 'none',
    width: '2px',
  },

  '& .sizing.sizable': {
    cursor: 'col-resize',
  },
});

export const getGlobalStyles = () => ({
  'body.dragging': {
    cursor: 'col-resize',
  },
});
