/* eslint-disable no-console */

import _ from 'underscore';
import { LogLevel } from '@azure/msal-browser';
import { serverContext } from '@cosman/server-context';

export const msalConfig = {
  auth: {
    clientId: _.get(serverContext, ['auth', 'clientId']),
    authority: _.get(serverContext, ['auth', 'authority']),
    redirectUri: '/',
  },
  cache: {
    cacheLocation: 'localStorage',
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            console.debug(message);
        }
      },
      piiLoggingEnabled: false,
    },
    windowHashTimeout: 60000,
    iframeHashTimeout: 10000,
    loadFrameTimeout: 0,
    asyncPopups: false,
  },
};

export const authRequest = {
  scopes: _.get(serverContext, ['auth', 'scopes']),
};
