import _ from 'underscore';
import { MockRequestInstance } from './mock-request-instance';

export class MockInstance {
  constructor() {
    this.requests = [];
  }

  get(url, ...args) {
    const request = new MockRequestInstance('get', url, ...args);
    this.requests.push(request);
    return request;
  }

  post(url, ...args) {
    const request = new MockRequestInstance('post', url, ...args);
    this.requests.push(request);
    return request;
  }

  resolve(method, url, ...args) {
    return _.find(this.requests, (request) => request.match(method, url, ...args));
  }
}
