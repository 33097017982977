import _ from 'underscore';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { h, c } from '@cosman/utils';
import { withStyles } from '@cosman/functions';
import { getStyles } from './index.styles';

const InputPure = (props) => {
  const {
    className,
    value,
    title,
    disabled,
    onChange,
  } = props;

  const handleChange = useCallback((event) => {
    !disabled && onChange({ value: event.target.value });
  }, [disabled, onChange]);

  return h(
    'div',
    { className: c(className, 'input-component', { disabled }) },
    h(
      'input',
      { value, title, onChange: handleChange, disabled },
    ),
  );
};

InputPure.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.string,
  title: PropTypes.string,
  onChange: PropTypes.func,
};

InputPure.defaultProps = {
  disabled: false,
  value: '',
  title: '',
  onChange: _.noop,
};

const wrap = _.compose(
  (Component) => withStyles(Component, getStyles),
);

export const Input = wrap(InputPure);
