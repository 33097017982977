/* eslint-disable react-hooks/exhaustive-deps */

import { v4 } from 'uuid';
import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from '@cosman/authorize';
import { instrumentation } from '@cosman/instrumentation';

const getAlias = (email) => {
  const matches = /^(.+)@(.+)$/.exec(email);
  const [, alias] = matches || [];

  return alias || '';
};

export const useTracker = () => {
  const { activeAccount } = useAuth();
  const location = useLocation();

  const trackingInfo = useMemo(() => {
    return {
      trackId: v4(),
      alias: getAlias(activeAccount.username),
      email: activeAccount.username,
      displayName: activeAccount.name,
      path: location.pathname,
      url: location.pathname + location.search,
      params: location.search,
      timestamp: new Date().toUTCString(),
    };
  }, [location.pathname, location.search, activeAccount.name, activeAccount.username]);

  const trackHttpRequest = useCallback(({
    api,
    state,
  }) => {
    const { trackId, alias, email, displayName, path } = trackingInfo;
    instrumentation.logInfo('httpRequest', {
      requestId: v4(),
      trackId,
      alias,
      email,
      displayName,
      path,
      api,
      state,
      timestamp: new Date().toUTCString(),
    });
  }, [trackingInfo]);

  const trackPageView = useCallback(() => {
    instrumentation.logInfo('pageView', trackingInfo);
  }, [trackingInfo]);

  const trackEvent = useCallback((name) => {
    instrumentation.trackEvent(name, {
      ...trackingInfo,
    });
  }, [trackingInfo]);

  return {
    trackingInfo,
    trackHttpRequest,
    trackPageView,
    trackEvent,
  };
};
