import _ from 'underscore';
import PropTypes from 'prop-types';
import { useCallback, useRef, useState } from 'react';
import { h, c } from '@cosman/utils';
import { withStyles } from '@cosman/functions';
import { Button } from '../Button';
import { getStyles } from './index.styles';

const CopiablePure = (props) => {
  const {
    className,
    title,
    children,
  } = props;

  const ref = useRef(null);
  const [copied, setCopied] = useState(false);

  const handleCopy = useCallback((event) => {
    event.stopPropagation();

    if (copied) {
      return;
    }

    setCopied(true);
    navigator.clipboard.writeText(ref.current.innerText);
    setTimeout(() => { setCopied(false); }, 2000);
  }, [copied]);

  return h(
    'div',
    {
      className: c(className, 'copiable-component'),
      title: 'Copy',
    },
    h(
      'div',
      {
        className: 'object',
        title,
        ref,
      },
      children,
    ),
    h(
      Button,
      {
        className: c('copy-button', { copied }),
        iconName: copied ? 'CheckMark' : 'Copy',
        onClick: handleCopy,
      },
    ),
  );
};

CopiablePure.propTypes = {
  title: PropTypes.string,
};

CopiablePure.defaultProps = {
  title: '',
};

const wrap = _.compose(
  (Component) => withStyles(Component, getStyles),
);

export const Copiable = wrap(CopiablePure);
