export const getStyles = () => ({
  paddingBottom: '15px',
  width: '100%',

  '& .label': {
    fontSize: '18px',
    fontWeight: '600',
    margin: '0px 15px',
    padding: '20px 0px 0px 0px',
  },

  '& .description': {
    margin: '15px 15px 0px',
  },

  '& .options': {
    borderTop: 'solid 1px rgba(0, 0, 0, 0.05)',
    margin: '15px 15px 15px',
  },

  '& .option-item': {
    borderBottom: 'solid 1px rgba(0, 0, 0, 0.05)',
    margin: '0px',
    padding: '8px 0px',
    position: 'relative',
  },

  '& .option-item .action': {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    position: 'absolute',
    right: '10px',
    top: '0px',
  },

  '& .option-item .action .button-label': {
    background: 'transparent',
  },

  '& .option-item .action .button-label:hover': {
    background: 'rgba(0, 0, 0, 0.1)',
  },

  '& .option-item .pair': {
    display: 'flex',
    margin: '0px',
  },

  '& .option-item .pair .name': {
    display: 'block',
    minWidth: '65px',
  },

  '& .option-item .pair .value': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whileSpace: 'nowrap',
  },

  '& .option-item .pair .value strong': {
    color: '#0078d4',
    fontWeight: '600',
  },

  '& .option-item.selected': {
    background: 'rgba(0, 0, 0, 0.05)',
  },

  '& .option-item.selected:hover': {
    background: 'rgba(0, 0, 0, 0.075)',
  },

  '& .option-item:hover': {
    background: 'rgba(0, 0, 0, 0.05)',
  },
});
