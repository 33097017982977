import _ from 'underscore';
import { useCallback, useEffect, useState } from 'react';
import { cosmanService as http } from '../../../services';

export const usePreference = () => {
  const [preferences, setPreferences] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const getPreference = useCallback((type, identity) => {
    return _.chain(preferences).filter((item) => item.type === type && item.identity === identity).first().value();
  }, [preferences]);

  const updatePreference = useCallback((type, identity, value) => {
    http.post('/v1.0/preferences/me', {}, {
      params: {
        type,
        identity,
        value,
      },
    }).then((response) => {
      setPreferences(response);
    });
  }, []);

  useEffect(() => {
    setIsLoading(true);

    http.get('/v1.0/preferences/me').then((response) => {
      setPreferences(response);
    }).finally(() => {
      setIsLoading(false);
    });
  }, []);

  return {
    preferences,
    getPreference,
    updatePreference,
    isLoading,
  };
};
