export const getStyles = () => ({
  width: '250px',

  '& input': {
    background: '#ffffff',
    border: 'solid 1px #605e5c',
    borderRadius: '2px',
    boxSizing: 'border-box',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    height: '32px',
    lineHeight: 'auto',
    outline: 'none',
    padding: '0px 9px',
    width: '100%',
  },

  '& input:focus': {
    border: 'solid 2px #0078d4',
    borderRadius: '0px',
    padding: '0px 8px',
  },

  '&.disabled input': {
    borderColor: '#d0d0d0',
    color: '#d0d0d0',
  },

  '&.disabled input:focus': {
    borderColor: '#d0d0d0',
    color: '#d0d0d0',
  },
});
