import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { globalInit } from '@cosman/core';
import { SecureAppProvider, tokens } from '@cosman/authorize';
import { instrumentation } from '@cosman/instrumentation';
import { context as httpContext } from '@cosman/data-actions';
import { Provider } from 'react-redux';
import App from './views/App';
import './index.js.css';
import store from './store';

globalInit({
  instrumentation,
  httpContext,
  tokens,
});

ReactDOM.render(
  <SecureAppProvider>
    <Router>
      <Provider store={store}>
        <App />
      </Provider>
    </Router>
  </SecureAppProvider>,
  document.getElementById('root'),
);
