export const getStyles = () => ({
  padding: '15px',

  '& .field-editor-settings': {
    marginTop: '10px',
  },

  '& .field-editor-settings .operator': {
    marginBottom: '10px',
  },

  '& .field-editor-actions': {
    display: 'flex',
    marginTop: '15px',
  },
});
