export const getStyles = () => ({
  paddingBottom: '15px',
  width: '100%',

  '& .label': {
    fontSize: '18px',
    fontWeight: '600',
    margin: '0px 15px',
    padding: '20px 0px 0px 0px',
  },

  '& .description': {
    margin: '15px 15px 0px',
  },

  '& .errors': {
    margin: '15px 15px 0px',
  },

  '& .options': {
    padding: '15px 15px 15px',
  },

  '& .option-item': {
    alignItems: 'center',
    borderRadius: '3px',
    cursor: 'all-scroll',
    display: 'flex',
    padding: '10px 10px 10px 0px',
  },

  '& .option-item:hover': {
    background: 'rgba(0, 0, 0, 0.025)',
  },

  '& .option-item:hover .order': {
    opacity: '1',
  },

  '& .option-item-gap': {
    border: 'solid 0px rgba(0, 0, 0, 0.05)',
    borderWidth: '1px 0px',
    height: '0px',
  },

  '& .option-item-gap .placeholder': {
    background: '#0078d4',
    borderRadius: '50%',
    display: 'none',
    height: '10px',
    marginLeft: '-5px',
    marginTop: '-5px',
    width: '10px',
  },

  '& .option-item-gap.active': {
    borderColor: '#0078d4',
  },

  '& .option-item-gap.active .placeholder': {
    display: 'block',
  },

  '& .order': {
    fontSize: '14px',
    height: '32px',
    opacity: '0',
    userSelect: 'none',
    width: '32px',
  },

  '& .column-select': {
    marginLeft: '13px',
    width: '355px',
  },

  '& .remove': {
    fontSize: '13px',
    marginLeft: 'auto',
  },

  '& .remove button': {
    background: 'transparent',
  },

  '& .create-new': {
    fontWeight: '600',
    padding: '0px 15px 0px',
  },

  '& .create-new button': {
    background: 'transparent',
  },

  '& .actions': {
    display: 'flex',
    fontWeight: '600',
    padding: '15px 15px 0px',
  },

  '& .actions .cancel': {
    marginLeft: 'auto',
  },

  '& .actions .cancel button': {
    background: 'rgba(0, 0, 0, 0.05)',
  },

  '& .actions .ok': {
    marginLeft: '15px',
  },
});
