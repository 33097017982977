import _ from 'underscore';
import { useRef, useCallback } from 'react';

export const useThrottle = (callback, delay) => {
  const ref = useRef();
  ref.current = callback;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const result = useCallback(_.throttle((...params) => {
    return ref.current(...params);
  }, delay), []);

  return result;
};
