import _ from 'underscore';

export const sortBy = (tableData, key, ascending = true) => {
  let updatedData = _
    .chain(tableData)
    .sortBy((item) => item[key])
    .value();

  if (!ascending) {
    updatedData = updatedData.reverse();
  }
  return updatedData;
};
