/* eslint-disable global-require */
/* eslint-disable no-unused-vars */

const baseUri = 'v1/blueshift-env-buildout';

export const mockPolicy = (http) => {
  /*
  http.get(`${baseUri}/clouds`).reply(200, require('./data/get-clouds-response').default);
  http.get(`${baseUri}/clouds/public/regions`).reply(200, require('./data/get-regions-response').default);
  http.get(`${baseUri}/clouds/mooncake/regions`).reply(200, require('./data/get-regions-response').regionsForMooncake);
  http.get(`${baseUri}/clouds/public/tenants`).reply(200, require('./data/get-tenants-response').default);
  http.get(`${baseUri}/clouds/mooncake/tenants`).reply(200, require('./data/get-tenants-response').tenantsForMooncake);
  http.get(`${baseUri}/clouds/public/tenants/msit/subscriptions`).reply(200, require('./data/get-subscriptions-response').default);
  http.get(`${baseUri}/clouds/mooncake/tenants/msit/subscriptions`).reply(200, require('./data/get-subscriptions-response').subscriptionsForMooncakeMicrosoft);
  http.get(`${baseUri}/roles`).reply(200, require('./data/get-roles-response').default);
  http.get(`${baseUri}/rollouts`).reply(200, require('./data/get-rollouts-response').rolloutRequests);
  */
};
