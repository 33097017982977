export const getStyles = () => ({
  component: {
    fontSize: '16px',
    padding: '15px',

    '& .contact': {
      color: '#0078d4',
      textDecoration: 'underline',
    },

    '& .logout': {
      background: 'none',
      border: 'solid 1px #0078d4',
      borderRadius: '5px',
    },
  },
});
