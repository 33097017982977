import _ from 'underscore';
import PropTypes from 'prop-types';
import { h, c } from '@cosman/utils';
import { Tooltip } from '@cosman/shared-components';
import { withStyles } from '@cosman/functions';
import { getStyles } from './index.styles';

const CardMetricPure = (props) => {
  const {
    className,
    valueColor,
    metricTitle,
    metricTooltip,
    metricValue,
  } = props;

  return h(
    'div',
    { className: c(className, 'card-metric-container') },
    h(
      'div',
      { className: 'metric-title' },
      metricTitle,
      h(
        Tooltip,
        { className: 'metric-tooltip', content: metricTooltip },
      ),
    ),
    h(
      'div',
      { className: 'metric-value', style: { color: valueColor } },
      metricValue,
    ),
  );
};

CardMetricPure.propTypes = {
  valueColor: PropTypes.string,
  metricTitle: PropTypes.string,
  metricTooltip: PropTypes.string,
  metricValue: PropTypes.string,
};

CardMetricPure.defaultProps = {
  valueColor: '#FE9900',
  metricTitle: null,
  metricTooltip: null,
  metricValue: null,
};

const wrap = _.compose(
  (Component) => withStyles(Component, getStyles),
);

export const CardMetric = wrap(CardMetricPure);
