import _ from 'underscore';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import reactToString from 'react-to-string';
import { CSVLink as Exporter } from 'react-csv';
import { h } from '@cosman/utils';
import { withStyles } from '@cosman/functions';
import { Icon } from '../../../../basic';
import { getStyles } from './index.styles';

const TableExporterPure = (props) => {
  const {
    className,
    data,
    columns,
    separator,
    filename,
  } = props;

  const headers = useMemo(() => {
    return _.map(columns, ({ name, label }) => ({ key: name, label }));
  }, [columns]);

  const actualData = useMemo(() => {
    return _.map(data, (item) => {
      return _.reduce(columns, (memo, curr) => {
        return _.assign(memo, {
          [curr.name]: reactToString(_.isFunction(curr.renderContent) ? curr.renderContent(item[curr.name], item) : item[curr.name]),
        });
      }, {});
    });
  }, [columns, data]);

  return h(
    'div',
    { className },
    h(
      Exporter,
      { data: actualData, headers, separator, filename: _.isFunction(filename) ? filename() : filename },
      h(Icon, { iconName: 'Download', title: 'Export data' }),
    ),
  );
};

TableExporterPure.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  columns: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
  })),
  separator: PropTypes.string,
  filename: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

TableExporterPure.defaultProps = {
  data: [],
  columns: [],
  separator: ',',
  filename: 'Export',
};

const wrap = _.compose(
  (Component) => withStyles(Component, getStyles),
);

export const TableExporter = wrap(TableExporterPure);
