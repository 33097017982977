import { initializeIcons } from '@uifabric/icons';
import { registerIcons } from '@fluentui/react/lib/Styling';
import { SVGIcons } from './SVG-icons';
import { globalSettings } from './global-settings';

export const globalInit = ({
  instrumentation,
  httpContext,
  tokens,
} = {}) => {
  initializeIcons(); // general icons
  registerIcons(SVGIcons); // svg icons

  const {
    version,
  } = globalSettings;

  // clear localStorage and sessionStorage if Cosman version changes
  const localVersion = localStorage.getItem('version');
  if (localVersion !== version) {
    localStorage.clear();
    sessionStorage.clear();
    localStorage.setItem('version', version);
  }

  if (httpContext) {
    httpContext.injectTokens(tokens);
    httpContext.injectInstrumentation(instrumentation);
  }
};
