import _ from 'underscore';
import PropTypes from 'prop-types';
import { h, c } from '@cosman/utils';
import { withStyles } from '@cosman/functions';
import { Icon } from '../Icon';
import { getStyles } from './index.styles';

const ErrorPure = (props) => {
  const {
    className,
    message,
    severity,
  } = props;

  return h(
    'div',
    { className: c(className, 'error-component') },
    h(
      'div',
      { className: c('error-wrapper', severity) },
      h(Icon, { className: 'icon', iconName: severity === 'error' ? 'StatusErrorFull' : (severity === 'warning' ? 'WarningSolid' : 'Info') }),
      h('span', { className: 'message' }, message),
    ),
  );
};

ErrorPure.propTypes = {
  message: PropTypes.string.isRequired,
  severity: PropTypes.oneOf(['error', 'warning']),
};

ErrorPure.defaultProps = {
  message: 'this is a message',
  severity: null,
};

const wrap = _.compose(
  (Component) => withStyles(Component, getStyles),
);

export const Error = wrap(ErrorPure);
