import _ from 'underscore';
import { useEffect, useState } from 'react';
import { cosmanService as http } from '../../../services';

const convertTreeNodeNext = ({
  I,
  N,
  T,
  H,
  C,
}) => ({
  id: I,
  name: N,
  key: I,
  type: T,
  hasChildren: H,
  links: C,
});

export const useServiceTreeHierarchyData = (defaultGroups) => {
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    setIsDataLoading(true);
    const ref = { cancelled: false };
    const callback = () => { ref.cancelled = true; };

    http.get('/v1.0/servicetree/ServiceTreeHierarchy', {
    }).then((resp) => {
      if (ref.cancelled) {
        return;
      }
      const recurFunc = (node, pathFromRoot) => {
        const nd = convertTreeNodeNext(node);
        nd.fullName = pathFromRoot.join('/');
        if (nd.links) {
          nd.links = _.map(nd.links, (child) => recurFunc(child, [...pathFromRoot, child.N]));
        } else {
          delete nd.links;
        }
        return nd;
      };
      setData([{
        links: _.map(resp, (node) => recurFunc(node, [node.N])),
      }]);
      setIsDataLoading(false);
    }).catch(() => {
      if (!ref.cancelled) { // if error happened and request is not cancelled, then clear data and loading state
        setData(defaultGroups);
        setIsDataLoading(false);
      }
    });
    return callback;
  }, [defaultGroups]);

  return [data, isDataLoading];
};
