export const getStyles = () => ({

  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  alignItems: 'left',

  '& .metric-title': {
    fontSize: '24px',
    lineHeight: '16px',
    color: '#666',
    display: 'flex',
    alignItems: 'center',
    cursor: 'default',
    userSelect: 'none',
  },

  '& .metric-tooltip': {
    marginLeft: '5px',
  },

  '& .metric-value': {
    // very common sans-serif font supports font weight and stretch
    // for digits, $ and alphabets
    fontFamily: 'Segoe UI',
    fontSize: '38px',
    color: 'darkblue',
    fontWeight: 100,
  },

});
