import _ from 'underscore';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSortBy, useTable } from 'react-table';
import { h, c } from '@cosman/utils';
import { withStyles } from '@cosman/functions';
import { aggregations } from '../../config';
import { Pagination } from '../Pagination';
import { Button, Copiable } from '../../basic';
import { Checkbox } from '../../controls';
import { TableColumnSelector, TableExporter, TableFilter, TableGrouping, TableHeaderColumn, TableInstance } from './components';
import { getStyles } from './index.styles';

const predefinedOperations = {
  Add: { iconName: 'Add', label: 'Add' },
  Delete: { iconName: 'Delete', label: 'Delete', disabled: ({ rows }) => _.isEmpty(rows) },
  Edit: { iconName: 'Edit', label: 'Edit', disabled: ({ rows }) => _.isEmpty(rows) },
};

const TablePure = (props) => {
  const {
    className,
    data: originalData,
    columns: originalColumns,
    pageIndex,
    pageSize,
    pageSizeOptions,
    pageCount,
    pageSiblingCount,
    sortBy,
    sortByDesc,
    groupBy,
    groupByOptions: originalGroupByOptions,
    operations,
    exportedName,
    selectedColumns,
    selectableHandler,
    filters,
    uniqueKey,
    multipleSelect,
    enablePagination,
    enableColumnSelection,
    enableColumnSizing,
    enableFiltering,
    enableExporting,
    enableSelection,
    enableGrouping,
    onSelect,
    onSelectColumns,
    onLoad,
  } = props;

  const [state, setState] = useState({});
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [expandedGroupKeys, setExpandedGroupKeys] = useState([]);
  const [updatedWidths, setUpdatedWidths] = useState({});
  const [hoverIndex, setHoverIndex] = useState(null);
  const [checkedAll, setCheckedAll] = useState(false);
  const [expandedAllGroups, setExpandedAllGroups] = useState(false);
  const [sorted, setSorted] = useState(false);

  const enableSumming = useMemo(() => {
    return _.any(originalColumns, ({ aggregation }) => !_.isUndefined(aggregation) && !_.isNull(aggregation));
  }, [originalColumns]);

  const selectedGroupBy = useMemo(() => {
    return _.isEmpty(groupBy) ? null : _.find(originalGroupByOptions, (option) => option.name === groupBy);
  }, [groupBy, originalGroupByOptions]);

  const data = useMemo(() => {
    if (!enableGrouping || _.isNull(selectedGroupBy)) {
      return originalData;
    }

    const groupColumns = _.map(selectedGroupBy.columns, (name) => _.find(originalColumns, { name }));
    const groupAggregations = selectedGroupBy.aggregations || [];

    const result = _.chain(originalData).groupBy((item) => {
      return _.map(groupColumns, (column) => item[column.name]);
    }).map((group, key) => {
      const texts = key.split(',');
      const groupedResult = _.reduce(groupColumns, (memo, curr, index) => ({ ...memo, [curr.name]: texts[index] }), {});

      _.each(groupAggregations, ({ name, method }) => {
        const values = _.map(group, (item) => item[name]);
        const aggregate = _.isFunction((aggregations[method] || {}).aggregate) ? aggregations[method].aggregate : aggregations.sum.aggregate;
        const aggregated = aggregate(values);

        _.assign(groupedResult, { [name]: aggregated, groupItems: group });
      });

      return groupedResult;
    }).value();

    return result;
  }, [enableGrouping, originalColumns, originalData, selectedGroupBy]);

  const groupedColumns = useMemo(() => {
    if (!enableGrouping || _.isNull(selectedGroupBy)) {
      return originalColumns;
    }

    const groupColumns = _.map(selectedGroupBy.columns, (name) => _.find(originalColumns, ({ name })));
    const aggregationColumns = _.map(selectedGroupBy.aggregations, ({ name, label }) => _.assign({}, _.find(originalColumns, ({ name })), label && { label }));

    return [...groupColumns, ...aggregationColumns];
  }, [enableGrouping, originalColumns, selectedGroupBy]);

  const innerColumns = useMemo(() => {
    if (!enableGrouping || _.isNull(selectedGroupBy)) {
      return [];
    }

    const selected = _.isEmpty(selectedColumns) ? _.map(originalColumns, 'name') : selectedColumns;
    const columnNames = _.filter(selected, (name) => !_.any(selectedGroupBy.columns, (column) => column === name));
    const result = _.map(columnNames, (name) => _.find(originalColumns, { name }));

    return result;
  }, [enableGrouping, originalColumns, selectedColumns, selectedGroupBy]);

  const joinedColumns = useMemo(() => {
    if (_.isEmpty(selectedColumns)) {
      return groupedColumns;
    }

    return _.chain(selectedColumns).map((name) => _.find(groupedColumns, { name })).compact().value();
  }, [groupedColumns, selectedColumns]);

  const columns = useMemo(() => {
    return _.chain(joinedColumns).map(({
      name,
      label,
      dataType,
      renderContent,
      tooltip,
      sortable,
      copiable,
      width,
      minWidth,
      maxWidth,
    }) => ({
      name,
      label,
      dataType,
      renderContent,
      tooltip,
      sortable,
      copiable,
      width: (_.isNumber(width) ? width : parseInt(width, 10)) || null,
      minWidth: Math.max((_.isNumber(minWidth) ? minWidth : parseInt(minWidth, 10)) || 0, 60),
      maxWidth: (_.isNumber(maxWidth) ? maxWidth : parseInt(maxWidth, 10)) || null,
    })).map(({
      name,
      label,
      dataType,
      renderContent,
      tooltip,
      sortable,
      copiable,
      width,
      minWidth,
      maxWidth,
    }) => _.assign({}, {
      accessor: name,
      dataType,
      tooltip,
      sortable,
      copiable,
      width: _.isNumber(updatedWidths[name]) ? updatedWidths[name] : width,
      minWidth,
      maxWidth,
      Header: _.isFunction(label) ? label() : label,
      renderContent,
      Cell: ({ value, row }) => {
        const content = _.isFunction(renderContent) ? renderContent(value, row.original) : value;
        const cell = copiable !== true || _.isEmpty(content) ? content : h(Copiable, { title: value }, content);
        return cell || null;
      },
    })).value();
  }, [joinedColumns, updatedWidths]);

  const widths = useMemo(() => {
    return _.reduce(columns, (memo, curr) => ({
      ...memo,
      [curr.accessor]: {
        width: curr.width,
        minWidth: curr.minWidth,
        maxWidth: curr.maxWidth,
      },
    }), {});
  }, [columns]);

  const summingData = useMemo(() => {
    if (!enableSumming) {
      return {};
    }

    const dict = {};
    const result = {};

    _.each(originalColumns, ({
      name,
      dataType,
      aggregation,
    }) => {
      if (dataType !== 'number') {
        return;
      }

      dict[name] = { data: [], aggregation };

      _.each(data, (item) => {
        dict[name].data.push(item[name] || 0);
      });
    });

    _.each(dict, (value, name) => {
      const { aggregation } = value;
      const method = _.isFunction(aggregation) ? aggregation : (aggregations[aggregation] || {}).aggregate;
      const num = _.isFunction(method) ? method(value.data) : null;

      result[name] = num;
    });

    return result;
  }, [data, enableSumming, originalColumns]);

  const operationButtons = useMemo(() => _.map(operations, (operation) => {
    if (!predefinedOperations[operation.name]) {
      return operation;
    }

    const currentDisabled = operation.disabled;
    const predefinedDisabled = predefinedOperations[operation.name].disabled;
    const currentOnClick = operation.onClick;
    const predefinedOnClick = predefinedOperations[operation.name].onClick;
    const config = {};

    if (_.isFunction(currentDisabled) || _.isBoolean(currentDisabled)) {
      config.disabled = currentDisabled;
    } else if (_.isFunction(predefinedDisabled) || _.isBoolean(predefinedDisabled)) {
      config.disabled = predefinedDisabled;
    }

    if (_.isFunction(currentOnClick)) {
      config.onClick = currentOnClick;
    } else if (_.isFunction(predefinedOnClick)) {
      config.onClick = predefinedOnClick;
    }

    return _.assign({}, predefinedOperations[operation.name], config);
  }), [operations]);

  const groupByOptions = useMemo(() => {
    return _.map(originalGroupByOptions, (option) => {
      return _.assign({}, option, { columns: _.map(option.columns, (name) => _.find(originalColumns, { name })) });
    });
  }, [originalColumns, originalGroupByOptions]);

  const getItemKey = useCallback((item, index) => {
    if (enableGrouping && !_.isNull(selectedGroupBy)) {
      return _.map(selectedGroupBy.columns, (column) => item[column]).join('|');
    }

    if (_.isString(uniqueKey)) {
      return (item[uniqueKey] || '').toString();
    }

    if (_.isFunction(uniqueKey)) {
      return (uniqueKey(item) || '').toString();
    }

    return ((pageIndex - 1) * pageSize + index).toString();
  }, [enableGrouping, pageSize, pageIndex, selectedGroupBy, uniqueKey]);

  const getIsItemSelected = useCallback((item, index) => {
    const contains = _.contains(selectedKeys, getItemKey(item, index));
    const selected = checkedAll ? !contains : contains;

    return selected;
  }, [checkedAll, getItemKey, selectedKeys]);

  const getIsGroupExpanded = useCallback((item, index) => {
    if (_.isUndefined(item) && _.isUndefined(index)) {
      return expandedAllGroups && _.isEmpty(expandedGroupKeys);
    }

    const contains = _.contains(expandedGroupKeys, getItemKey(item, index));
    const expanded = expandedAllGroups ? !contains : contains;

    return expanded;
  }, [expandedAllGroups, expandedGroupKeys, getItemKey]);

  const handleChangeSorting = useCallback((event) => {
    setState((previous) => {
      if (event.sortBy === previous.sortBy && event.sortByDesc === previous.sortByDesc) {
        return previous;
      }

      return _.assign({}, previous, { sortBy: event.sortBy, sortByDesc: event.sortByDesc });
    });
  }, []);

  const handleChangeFiltering = useCallback((event) => {
    if (!enableFiltering) {
      return;
    }

    setState((previous) => {
      if (event.filters === previous.filters && event.filterHandler === previous.filterHandler) {
        return previous;
      }

      return _.assign({}, previous, { filters: event.filters, filterHandler: event.filterHandler, pageIndex: 1 });
    });
  }, [enableFiltering]);

  const handleChangeGrouping = useCallback((event) => {
    if (!enableGrouping) {
      return;
    }

    setState((previous) => {
      if (event.groupBy === previous.groupBy) {
        return previous;
      }

      setCheckedAll(false);
      setSelectedKeys([]);
      setSelectedRows([]);

      return _.assign({}, previous, { groupBy: event.groupBy });
    });
  }, [enableGrouping]);

  const handleChangePagination = useCallback((event) => {
    if (!enablePagination) {
      return;
    }

    setState((previous) => {
      if (event.pageIndex === previous.pageIndex && event.pageSize === previous.pageSize) {
        return previous;
      }

      return _.assign({}, previous, { pageIndex: event.pageIndex, pageSize: event.pageSize });
    });
  }, [enablePagination]);

  const handleChangeColumnWidth = useCallback((event) => {
    setUpdatedWidths((previous) => _.assign({}, previous, { [event.name]: event.width }));
  }, []);

  const handleSelect = useCallback((event) => {
    if (!enableSelection) {
      return;
    }

    let updatedKyes = null;
    let updatedSelectedRows = null;

    setSelectedKeys((previous) => {
      const { ctrlKey, row, index } = event;
      const multiple = multipleSelect && (_.isUndefined(ctrlKey) || ctrlKey);
      const key = getItemKey(row, index);
      const existing = _.contains(previous, key);

      if (multiple) {
        updatedKyes = existing ? _.filter(previous, (item) => item !== key) : [...previous, key];
      } else {
        setCheckedAll(false);
        updatedKyes = (existing && previous.length === 1) ? [] : [key];
      }

      updatedSelectedRows = _.filter(data, (item, i) => _.contains(updatedKyes, getItemKey(item, i)));
      setSelectedRows(updatedSelectedRows);

      return updatedKyes;
    });
  }, [data, enableSelection, getItemKey, multipleSelect]);

  const handleSelectAll = useCallback(() => {
    if (!enableSelection) {
      return;
    }

    if (_.isEmpty(selectedKeys)) {
      setCheckedAll((previous) => !previous);
    } else {
      setCheckedAll(true);
    }

    setSelectedKeys([]);
    setSelectedRows([]);
  }, [enableSelection, selectedKeys]);

  const handleExpandGroup = useCallback((event) => {
    event.stopPropagation();

    if (!enableGrouping) {
      return;
    }

    setExpandedGroupKeys((previous) => {
      const { row, index } = event;
      const key = getItemKey(row, index);
      const contains = _.contains(previous, key);

      if (contains) {
        return _.filter(previous, (item) => item !== key);
      }

      return [...previous, key];
    });
  }, [enableGrouping, getItemKey]);

  const handleExpandAllGroup = useCallback((event) => {
    event.stopPropagation();

    if (!enableGrouping) {
      return;
    }

    if (_.isEmpty(expandedGroupKeys)) {
      setExpandedAllGroups((previous) => !previous);
    } else {
      setExpandedAllGroups(true);
    }

    setExpandedGroupKeys([]);
  }, [enableGrouping, expandedGroupKeys]);

  const handleSelectColumns = useCallback((event) => {
    if (!enableColumnSelection) {
      return;
    }

    onSelectColumns({ columns: event.columns });
    setUpdatedWidths({});
  }, [enableColumnSelection, onSelectColumns]);

  const handleMouseMover = useCallback((event) => {
    setHoverIndex(event.index);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setHoverIndex(null);
  }, []);

  const handleStopPropagation = useCallback((event) => {
    event.stopPropagation();
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy: sortByState },
    setSortBy,
  } = useTable({
    data,
    columns,
    manualSortBy: true,
    autoResetSortBy: false,
  }, useSortBy);

  useEffect(() => {
    const newSortBy = _.isEmpty(sortBy) ? [] : [{ id: sortBy, desc: sortByDesc }];
    const newSorting = _.chain(sortByState).map(({ id, desc }) => ({ sortBy: id, sortByDesc: desc })).first().value() || {};
    const ready = !sorted && _.isFunction(setSortBy);

    if (ready) {
      setSortBy(newSortBy);
      setSorted(true);
    } else if (sorted) {
      handleChangeSorting(newSorting);
    }
  }, [handleChangeSorting, setSortBy, sortBy, sortByDesc, sortByState, sorted]);

  useEffect(() => {
    const result = {};

    if (enablePagination) {
      _.assign(result, { pageIndex: state.pageIndex || 1, pageSize: state.pageSize || pageSize });
    }

    _.assign(result, { sortBy: state.sortBy || '', sortByDesc: state.sortByDesc || false });

    if (enableFiltering) {
      _.assign(result, { filters: state.filters || [], filterHandler: state.filterHandler || null });
    }

    if (enableGrouping) {
      _.assign(result, { groupBy: state.groupBy || '' });
    }

    onLoad(result);
  }, [enableFiltering, enableGrouping, enablePagination, onLoad, pageSize, state]);

  useEffect(() => {
    const mode = checkedAll ? 'exclude' : 'include';
    const extractedRows = enableGrouping && !_.isEmpty(selectedGroupBy) ? _.flatten(_.map(selectedRows, (item) => item.groupItems)) : selectedRows;

    onSelect({ mode, rows: extractedRows });
  }, [checkedAll, enableGrouping, onSelect, selectedGroupBy, selectedRows]);

  return h(
    'div',
    { className: c(className, 'table-component') },
    h(
      'div',
      { className: 'table-actions' },
      enableFiltering && h(
        TableFilter,
        { className: 'table-filter', data, columns: originalColumns, filters: _.isEmpty(filters) ? state.filters : filters, onChange: handleChangeFiltering },
      ),
      h(
        'div',
        { className: 'table-action-spacer' },
      ),
      _.isEmpty(operationButtons) || h(
        'div',
        { className: 'table-operations' },
        _.map(operationButtons, (button, index) => h(Button, {
          key: index,
          iconName: button.iconName,
          label: button.label,
          disabled: _.isFunction(button.disabled) ? button.disabled({ rows: selectedRows }) : button.disabled,
          onClick: () => _.isFunction(button.onClick) && button.onClick({ rows: selectedRows }),
        })),
      ),
      enableGrouping && h(TableGrouping, {
        className: 'table-grouping',
        groupBy,
        groupByOptions,
        onChange: handleChangeGrouping,
      }),
      enableColumnSelection && h(TableColumnSelector, {
        className: 'table-column-selector',
        columns: originalColumns,
        selectedColumns,
        onChange: handleSelectColumns,
      }),
      enableExporting && h(TableExporter, {
        className: 'table-export',
        data,
        columns: _.map(columns, ({ accessor, Header, renderContent }) => ({ name: accessor, label: Header, renderContent })),
        separator: ',',
        filename: () => `${exportedName} - ${new Date().toISOString()}.csv`,
      }),
    ),
    h(
      'div',
      { className: 'table-wrapper' },
      h(
        'table',
        getTableProps({
          className: c({ 'grouped-table': enableGrouping && !_.isNull(selectedGroupBy) }),
        }),
        h(
          'thead',
          {},
          _.map(headerGroups, (headerGroup) => h(
            'tr',
            headerGroup.getHeaderGroupProps({
              className: 'table-header',
            }),
            enableSelection && multipleSelect && h(
              'th',
              { className: 'checkbox' },
              h(
                Checkbox,
                {
                  className: 'check-all',
                  checked: checkedAll,
                  partial: !_.isEmpty(selectedKeys),
                  onChange: handleSelectAll,
                  onClick: handleStopPropagation,
                },
              ),
            ),
            enableGrouping && !_.isNull(selectedGroupBy) && h(
              'th',
              { className: 'toggle-group' },
              h(Button, {
                iconName: getIsGroupExpanded() ? 'ChevronDown' : 'ChevronRight',
                onClick: handleExpandAllGroup,
              }),
            ),
            _.map(headerGroup.headers, (column) => h(
              'th',
              column.getHeaderProps({
                ...(column.sortable && column.getSortByToggleProps && column.getSortByToggleProps()),
                style: _.isEmpty(widths[column.id]) ? {} : widths[column.id],
                title: column.Header,
              }),
              h(TableHeaderColumn, { column, onChange: handleChangeColumnWidth, enableColumnSizing }),
            )),
          )),
        ),
        h(
          'tbody',
          getTableBodyProps(),
          _.isEmpty(rows) && h(
            'tr',
            { className: 'empty' },
            h(
              'td',
              { colSpan: (enableSelection && multipleSelect ? 1 : 0) + (enableGrouping && !_.isNull(selectedGroupBy) ? 1 : 0) + _.size(columns) },
              'No data',
            ),
          ),
          _.map(rows, (row, index) => prepareRow(row) || h(
            React.Fragment,
            _.omit(row.getRowProps(), 'role'),
            h(
              'tr',
              {
                className: c('data-row', {
                  selected: getIsItemSelected(row.original, index),
                  expanded: getIsGroupExpanded(row.original, index),
                  hover: hoverIndex === index,
                }),
                onClick: (event) => handleSelect({ ...event, row: row.original, index }),
                onMouseOver: () => handleMouseMover({ index }),
                onMouseLeave: handleMouseLeave,
              },
              enableSelection && multipleSelect && h(
                'td',
                { className: 'checkbox' },
                h(
                  Checkbox,
                  {
                    className: 'check-item',
                    checked: getIsItemSelected(row.original, index),
                    disabled: !selectableHandler(row.original),
                    onChange: () => handleSelect({ row: row.original, index }),
                    onClick: handleStopPropagation,
                  },
                ),
              ),
              enableGrouping && !_.isNull(selectedGroupBy) && h(
                'td',
                { className: 'toggle-group' },
                h(Button, {
                  iconName: getIsGroupExpanded(row.original, index) ? 'ChevronDown' : 'ChevronRight',
                  onClick: (event) => handleExpandGroup(_.assign(event, { row: row.original, index })),
                }),
              ),
              _.map(row.cells, (cell) => h(
                'td',
                cell.getCellProps({ className: c('data-cell', { 'align-right': cell.column.dataType === 'number' }), title: cell.value }),
                cell.render('Cell'),
              )),
            ),
            enableGrouping && !_.isNull(selectedGroupBy) && getIsGroupExpanded(row.original, index) && h(
              'tr',
              {
                className: c('group-row', { selected: getIsItemSelected(row.original, index), hover: hoverIndex === index }),
                onClick: (event) => handleSelect({ ...event, row: row.original, index }),
                onMouseOver: () => handleMouseMover({ index }),
                onMouseLeave: handleMouseLeave,
              },
              h(
                'td',
                {
                  className: 'group-cell-collapsed',
                  colSpan: (enableSelection && multipleSelect ? 1 : 0) + (enableGrouping && !_.isNull(selectedGroupBy) ? 1 : 0) + _.size(columns),
                },
                h(
                  TableInstance,
                  { data: row.original.groupItems, columns: innerColumns },
                ),
              ),
            ),
          )),
        ),
        enableSumming && (!_.isEmpty(rows)) && h(
          'tfoot',
          {},
          h(
            'tr',
            {},
            enableSelection && multipleSelect && h('td', {}),
            enableGrouping && !_.isNull(selectedGroupBy) && h('td', {}),
            _.map(columns, (cell, index) => h(
              'td',
              { key: index, className: c({ 'align-right': cell.dataType === 'number' }) },
              _.find(summingData, (value, name) => name === cell.accessor) || null,
            )),
          ),
        ),
      ),
    ),
    enablePagination && h(
      'div',
      { className: 'table-pagination' },
      h(Pagination, { pageIndex, pageSize, pageSizeOptions, pageCount, siblingCount: pageSiblingCount, onChange: handleChangePagination }),
    ),
  );
};

TablePure.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
    aggregation: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    renderContent: PropTypes.func,
    tooltip: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    sortable: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  })).isRequired,
  pageIndex: PropTypes.number,
  pageSize: PropTypes.number,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
  pageCount: PropTypes.number,
  pageSiblingCount: PropTypes.number,
  sortBy: PropTypes.string,
  sortByDesc: PropTypes.bool,
  groupBy: PropTypes.string,
  groupByOptions: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    columns: PropTypes.arrayOf(PropTypes.string).isRequired,
    aggregations: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
      method: PropTypes.oneOf(_.keys(aggregations)),
    })),
  })),
  operations: PropTypes.arrayOf(PropTypes.object),
  exportedName: PropTypes.string,
  selectedColumns: PropTypes.arrayOf(PropTypes.string),
  selectableHandler: PropTypes.func,
  filters: PropTypes.arrayOf(PropTypes.object),
  uniqueKey: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  multipleSelect: PropTypes.bool,
  enablePagination: PropTypes.bool,
  enableColumnSelection: PropTypes.bool,
  enableColumnSizing: PropTypes.bool,
  enableFiltering: PropTypes.bool,
  enableExporting: PropTypes.bool,
  enableSelection: PropTypes.bool,
  enableGrouping: PropTypes.bool,
  onSelect: PropTypes.func,
  onSelectColumns: PropTypes.func,
  onLoad: PropTypes.func,
};

TablePure.defaultProps = {
  pageIndex: 1,
  pageSize: 10,
  pageSizeOptions: [10, 20, 50, 100, 500, 1000],
  pageCount: 0,
  pageSiblingCount: undefined,
  selectableHandler: _.constant(true),
  sortBy: null,
  sortByDesc: false,
  groupBy: null,
  groupByOptions: undefined,
  operations: [],
  exportedName: 'Export',
  selectedColumns: null,
  filters: [],
  uniqueKey: null,
  multipleSelect: false,
  enablePagination: true,
  enableColumnSelection: true,
  enableColumnSizing: true,
  enableFiltering: true,
  enableExporting: true,
  enableSelection: true,
  enableGrouping: false,
  onSelect: _.noop,
  onSelectColumns: _.noop,
  onLoad: _.noop,
};

const wrap = _.compose(
  (Component) => withStyles(Component, getStyles),
);

export const Table = wrap(TablePure);
