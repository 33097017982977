import _ from 'underscore';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { Callout, DirectionalHint } from '@fluentui/react';
import { h } from '@cosman/utils';
import { withStyles } from '@cosman/functions';
import { Button } from '../../../../basic';
import { operators, dataTypes } from '../../../../config';
import { FieldEditor } from '../FieldEditor';
import { FieldSelector } from '../FieldSelector';
import { getStyles } from './index.styles';

const FilterItemPure = (props) => {
  const {
    className,
    fields,
    filter,
    index,
    disabled,
    onChange,
    onCancel,
  } = props;

  const [showDialog, setShowDialog] = React.useState(false);

  const preparing = useMemo(() => {
    return _.isUndefined(filter.name);
  }, [filter]);

  const fieldNames = useMemo(() => {
    return _.object(_.map(fields, (field) => [field.name, field.label]));
  }, [fields]);

  const handleOpenDialog = useCallback(() => {
    if (disabled) {
      return;
    }

    setShowDialog(true);
  }, [disabled]);

  const handleCloseDialog = useCallback(() => {
    if (disabled) {
      return;
    }

    onCancel();
    setShowDialog(false);
  }, [disabled, onCancel]);

  const handleUpdate = useCallback((event) => {
    if (disabled) {
      return;
    }

    const updated = { ...event.filter, guid: filter.guid };
    const shouldDeactive = !_.isEmpty(_.omit(updated, 'name', 'guid'));

    onChange({ filter: updated, index });
    shouldDeactive && setShowDialog(false);
  }, [disabled, index, onChange, filter.guid]);

  const handleRemove = useCallback(() => {
    if (disabled) {
      return;
    }

    onChange({ filter: null, index });
  }, [disabled, index, onChange]);

  return h(
    'div',
    { className },
    preparing ? h(Button, {
      className: 'surrounded dialog-target',
      iconName: 'FilterSettings',
      label: 'Add filters',
      disabled,
      onClick: handleOpenDialog,
    }) : h(
      React.Fragment,
      {},
      h(Button, {
        className: 'left-rounded dialog-target',
        label: () => h(
          'span',
          {},
          fieldNames[filter.name],
          filter.operator && ' ',
          filter.operator && operators[filter.operator] && (operators[filter.operator].label || ''),
          filter.value && ' ',
          filter.value && h('span', { className: 'filter-value' }, filter.value),
        ),
        disabled,
        onClick: handleOpenDialog,
      }),
      h('div', { className: 'gap' }),
      h(Button, {
        className: 'right-rounded',
        iconName: 'ChromeClose',
        disabled,
        onClick: handleRemove,
      }),
    ),
    showDialog && h(
      Callout,
      {
        target: `.${className.replace(/\s+/g, '.')} .dialog-target`,
        gapSpace: 0,
        calloutWidth: 280,
        onDismiss: handleCloseDialog,
        directionalHint: DirectionalHint.bottomLeftEdge,
      },
      preparing || h(FieldEditor, {
        fields,
        filter,
        onChange: handleUpdate,
      }),
      preparing && h(FieldSelector, {
        fields,
        onChange: handleUpdate,
      }),
    ),
  );
};

FilterItemPure.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    dataType: PropTypes.oneOf(_.keys(dataTypes)).isRequired,
    operators: PropTypes.arrayOf(PropTypes.oneOf(_.keys(operators))).isRequired,
    options: PropTypes.arrayOf(PropTypes.string),
  })),
  filter: PropTypes.shape({
    name: PropTypes.string,
    operator: PropTypes.oneOf(_.keys(operators)),
    value: PropTypes.any,
  }),
  index: PropTypes.number,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onCancel: PropTypes.func,
};

FilterItemPure.defaultProps = {
  fields: [],
  filter: null,
  index: null,
  disabled: false,
  onChange: _.noop,
  onCancel: _.noop,
};

const wrap = _.compose(
  (Component) => withStyles(Component, getStyles),
);

export const FilterItem = wrap(FilterItemPure);
