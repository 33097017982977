import _ from 'underscore';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { h, c } from '@cosman/utils';
import { withStyles } from '@cosman/functions';
import { getStyles } from './index.styles';

const SelectOptionsPure = (props) => {
  const {
    className,
    options,
    focusIndex,
    value,
    onChange,
  } = props;

  const handleSelect = useCallback((event) => {
    onChange(event);
  }, [onChange]);

  return h(
    'div',
    { className },
    h(
      'ul',
      {},
      _.map(options, (option, index) => h(
        'li',
        { key: option.name, className: c('select-option', { selected: _.any(value, (name) => name === option.name), focused: index === focusIndex }) },
        h(
          'button',
          {
            type: 'button',
            tabIndex: -1,
            onClick: () => handleSelect({ options: [option], index }),
            title: option.label,
          },
          option.label,
        ),
      )),
      _.isEmpty(options) && h(
        'li',
        { className: 'select-option unavailable' },
        h(
          'button',
          { type: 'button', tabIndex: -1 },
          'No options available',
        ),
      ),
    ),
  );
};

SelectOptionsPure.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
  })),
  value: PropTypes.arrayOf(PropTypes.string),
  focusIndex: PropTypes.number,
  onChange: PropTypes.func,
};

SelectOptionsPure.defaultProps = {
  options: [],
  value: [],
  focusIndex: null,
  onChange: _.noop,
};

const wrap = _.compose(
  (Component) => withStyles(Component, getStyles),
);

export const SelectOptions = wrap(SelectOptionsPure);
