import _ from 'underscore';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { h, c } from '@cosman/utils';
import { withStyles } from '@cosman/functions';
import { Icon } from '../Icon';
import { getStyles } from './index.styles';

const ButtonPure = (props) => {
  const {
    className,
    iconName,
    iconPosition,
    label,
    title,
    disabled,
    buttonType,
    onClick,
  } = props;

  const handleClick = useCallback((event) => {
    if (!disabled) {
      onClick(event);
    }
  }, [disabled, onClick]);

  return h(
    'div',
    {
      className: c(className, 'button-component', {
        disabled,
        primary: buttonType === 'primary',
      }),
      title,
    },
    h(
      'button',
      {
        className: c('button-label', `icon-${iconPosition}`),
        type: 'button',
        tabIndex: disabled ? -1 : 0,
        onClick: handleClick,
      },
      iconName && iconPosition === 'left' && h(Icon, { iconName }),
      label && h('div', { className: c({ 'icon-text': !!iconName, 'plain-text': !iconName }) }, _.isFunction(label) ? label() : label),
      iconName && iconPosition === 'right' && h(Icon, { iconName }),
    ),
  );
};

ButtonPure.propTypes = {
  iconName: PropTypes.string,
  iconPosition: PropTypes.oneOf(['left', 'right']),
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  title: PropTypes.string,
  disabled: PropTypes.bool,
  buttonType: PropTypes.string,
  onClick: PropTypes.func,
};

ButtonPure.defaultProps = {
  iconName: null,
  iconPosition: 'left',
  label: null,
  title: null,
  disabled: false,
  buttonType: null,
  onClick: _.noop,
};

const wrap = _.compose(
  (Component) => withStyles(Component, getStyles),
);

export const Button = wrap(ButtonPure);
