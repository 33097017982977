import _ from 'underscore';
import s from 'underscore.string';
import { cosmanService as cosmanHttp, efficiencyEngineService as efficiencyEngineHttp } from '@cosman/data-actions';
import { useEffect, useState } from 'react';
import { nanColumnValue } from './consts';

// cosmosStorage, cosmosProcessing,blueshiftStorage,blueshiftProcessing
const getLeafNodeOtherFabricData = ({
  serviceId,
  startDate,
  endDate,
  granularity,
}) => {
  return cosmanHttp.get('/v1.0/cost/Overview/LeafNode', {
    params: {
      serviceId,
      startDate,
      endDate,
      granularity,
    },
  }).then((costByFabric) => {
    return _.mapObject(costByFabric, (v) => {
      const dailyCost = _.each(v.dailyCost, (c) => {
        return { date: c.date.substring(0, 10), value: c.value };
      });
      return {
        dailyCost,
        realizedCumulativeCost: v.realizedCumulativeCost,
        averageDailyUsage: v.averageDailyUsage,
        estimatedAnnualCost: v.estimatedAnnualCost,
      };
    });
  });
};

const getLeafNodeCostDataFromEfficiencyEngine = (
  {
    serviceId,
    startDate,
    endDate,
    metric,
    granularity,
  },
) => {
  const convertedGranularity = granularity === 'ByDay' ? 'Daily' : 'Monthly';
  return efficiencyEngineHttp.get('/api/cost/service', {
    params: {
      serviceId,
      metric,
      startDate,
      endDate,
      granularity: convertedGranularity,
    },
  }).then((resp) => {
    const costList = _.flatten(_.map(resp.SeriesData, (v) => {
      return v.DataList;
    }), true);
    const costGroupByDate = _.groupBy(costList, (x) => (x.Date));
    const costMap = _.mapObject(costGroupByDate, (v) => {
      return _.reduce(v, (memo, data) => { return memo + data.Value || 0; }, 0);
    });
    const dailyCost = _.map(costMap, (v, k) => {
      return { date: k.substring(0, 10), value: v };
    });
    const realizedCumulativeCost = _.reduce(dailyCost, (memo, data) => { return memo + data.value; }, 0);
    const estimatedAnnualCost = (realizedCumulativeCost / dailyCost.length) * 365;
    return {
      dailyCost,
      realizedCumulativeCost,
      averageDailyUsage: 0,
      estimatedAnnualCost,
    };
  });
};

const convertLeafNodeData = ([
  {
    cosmosStorage,
    cosmosProcessing,
    blueshiftStorage,
    blueshiftStore,
    blueshiftRead,
    blueshiftWrite,
    blueshiftProcessing,
    blueshiftStandardToken,
    blueshiftEcoToken,
  },
  kusto,
  mDS,
]) => {
  const costByFabric = {
    'Cosmos Storage': cosmosStorage,
    'Cosmos Processing': cosmosProcessing,
    'Blueshift Storage': blueshiftStorage,
    'Blueshift Store': blueshiftStore,
    'Blueshift Read': blueshiftRead,
    'Blueshift Write': blueshiftWrite,
    'Blueshift Processing': blueshiftProcessing,
    'Blueshift Standard Token': blueshiftStandardToken,
    'Blueshift Eco Token': blueshiftEcoToken,
    Kusto: kusto,
    MDS: mDS,
  };
  if (!_.any(costByFabric, (v) => {
    return v.realizedCumulativeCost > 0;
  })) {
    return {
      costByFabric: {},
      summaryTableDataByFabric: [],
    };
  }
  const totalRowByFabric = {
    name: 'Total',
    drillDownName: '',
    realizedCumulativeCost: 0,
    estimatedAnnualCost: 0,
    averageDailyUsage: nanColumnValue,
    estimatedAnnualUsage: nanColumnValue,
  };

  let summaryTableDataByFabric = _.map(costByFabric, (fabricData, fabric) => {
    const row = {
      name: fabric,
      drillDownName: '',
      realizedCumulativeCost: `${s.numberFormat(fabricData.realizedCumulativeCost, 0)}`,
      estimatedAnnualCost: `${s.numberFormat(fabricData.estimatedAnnualCost, 0)}`,
      averageDailyUsage: nanColumnValue,
      estimatedAnnualUsage: nanColumnValue,
    };
    switch (fabric) {
      case 'Cosmos Storage':
        row.averageDailyUsage = `${s.numberFormat(fabricData.averageDailyUsage, 0)} TiBs`;
        row.estimatedAnnualUsage = `${s.numberFormat(fabricData.averageDailyUsage, 0)} TiBs`;
        break;
      case 'Cosmos Processing':
        row.averageDailyUsage = `${s.numberFormat(fabricData.averageDailyUsage, 0)} Tokens`;
        row.estimatedAnnualUsage = `${s.numberFormat(fabricData.averageDailyUsage * 365, 0)} Tokens`;
        break;
      case 'Blueshift Storage':
        row.averageDailyUsage = nanColumnValue;
        row.estimatedAnnualUsage = nanColumnValue;
        row.drillDownName = 'Total';
        break;
      case 'Blueshift Store':
        row.averageDailyUsage = `${s.numberFormat(fabricData.averageDailyUsage, 0)} TiBs`;
        row.estimatedAnnualUsage = `${s.numberFormat(fabricData.averageDailyUsage, 0)} TiBs`;
        row.name = '';
        row.drillDownName = 'Store';
        break;
      case 'Blueshift Read':
        row.averageDailyUsage = fabricData.averageDailyUsage;
        row.estimatedAnnualUsage = `${s.numberFormat(row.averageDailyUsage * 365, 0)}`;
        row.averageDailyUsage = `${s.numberFormat(row.averageDailyUsage, 0)}`;
        row.name = '';
        row.drillDownName = 'Read Operation';
        break;
      case 'Blueshift Write':
        row.averageDailyUsage = fabricData.averageDailyUsage;
        row.estimatedAnnualUsage = `${s.numberFormat(row.averageDailyUsage * 365, 0)}`;
        row.averageDailyUsage = `${s.numberFormat(row.averageDailyUsage, 0)}`;
        row.name = '';
        row.drillDownName = 'Write Operation';
        break;
      case 'Blueshift Processing':
        row.averageDailyUsage = nanColumnValue;
        row.estimatedAnnualUsage = nanColumnValue;
        row.drillDownName = 'Total';
        break;
      case 'Blueshift Standard Token':
        row.averageDailyUsage = `${s.numberFormat(fabricData.averageDailyUsage * 365, 0)} AUs`;
        row.estimatedAnnualUsage = `${s.numberFormat(fabricData.averageDailyUsage, 0)} AUs`;
        row.name = '';
        row.drillDownName = 'Standard Token';
        break;
      case 'Blueshift Eco Token':
        row.averageDailyUsage = `${s.numberFormat(fabricData.averageDailyUsage * 365, 0)} AUs`;
        row.estimatedAnnualUsage = `${s.numberFormat(fabricData.averageDailyUsage, 0)} AUs`;
        row.name = '';
        row.drillDownName = 'Eco Token';
        break;
      default:
    }
    if (row.drillDownName !== 'Total') { // Avoid adding cost twice, only add top-level fabrics
      totalRowByFabric.realizedCumulativeCost += fabricData.realizedCumulativeCost;
      totalRowByFabric.estimatedAnnualCost += fabricData.estimatedAnnualCost;
    }
    return row;
  });
  totalRowByFabric.realizedCumulativeCost = s.numberFormat(totalRowByFabric.realizedCumulativeCost, 0);
  totalRowByFabric.estimatedAnnualCost = s.numberFormat(totalRowByFabric.estimatedAnnualCost, 0);
  summaryTableDataByFabric = [totalRowByFabric].concat(summaryTableDataByFabric);

  delete costByFabric['Blueshift Standard Token'];
  delete costByFabric['Blueshift Eco Token'];
  delete costByFabric['Blueshift Store'];
  delete costByFabric['Blueshift Read'];
  delete costByFabric['Blueshift Write'];

  return {
    costByFabric,
    summaryTableDataByFabric,
  };
};

export const useOverviewData = (serviceId, startDate, endDate, granularity) => {
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [data, setData] = useState({});

  useEffect(() => {
    setIsDataLoading(true);
    const ref = { cancelled: false };
    const callback = () => { ref.cancelled = true; };

    if (!startDate || !endDate) {
      return callback;
    }
    Promise.all([
      getLeafNodeOtherFabricData(
        {
          serviceId,
          startDate,
          endDate,
          granularity,
        },
      ),
      getLeafNodeCostDataFromEfficiencyEngine(
        {
          serviceId,
          startDate,
          endDate,
          metric: 'Kusto',
          granularity,
        },
      ),
      getLeafNodeCostDataFromEfficiencyEngine(
        {
          serviceId,
          startDate,
          endDate,
          metric: 'MDS',
          granularity,
        },
      ),
    ]).then((resp) => {
      if (ref.cancelled) {
        return;
      }
      setData(convertLeafNodeData(resp));
      setIsDataLoading(false);
    }).catch(() => {
      if (!ref.cancelled) { // if error happened and request is not cancelled, then clear data and loading state
        setData({});
        setIsDataLoading(false);
      }
    });
    return callback;
  }, [startDate, endDate, serviceId, granularity]);
  return [data, isDataLoading];
};
