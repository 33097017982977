export const getStyles = () => ({
  '& ul, li': {
    margin: '0px',
    padding: '0px',
  },

  '& .select-option button': {
    background: 'transparent',
    border: 'solid 2px transparent',
    boxSizing: 'border-box',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    height: '35px',
    overflow: 'hidden',
    textAlign: 'left',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
  },

  '& .select-option button:hover': {
    background: 'rgba(0, 0, 0, 0.1)',
  },

  '& .select-option.selected': {
    background: 'rgba(0, 0, 0, 0.12)',
  },

  '& .select-option.focused button': {
    borderColor: '#007bff',
  },

  '& .select-option.unavailable button': {
    color: 'rgba(0, 0, 0, 0.38)',
  },
});
