export const getStyles = (colors) => ({
  welcomeMessage: {
    color: colors.textSecondary,
    fontFamily: '"Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji"',
    fontSize: '48px',
    fontWeight: '700',
    lineHeight: '100px',
    textAlign: 'center',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
});
