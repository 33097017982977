/* eslint-disable no-unused-vars */
/* eslint-disable global-require */
// import { gemMockPolicy } from './mock-policy-gem';

export const mockPolicy = () => {
  // http.get('/v1.0/favorites', { params: { aliasName: 'boluli', sourceType: 'type4' } }).reply(200, require('./data/get-favorites-response').default);
  // http.get('/v1.0/home/services/own').reply(200, require('./data/get-homepage-services').default);
  // http.get('/v1.0/home/services/subscribed').reply(200, require('./data/get-homepage-services').default);
  // gemMockPolicy(http);
  // http.get('/v1.0/prices/current').reply(200, require('./data/price').default);
  // http.get('/v1.0/prices/all-fiscal-years');
  // http.get(
  //   '/v1.0/groupmanager/new-report/cosmos-storage-quota', {
  //     params: { organization: 'OneDrive/SharePoint'}}).reply(200, require('./data/gem-quota').default
  // );
};
