import _ from 'underscore';
import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { cosmanDataActions } from '@cosman/data-actions';
import { getDisplayName } from '@cosman/utils';

export const PreferenceContext = React.createContext({
  preferences: {},
  getPreference: _.noop,
  updatePreference: _.noop,
  isLoading: false,
});

export const withPreferenceContext = (Component) => {
  const {
    usePreference,
  } = cosmanDataActions;

  const HoC = React.memo((props) => {
    const {
      preferences,
      getPreference,
      updatePreference,
      isLoading,
    } = usePreference();

    return React.createElement(
      PreferenceContext.Provider,
      { value: { preferences, getPreference, updatePreference, isLoading } },
      React.createElement(Component, props),
    );
  });

  HoC.displayName = `withPreferenceContext(${getDisplayName(Component)})`;

  return hoistNonReactStatics(HoC, Component);
};
