import _ from 'underscore';

export const getAdlAccount = (cluster, virtualCluster) => {
  const regex = /^cosmos(\d+)/;

  if (!regex.test(cluster) || !_.isString(virtualCluster)) {
    return null;
  }

  return `${virtualCluster.replace(/\./g, '-')}-c${regex.exec(cluster)[1]}`;
};
