/**
   * Returns if the current job is lens job.
   *
   * @param {string} jobName The name of the current job.
   * @return {boolean} boolean whether the current job is lens job.
   */
export const isLensJob = (jobName) => {
  if (!jobName) {
    return false;
  }
  const regex = /GO\|.*\|[MDTR]\|.*/gm;
  const matchRes = jobName.match(regex);
  if (matchRes && matchRes.length === 1) {
    return true;
  }
  return false;
};

/**
 * Returns the full lens jobs list.
 * Assuming all lens jobs are in this pattern <GO\|.*\|[MTR]\|.*>,
 * generate all three kinds of it: GO\|.*\|[M]\|.*, GO\|.*\|[T]\|.*, GO\|.*\|[R]\|.*
 *
 * @param {string} jobName The name of the current job.
 * @return {Array} res an Array of the full lens jobs.
 */
export const generateFullLensJobsList = (jobName) => {
  const regex = /GO\|.*\|[A-Z]\|/gm;
  const lensJobCategories = ['M', 'D', 'T', 'R'];
  const firstPart = jobName.match(regex)[0];
  const res = [];
  for (let i = 0; i < lensJobCategories.length; i += 1) {
    const curJobName = `${firstPart.slice(0, firstPart.length - 3)}|${lensJobCategories[i]}|${jobName.slice(firstPart.length)}`;
    res.push(curJobName);
  }
  return res;
};

/**
 * Returns if they are the same lens jobs.
 *
 * @param {string} jobNameFirst The name of the first job.
 * @param {string} jobNameSecond The name of the second job.
 * @return {boolean} res whether the two jobs are the same lens jobs.
 */
export const isTheSameLensJobs = (jobNameFirst, jobNameSecond) => {
  if (jobNameFirst.length !== jobNameSecond.length) {
    return false;
  }

  let res = false;
  for (let i = 0; i <= jobNameFirst.length; i += 1) {
    if (jobNameFirst[i] !== jobNameSecond[i]) {
      if (res) {
        return false;
      }
      res = true;
    }
  }
  return res;
};
