export const getStyles = ({
  colors,
}) => ({
  display: 'flex',
  height: '100%',
  width: 'auto',

  '& .pagesize': {
    display: 'flex',
    margin: 'auto 10px auto 0px',
    width: '180px',
  },

  '& .pagesize-selector': {
    width: '70px',
  },

  '& .pagesize-label': {
    fontSize: '13px',
    margin: 'auto 5px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },

  '& .page-selectors': {
    display: 'flex',
    marginLeft: 'auto',
    marginRight: '-5px',
  },

  '& .page-selectors .button-component': {
    marginRight: '5px',
    color: colors.textPrimary,
  },

  '& .page-selectors .button-component .plain-text': {
    padding: '0px',
  },

  '& .page-selectors .button-component span': {
    padding: '0px',
  },

  '& .page-selectors .button-component.selected button': {
    background: colors.primary,
    color: '#ffffff',
  },
});
