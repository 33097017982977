import _ from 'underscore';

export class MockRequestInstance {
  constructor(method, url, ...args) {
    this.method = method;
    this.url = url;
    this.args = args;
    this.code = this.serialize(method, url, ...args);
  }

  reply(statusCode, data) {
    this.result = { statusCode, data };
  }

  match(method, url, ...args) {
    if (method !== this.method || url !== this.url) {
      return false;
    }

    if (method === 'get' && !this.resolveParams(this.args)) {
      return true;
    }

    return this.code === this.serialize(method, url, ...args);
  }

  serialize(method, url, ...args) {
    if (method === 'post') {
      return JSON.stringify({ m: method, u: url });
    }

    const params = this.resolveParams(args);
    const json = JSON.stringify({ m: method, u: url, p: this.reorganize(params) });

    return json;
  }

  reorganize(json) {
    if (!_.isObject(json)) {
      return json;
    }

    const keys = _.chain(json).keys().sort().value();
    const sortedJson = _.reduce(keys, (memo, curr) => ({ ...memo, [curr]: this.reorganize(json[curr]) }), {});

    return sortedJson;
  }

  resolveParams(args) {
    return args && args[0] && args[0].params;
  }
}
