export const getStyles = () => ({
  overflow: 'hidden',

  '& .table-actions': {
    display: 'flex',
    padding: '10px 0px',
  },

  '& .table-action-spacer': {
    marginLeft: 'auto',
  },

  '& .table-operations': {
    borderRight: 'solid 2px rgba(0, 0, 0, 0.05)',
    display: 'flex',
    paddingRight: '10px',
  },

  '& .table-operations .button-component': {
    marginLeft: '10px',
  },

  '& .table-header': {
    background: 'rgba(0, 0, 0, 0.05)',
  },

  '& .table-grouping': {
    marginLeft: '10px',
    whiteSpace: 'nowrap',
  },

  '& .table-column-selector': {
    marginLeft: '10px',
    whiteSpace: 'nowrap',
  },

  '& .table-export': {
    marginLeft: '10px',
  },

  '& .table-wrapper': {
    overflowX: 'auto',
    overflowY: 'hidden',
    width: '100%',
  },

  '& .table-pagination': {
    display: 'flex',
  },

  '& .table-pagination .pagination-component': {
    padding: '10px 0px',
    width: '100%',
  },

  '& .checkbox': {
    boxSizing: 'border-box',
    lineHeight: '30px',
    padding: '0px 0px 0px 8px',
    width: '32px',
  },

  '& .checkbox .check-all': {
    display: 'none',
  },

  '& .toggle-group': {
    boxSizing: 'border-box',
    padding: '0px',
    width: '32px',
  },

  '& .toggle-group .button-label': {
    background: 'transparent',
    fontSize: '12px',
  },

  '& .toggle-group .button-label:focus': {
    border: 'none',
  },

  '& .data-row.hover': {
    background: 'rgba(0, 0, 0, 0.05)',
  },

  '& .data-row.selected': {
    background: 'rgba(0, 0, 255, 0.05)',
  },

  '& .data-row.selected.hover': {
    background: 'rgba(0, 0, 255, 0.1)',
  },

  '& .group-row.hover': {
    background: 'rgba(0, 0, 0, 0.05)',
  },

  '& .group-row.selected': {
    background: 'rgba(0, 0, 255, 0.05)',
  },

  '& .group-row.selected.hover': {
    background: 'rgba(0, 0, 255, 0.1)',
  },

  '& .grouped-table': {
    background: 'rgba(0, 0, 0, 0.05)',
  },

  '& .grouped-table .data-row.expanded': {
    border: 'none',
  },

  '& .grouped-table .group-cell-collapsed': {
    paddingBottom: '10px',
  },

  '& .data-cell .copy-button': {
    display: 'none',
  },

  '& .data-cell:hover .copy-button': {
    display: 'block',
  },

  '& table': {
    background: '#ffffff',
    borderCollapse: 'collapse',
    fontFamily: 'inherit',
    lineHeight: '30px',
    tableLayout: 'fixed',
    textAlign: 'left',
    width: '100%',
  },

  '& th': {
    height: 'inherit',
    margin: '0px',
    overflow: 'hidden',
    padding: '0px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },

  '& td': {
    margin: '0px',
    overflow: 'hidden',
    padding: '0px 8px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },

  '& td.align-right': {
    textAlign: 'right',
  },

  '& td.align-right .copiable-component': {
    float: 'right',
  },

  '& thead tr': {
    borderBottom: 'solid 2px rgba(0, 0, 0, 0.1)',
    height: '35px',
    lineHeight: '35px',
  },

  '& thead th': {
    fontWeight: 'normal',
  },

  '& tbody tr': {
    borderBottom: 'solid 1px rgba(0, 0, 0, 0.1)',
  },

  '& tfoot tr': {
    background: 'rgba(0, 0, 0, 0.0375)',
    fontWeight: '600',
    height: '35px',
  },
});
