import _ from 'underscore';
import { MockAdapter } from './mock-adapter';

export const mockDataActions = (http, mockPolicy) => {
  if (_.isNull(http) || _.isUndefined(http) || !_.isFunction(mockPolicy)) {
    return;
  }

  new MockAdapter(http).setPolicy(mockPolicy);
};
