export const getStyles = () => ({
  alignItems: 'center',
  display: 'flex',
  flexWrap: 'nowrap',
  overflow: 'hidden',
  width: 'auto',

  '& .object': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },

  '& .copy-button': {
    padding: '0px',
  },

  '& .copy-button .button-label': {
    background: 'transparent',
    color: 'rgba(0, 0, 0, 0.5)',
    fontSize: '15px',
    height: '22px',
    minWidth: '22px',
    width: '22px',
  },

  '& .copy-button .button-label:focus': {
    borderColor: 'transparent',
  },

  '& .copy-button .button-label:hover': {
    color: 'rgba(0, 0, 0, 0.75)',
  },

  '& .copy-button.copied .button-label': {
    color: 'rgba(0, 0, 0, 1)',
    fontSize: '18px',
  },
});
