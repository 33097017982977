import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { useAccount, useMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import { instrumentation } from '@cosman/instrumentation';
import { Dialog } from '@cosman/shared-components';
import { AuthenticatingPage } from '../AuthenticatingPage';
import { authRequest } from '../../config/msalconfig';

const MINUTE_MS = 60000;

const WrappedApp = (props) => {
  const { instance, accounts } = useMsal();
  const account = useAccount(instance.getActiveAccount() || accounts[0] || {});
  const [accessToken, setAccessToken] = useState(null);
  const { children, loadingPage } = props;
  const { logDebug, logError } = instrumentation;
  const [hidden, setHidden] = useState(true);
  const [dialogData, setDialogData] = useState({});

  const showAlert = useCallback(({
    title = 'Notification',
    renderBody = () => '',
    width = 600,
  }) => new Promise((resolve) => {
    setDialogData({
      title,
      buttons: [
        {
          type: 'primary',
          behavior: 'ok',
          text: 'OK',
          onClick: () => {
            window.location.reload();
            setHidden(true);
            resolve();
          },
        },
        {
          behavior: 'dismiss',
          text: 'Cancel',
          onClick: () => {
            setHidden(true);
          },
        },
      ],
      renderBody,
      width,
    });

    setHidden(false);
  }), []);

  const updateAccessToken = useCallback(() => {
    const cachedAccessToken = localStorage.getItem('accessToken');
    const accessTokenExpiresOn = localStorage.getItem('accessTokenExpiresOn');
    // Force refresh a new access token when the old token is to expire in 10 minutes
    const forceRefresh = cachedAccessToken === null || new Date().valueOf() - parseInt(accessTokenExpiresOn, 10) - 10 * MINUTE_MS > 0;

    const req = {
      ...authRequest,
      account,
      forceRefresh,
    };

    instance.acquireTokenSilent(req).then((resp) => {
      if (resp !== null) {
        const tokens = resp.accessToken.split('.');
        localStorage.setItem('accessToken', resp.accessToken);
        localStorage.setItem('accessTokenExpiresOn', resp.expiresOn.valueOf());
        localStorage.setItem('roles', JSON.parse(atob(tokens[1])).roles || []);
        setAccessToken(localStorage.getItem('accessToken'));
        logDebug('acquireTokenSilent success');
      } else {
        localStorage.removeItem('accessToken');
        logDebug('acquireTokenSilent success but no resp');
      }
    }).catch((err) => {
      logError(['acquireTokenSilent failed with errorCode=', err.errorCode, ', message=', err.message].join(''));
      if (err instanceof InteractionRequiredAuthError) {
        logError(['acquireTokenSilent failed with InteractionRequiredAuthError, status=', err.errorCode, ', message=', err.message].join(''));
        instance.acquireTokenRedirect({ ...req, redirectUri: window.location.origin, prompt: 'select_account' });
      } else if (err.errorCode === 'access_denied') {
        logError(['acquireTokenSilent failed with access_denied, status=', err.errorCode, ', message=', err.message].join(''));
        instance.acquireTokenRedirect({ ...req, redirectUri: window.location.origin, prompt: 'select_account' });
      }
    });
  }, [instance, account, logDebug, logError]);

  useEffect(() => {
    // listen storage change
    const alertWhenSignoutSomewhereElse = (e) => {
      if (e.key === 'accessToken' && e.newValue === null) {
        // user is logged out, show dialog
        showAlert({ renderBody: () => 'You have been signed out, refresh to sign in again!' });
      }
    };
    window.addEventListener('storage', alertWhenSignoutSomewhereElse);
    updateAccessToken();
    // check accessToken lifetime every 5 minutes
    const fid = setInterval(updateAccessToken, 5 * MINUTE_MS);

    return () => {
      clearInterval(fid);
      window.removeEventListener('storage', alertWhenSignoutSomewhereElse);
    };
  }, [updateAccessToken, showAlert]);

  if (accessToken) {
    return React.createElement(
      React.Fragment,
      null,
      children,
      React.createElement(
        Dialog,
        { title: dialogData.title, buttons: dialogData.buttons, width: dialogData.width, hidden },
        dialogData.renderBody && dialogData.renderBody(),
      ),
    );
  }

  return loadingPage;
};

WrappedApp.propTypes = {
  loadingPage: PropTypes.object,
};

WrappedApp.defaultProps = {
  loadingPage: React.createElement(AuthenticatingPage),
};

export const SecureApp = (props) => {
  const { instance, accounts, inProgress } = useMsal();
  const { children, loadingPage } = props;

  // redirect to login page when there is no user logged in
  useEffect(() => {
    if (accounts.length === 0 && inProgress === InteractionStatus.None) {
      instance.loginRedirect().catch(() => localStorage.clear());
    }
  }, [instance, accounts, inProgress]);

  return React.createElement(
    React.Fragment,
    null,
    React.createElement(
      AuthenticatedTemplate,
      null,
      React.createElement(
        WrappedApp,
        { loadingPage },
        children,
      ),
    ),
    React.createElement(
      UnauthenticatedTemplate,
      null,
      loadingPage,
    ),
  );
};

SecureApp.propTypes = {
  loadingPage: PropTypes.object,
};

SecureApp.defaultProps = {
  loadingPage: React.createElement(AuthenticatingPage),
};
