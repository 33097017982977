import { isNode } from 'react-flow-renderer';
import dagre from 'dagre';

const nodeWidth = 172;
const nodeHeight = 36;

const createLayouteddGraph = (elements, centralNodeID, direction = 'LR') => {
  const dagreGraph = new dagre.graphlib.Graph();

  dagreGraph.setGraph({
    rankdir: direction,
    nodesep: 40,
    marginx: 20,
    marginy: 20,
    edgesep: 100,
    ranksep: 500,
  });

  dagreGraph.setDefaultEdgeLabel(() => ({}));

  elements.forEach((el) => {
    if (isNode(el)) {
      dagreGraph.setNode(el.id, { width: nodeWidth, height: nodeHeight });
    } else {
      dagreGraph.setEdge(el.source, el.target);
    }
  });

  dagre.layout(dagreGraph);

  let distance = 0;

  const layoutedEls = elements.map((el) => {
    if (isNode(el)) {
      const nodeWithPosition = dagreGraph.node(el.id);
      const updatedEl = el;
      updatedEl.position = {
        x: nodeWithPosition.x - nodeWidth / 2 + Math.random() / 1000,
        y: nodeWithPosition.y - nodeHeight / 2,
      };
      if (el.id === centralNodeID) {
        distance = updatedEl.position.y - 250;
      }
      return updatedEl;
    }
    return el;
  });

  return layoutedEls.map((el) => {
    if (isNode(el)) {
      const nodeWithPosition = dagreGraph.node(el.id);
      const updatedEl = el;
      updatedEl.position = {
        x: nodeWithPosition.x,
        y: nodeWithPosition.y - distance,
      };
      return updatedEl;
    }
    return el;
  });
};

const setGraphElements = (elements) => {
  const elFromGraph = [];
  const defaultPosition = { x: 0, y: 0 };

  elements.DownJobVertices.forEach((downJob) => {
    if (downJob && (downJob.JobID !== elements.SearchedJob.JobID)) {
      elFromGraph.push({
        targetPosition: 'left',
        sourcePosition: 'right',
        defaultPosition,
        id: downJob.JobID,
        type: 'downstreamNode',
        data: {
          JobID: downJob.JobID,
          VC: downJob.JobVC,
          NormalizedName: downJob.JobNormalizedName,
          Cluster: downJob.JobCluster,
        },
      });
    }
  });

  elements.UpJobVertices.forEach((upJob) => {
    if (upJob && (upJob.JobID !== elements.SearchedJob.JobID)) {
      elFromGraph.push({
        targetPosition: 'left',
        sourcePosition: 'right',
        defaultPosition,
        id: upJob.JobID,
        type: 'upstreamNode',
        data: {
          JobID: upJob.JobID,
          VC: upJob.JobVC,
          NormalizedName: upJob.JobNormalizedName,
          Cluster: upJob.JobCluster,
        },
      });
    }
  });

  elements.DownEdges.forEach((downEdge) => {
    if (downEdge) {
      const edgeId = `e${downEdge.SourceID}-${downEdge.TargetID}`;
      elFromGraph.push({
        id: edgeId,
        source: downEdge.SourceID,
        target: downEdge.TargetID,
        animated: true,
        type: 'step',
      });
    }
  });

  elements.UpEdges.forEach((upEdge) => {
    if (upEdge) {
      const edgeId = `e${upEdge.SourceID}-${upEdge.TargetID}`;
      elFromGraph.push({
        id: edgeId,
        source: upEdge.SourceID,
        target: upEdge.TargetID,
        animated: true,
        type: 'step',
      });
    }
  });

  elFromGraph.push({
    targetPosition: 'left',
    sourcePosition: 'right',
    defaultPosition,
    id: elements.SearchedJob.JobID,
    type: 'centralNode',
    data: {
      JobID: elements.SearchedJob.JobID,
      VC: elements.SearchedJob.JobVC,
      NormalizedName: elements.SearchedJob.JobNormalizedName,
      Cluster: elements.SearchedJob.JobCluster,
    },
  });

  return elFromGraph;
};

export const getPipelineChart = (res) => {
  if (res) {
    const elements = res[0];
    const elFromGraph = elements && setGraphElements(elements);
    const layoutedGraphElements = elFromGraph && createLayouteddGraph(elFromGraph, elements.SearchedJob.JobID);

    return layoutedGraphElements;
  }
  return null;
};
