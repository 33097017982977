import _ from 'underscore';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { TooltipHost, TooltipDelay, DirectionalHint } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { h, c } from '@cosman/utils';
import { withStyles } from '@cosman/functions';
import { Icon } from '../../../../basic';
import { getStyles, getGlobalStyles } from './index.styles';

const TableHeaderColumnPure = (props) => {
  const {
    className,
    column,
    enableColumnSizing,
    onChange,
  } = props;

  const ref = useRef(null);
  const start = useRef(null);
  const [dragging, setDragging] = useState(false);
  const tooltipId = useId('tooltip');

  const tooltipProps = useMemo(() => {
    const func = _.isFunction(column.tooltip) ? column.tooltip : () => column.tooltip;
    const tooltip = func();

    if (_.isNull(tooltip) || _.isUndefined(tooltip) || _.isEmpty(tooltip)) {
      return null;
    }

    return {
      onRenderContent: () => tooltip,
    };
  }, [column]);

  const handleSizing = useCallback((event) => {
    if (_.isNull(start.current)) {
      return;
    }

    const offset = event.pageX - start.current.pageX;
    const newWidth = start.current.width + offset;

    onChange({ name: column.id, width: newWidth });
  }, [column.id, onChange]);

  const handleSizingStart = useCallback((event) => {
    if (!enableColumnSizing) {
      return;
    }

    setDragging(true);
    start.current = start.current || { width: ref.current.offsetWidth, pageX: event.pageX };
  }, [enableColumnSizing]);

  const handleSizingEnd = useCallback(() => {
    start.current = null;
    setDragging(false);
  }, []);

  const handleStopPropagation = useCallback((event) => {
    event.stopPropagation();
  }, []);

  useEffect(() => {
    if (dragging) {
      document.body.classList.add('dragging');
    } else {
      document.body.classList.remove('dragging');
    }
  }, [dragging]);

  useEffect(() => {
    document.addEventListener('mousemove', handleSizing);
    document.addEventListener('mouseup', handleSizingEnd);

    return () => {
      document.removeEventListener('mousemove', handleSizing);
      document.removeEventListener('mouseup', handleSizingEnd);
    };
  }, [handleSizing, handleSizingEnd]);

  return h(
    'div',
    { className, ref },
    h(
      'div',
      { className: c('wrapper', { sortable: column.sortable }) },
      h(
        'span',
        { className: 'header-content' },
        column.render('Header'),
      ),
      tooltipProps && h(
        TooltipHost,
        {
          id: tooltipId,
          directionalHint: DirectionalHint.bottomLeftEdge,
          delay: TooltipDelay.zero,
          tooltipProps,
        },
        h(
          'div',
          {
            className: 'tooltip',
            title: '',
            onClick: handleStopPropagation,
          },
          h(Icon, { iconName: 'Unknown' }),
        ),
      ),
      h(
        'span',
        { className: 'action-spacer' },
      ),
      column.sortable && h(
        Icon,
        {
          className: c('sorting', { sorted: column.isSorted }),
          iconName: column.isSorted ? (column.isSortedDesc ? 'SortDown' : 'SortUp') : 'Sort',
          title: '',
        },
      ),
    ),
    enableColumnSizing && h(
      'div',
      { className: c('sizing', { sizable: enableColumnSizing }), onMouseDown: handleSizingStart, onClick: handleStopPropagation },
    ),
  );
};

TableHeaderColumnPure.propTypes = {
  column: PropTypes.object.isRequired,
  enableColumnSizing: PropTypes.bool,
  onChange: PropTypes.func,
};

TableHeaderColumnPure.defaultProps = {
  enableColumnSizing: false,
  onChange: _.noop,
};

const wrap = _.compose(
  (Component) => withStyles(Component, getStyles, getGlobalStyles),
);

export const TableHeaderColumn = wrap(TableHeaderColumnPure);
