export const getStyles = () => ({
  '& .radio-label': {
    alignItems: 'center',
    display: 'flex',
    height: '25px',
  },

  '& .radio-label .radio-icon': {
    borderRadius: '16px',
    fontSize: '16px',
    height: '18px',
    overflow: 'hidden',
    width: '18px',
  },

  '& .radio-label .radio-text': {
    marginLeft: '10px',
  },

  '&.checked .radio-label .radio-icon': {
    color: '#0078d4',
  },

  '&.disabled .radio-label': {
    color: '#d0d0d0',
  },

  '&.disabled .radio-label .radio-icon': {
    color: '#d0d0d0',
  },

  '&:hover .radio-label .radio-icon': {
    background: 'rgba(0, 0, 0, 0.1)',
  },

  '&.disabled:hover .radio-label .radio-icon': {
    background: 'transparent',
  },
});
