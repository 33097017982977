import { mockDataActions } from '@cosman/mock';
import { mockPolicy } from '../../mock/pipeline-studio/mock-policy';
import { BaseService } from '../base-service';

export class PipelineStudioService extends BaseService {
  constructor() {
    super({ serviceName: 'PipelineStudio' });
  }

  injectMockPolicy() {
    mockDataActions(this.http, mockPolicy);
  }
}

export const pipelineStudioService = new PipelineStudioService();
