import _ from 'underscore';
import { useCallback, useEffect, useState } from 'react';
import { cosmanService as http } from '../../../services';

export const useFavorite = () => {
  const [favorites, setFavorites] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const convert = useCallback((data) => {
    return _.chain(data).groupBy('sourceType').value();
  }, []);

  const addFavorite = useCallback((sourceType, sourceIdentity) => {
    http.post('/v1.0/favorites/me', {}, {
      params: {
        sourceType,
        sourceIdentity,
        validationTarget: true,
      },
    }).then((response) => {
      setFavorites(convert(response));
    });
  }, [convert]);

  const cancelFavorite = useCallback((sourceType, sourceIdentity) => {
    http.post('/v1.0/favorites/me', {}, {
      params: {
        sourceType,
        sourceIdentity,
        validationTarget: false,
      },
    }).then((response) => {
      setFavorites(convert(response));
    });
  }, [convert]);

  useEffect(() => {
    setIsLoading(true);

    http.get('/v1.0/favorites/me').then((response) => {
      setFavorites(convert(response));
    }).finally(() => {
      setIsLoading(false);
    });
  }, [convert]);

  return {
    favorites,
    addFavorite,
    cancelFavorite,
    isLoading,
  };
};
