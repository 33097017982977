import _ from 'underscore';
import PropTypes from 'prop-types';
import { h, c } from '@cosman/utils';
import { withStyles } from '@cosman/functions';
import { getStyles } from './index.styles';

const AggregatedMetricPure = (props) => {
  const {
    className,
    color,
    metricValue,
    metricDescription,
  } = props;

  return h(
    'div',
    { className: c(className, 'metric-container') },
    h(
      'div',
      { className: c('color-strip'), style: { backgroundColor: color } },
    ),
    h(
      'div',
      { className: c('metric-info') },
      h(
        'div',
        { className: c('metric-value') },
        metricValue,
      ),
      h(
        'div',
        { className: c('metric-description') },
        metricDescription,
      ),
    ),
  );
};

AggregatedMetricPure.propTypes = {
  disabled: PropTypes.bool,
  buttonType: PropTypes.string,
};

AggregatedMetricPure.defaultProps = {
  disabled: false,
  buttonType: null,
};

const wrap = _.compose(
  (Component) => withStyles(Component, getStyles),
);

export const AggregatedMetric = wrap(AggregatedMetricPure);
