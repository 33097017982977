import _ from 'underscore';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { h } from '@cosman/utils';
import { withStyles } from '@cosman/functions';
import { operators, dataTypes } from '../../../../config';
import { Button } from '../../../../basic';
import { Radio } from '../../../Radio';
import { getStyles } from './index.styles';

const FieldSelectorPure = (props) => {
  const {
    className,
    fields,
    onChange,
  } = props;

  const [selected, setSelected] = useState(null);

  const handleSelect = useCallback((event) => {
    setSelected({ name: event.value });
  }, []);

  const handleApply = useCallback(() => {
    !_.isEmpty(selected) && onChange({ filter: selected });
  }, [onChange, selected]);

  return h(
    'div',
    { className },
    h(
      'h2',
      { className: 'field-selector-header' },
      'Select a field',
    ),
    h(
      'div',
      { className: 'field-selector-options' },
      _.map(fields, (field) => h(
        Radio,
        {
          key: field.name,
          name: 'field-group',
          value: field.name,
          label: field.label,
          checked: selected && selected.name === field.name,
          onChange: handleSelect,
        },
        field.label,
      )),
    ),
    h(
      'div',
      { className: 'field-selector-actions' },
      h(
        Button,
        {
          className: 'apply-button',
          label: 'Apply',
          buttonType: 'primary',
          onClick: handleApply,
        },
      ),
    ),
  );
};

FieldSelectorPure.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    dataType: PropTypes.oneOf(_.keys(dataTypes)).isRequired,
    operators: PropTypes.arrayOf(PropTypes.oneOf(_.keys(operators))).isRequired,
    options: PropTypes.arrayOf(PropTypes.string),
  })),
  onChange: PropTypes.func,
};

FieldSelectorPure.defaultProps = {
  fields: [],
  onChange: _.noop,
};

const wrap = _.compose(
  (Component) => withStyles(Component, getStyles),
);

export const FieldSelector = wrap(FieldSelectorPure);
