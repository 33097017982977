import _ from 'underscore';
import { MockInstance } from './mock-instance';

export class MockAdapter {
  constructor(http) {
    _.assign(this, { httpGet: http.get, httpPost: http.post });
    _.assign(http, { get: (...args) => this.get(...args), post: (...args) => this.post(...args) });
  }

  setPolicy(mockPolicy) {
    mockPolicy(this.mockInstance = new MockInstance());
  }

  mockRequest(method, url, ...args) {
    return this.mockInstance.resolve(method, url, ...args);
  }

  get(url, options) {
    const reply = this.mockRequest('get', url, options);
    const { result } = reply || {};

    if (!_.isUndefined(result)) {
      return Promise.resolve(result);
    }

    return this.httpGet(url, options);
  }

  post(url, payload, options) {
    const reply = this.mockRequest('post', url, payload, options);
    const { result } = reply || {};

    if (!_.isUndefined(result)) {
      return Promise.resolve(result);
    }

    return this.httpPost(url, payload, options);
  }
}
