export const operators = {
  eq: {
    label: '==',
    executor: (current, search) => current === search,
  },
  ne: {
    label: '!=',
    executor: (current, search) => current !== search,
  },
  gt: {
    label: '>',
    executor: (current, search) => current > search,
  },
  gte: {
    label: '>=',
    executor: (current, search) => current >= search,
  },
  lt: {
    label: '<',
    executor: (current, search) => current < search,
  },
  lte: {
    label: '<=',
    executor: (current, search) => current <= search,
  },
  contains: {
    label: 'contains',
    executor: (current, search) => current.indexOf(search) > -1,
  },
  notContains: {
    label: 'not contains',
    executor: (current, search) => current.indexOf(search) === -1,
  },
  startsWith: {
    label: 'starts with',
    executor: (current, search) => current.indexOf(search) === 0,
  },
  endsWith: {
    label: 'ends with',
    executor: (current, search) => current.lastIndexOf(search) === current.length - search.length,
  },
};

export const dataTypes = {
  string: {
    parser: (value) => value,
    operators: ['eq', 'ne', 'contains', 'notContains', 'startsWith', 'endsWith'],
  },
  number: {
    parser: (value) => Number(value),
    operators: ['eq', 'ne', 'gt', 'gte', 'lt', 'lte'],
  },
  enum: {
    parser: (value) => value,
    operators: ['eq', 'ne'],
  },
  date: {
    parser: (value) => new Date(value),
    operators: ['eq', 'ne', 'gt', 'gte', 'lt', 'lte'],
  },
};
