export const getStyles = () => ({
  background: '#ffffff',

  '& td .copy-button': {
    display: 'none',
  },

  '& td:hover .copy-button': {
    display: 'block',
  },
});
