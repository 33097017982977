export const getStyles = () => ({
  '& a': {
    alignItems: 'center',
    background: 'rgba(0, 0, 0, 0.05)',
    borderRadius: '2px',
    boxSizing: 'border-box',
    cursor: 'default',
    display: 'flex',
    height: '32px',
    justifyContent: 'center',
    outline: 'none',
    overflow: 'hidden',
    width: '32px',
  },

  '& a:hover': {
    background: 'rgba(0, 0, 0, 0.2)',
    textDecoration: 'none',
  },

  '& a:focus': {
    border: 'solid 2px #0078d4',
    borderRadius: '0px',
  },
});
