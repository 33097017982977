import _ from 'underscore';
import { Observer } from '@cosman/utils';
import { serverContext } from '@cosman/server-context';

const tokens = {};
const instrumentation = {};
const config = {};

const injectTokens = (value) => {
  _.assign(tokens, value);
};

const injectInstrumentation = (value) => {
  _.assign(instrumentation, value);
};

const loadAppSettings = () => {
  _.assign(config, serverContext);
};

const acquireToken = (tokenName) => {
  return tokens && tokens[tokenName];
};

const getBaseUrl = (serviceName) => {
  const services = _.mapObject(config.baseUrls, (value, key) => ({ key, value }));
  const service = _.chain(services).filter((item) => item.key.toLowerCase() === serviceName.toLowerCase()).first().value();
  const baseUrl = service && service.value;

  return baseUrl;
};

export const context = {
  instrumentation,
  loadAppSettings,
  injectInstrumentation,
  injectTokens,
  acquireToken,
  getBaseUrl,
  observer: new Observer(),
};
