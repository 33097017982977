import PropTypes from 'prop-types';
import { h } from '@cosman/utils';

export const HighlightText = ({ text, highlight }) => {
  const escapeRegExp = (string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const parts = text.split(new RegExp(`(${escapeRegExp(highlight)})`, 'gi'));

  return h('div', {}, parts.map((part, index) => (part.toLowerCase() === highlight.toLowerCase()
    ? h('span', { key: `${part}-${index}`, style: { backgroundColor: 'yellow' } }, part)
    : part)));
};

HighlightText.propTypes = {
  text: PropTypes.string.isRequired,
  highlight: PropTypes.string.isRequired,
};
