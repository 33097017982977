/* eslint-disable max-len */

import * as registration from '@cosman/registration-next';
import * as cosmosInsight from '@cosman/cosmos-insight';
import * as blueshiftInsight from '@cosman/blueshift-insight';
import * as genevaInsight from '@cosman/geneva-insight';
import * as kustoInsight from '@cosman/kusto-insight';
import * as gemReport from '@cosman/cost';
import * as cost from '@cosman/cost-v2';
import * as actualCost from '@cosman/actual-cost';
import * as search from '@cosman/search';
import * as jobHistory from '@cosman/job-history';
import * as help from '@cosman/help';
import * as homepage from '@cosman/homepage';
import * as adminPortal from '@cosman/admin-portal';
import * as workshop from '@cosman/workshop';
import * as serviceInsight from '@cosman/service-insight';
import * as cosmicCogs from '@cosman/cosmic-cogs';
import { cosmanService as http } from '@cosman/data-actions';

export const routes = {
  ...homepage.routes,
  ...adminPortal.routes,
  ...workshop.routes,
  ...search.routes,
  ...registration.routes,
  ...cosmosInsight.routes,
  ...blueshiftInsight.routes,
  ...genevaInsight.routes,
  ...kustoInsight.routes,
  ...gemReport.routes,
  ...cost.routes,
  ...actualCost.routes,
  ...jobHistory.routes,
  ...help.routes,
  ...adminPortal.routes,
  ...serviceInsight.routes,
  ...cosmicCogs.routes,
  '/clear': () => http.get('/CacheService/Clear'),
};

export const menus = [
  ...homepage.menus,
  ...workshop.menus,
  ...registration.menus,
  ...cosmosInsight.menus,
  ...blueshiftInsight.menus,
  ...genevaInsight.menus,
  ...kustoInsight.menus,
  ...gemReport.menus,
  ...cost.menus,
  ...actualCost.menus,
  ...search.menus,
  ...jobHistory.menus,
  ...help.menus,
  ...adminPortal.menus,
  ...serviceInsight.menus,
  ...cosmicCogs.menus,
];

export const actions = [
  {
    label: 'Suggest a Feature',
    iconName: 'SingleColumnEdit',
    onTrigger: () => {
      window.open('https://dev.azure.com/o365Exchange/O365%20Core/_workitems/create/Task?templateId=a249832b-dc8a-4b51-a0d3-73586636180d&ownerId=5ab0e819-26ab-4c86-8914-1ed9c4e82e4e', '_blank');
    },
  },
  {
    label: 'Report a Problem',
    iconName: 'Bug',
    onTrigger: () => {
      window.open('https://dev.azure.com/o365Exchange/O365%20Core/_workitems/create/Bug?templateId=2598a35e-ab58-4f1b-b0cf-c81c8093a6a5&ownerId=5ab0e819-26ab-4c86-8914-1ed9c4e82e4e', '_blank');
    },
  },
];
