import _ from 'underscore';

export const aggregations = {
  min: {
    aggregate: (values) => _.min(values),
  },
  max: {
    aggregate: (values) => _.max(values),
  },
  sum: {
    aggregate: (values) => _.reduce(values, (memo, curr) => memo + curr, 0),
  },
  average: {
    aggregate: (values) => (values && values.length > 0 ? (_.reduce(values, (memo, curr) => memo + curr, 0) / values.length) : 0),
  },
  count: {
    aggregate: (values) => (values && values.length > 0 ? values.length : 0),
  },
};
