import _ from 'underscore';
import PropTypes from 'prop-types';
import { v4 as guid } from 'uuid';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { h, c } from '@cosman/utils';
import { withStyles } from '@cosman/functions';
import { operators, dataTypes } from '../../config';
import { FilterItem } from './components';
import { getStyles } from './index.styles';

const FilterGroupPure = (props) => {
  const {
    className,
    fields,
    value: originalValue,
    disabled,
    onChange,
  } = props;

  const [temp, setTemp] = useState({});
  const [initialized, setInitialized] = useState(false);

  const value = useMemo(() => {
    return _.map(originalValue, (filter) => _.assign({}, filter, { guid: filter.guid || guid() }));
  }, [originalValue]);

  const extendedValue = useMemo(() => {
    return [...value, _.isEmpty(temp) ? { guid: guid() } : temp];
  }, [temp, value]);

  const calculateQuery = useCallback((updated) => (data) => {
    const ref = {
      chain: _.chain(data),
    };

    _.each(updated, (filter) => {
      const { name, operator } = filter || {};
      const { executor } = operators[operator] || {};

      if (_.isEmpty(name) || _.isEmpty(operator) || !_.isFunction(executor)) {
        return;
      }

      try {
        const { dataType } = _.find(fields, (field) => field.name === name);
        const { parser } = dataTypes[dataType] || ((v) => v);

        ref.chain = ref.chain.filter((record) => executor(parser(record[name]), parser(filter.value)));
      } catch (e) {
        // do nothing
      }
    });

    return ref.chain.value();
  }, [fields]);

  const handleChange = useCallback((event) => {
    if (disabled) {
      return;
    }

    const left = value.slice(0, event.index);
    const right = value.slice(event.index + 1);
    const exclude = _.isEmpty(event.filter) || _.isUndefined(event.filter.operator) || _.isUndefined(event.filter.value);
    const updated = exclude ? [...left, ...right] : [...left, event.filter, ...right];
    const callback = calculateQuery(updated);

    if (!_.isEqual(updated, value)) {
      onChange({ value: updated, filter: callback });
    }

    setTemp(exclude && !_.isEmpty(event.filter) ? event.filter : null);
  }, [calculateQuery, disabled, value, onChange]);

  const handleCancel = useCallback(() => {
    setTemp((prev) => (_.isEmpty(prev) ? prev : {}));
  }, []);

  useEffect(() => {
    if (!initialized && !disabled) {
      onChange({ value, filter: calculateQuery(value) });
      setInitialized(true);
    }
  }, [calculateQuery, disabled, value, initialized, onChange]);

  return h(
    'div',
    { className: c(className, 'filter-group-component', { disabled }) },
    h(
      'div',
      { className: 'filter-group' },
      _.map(extendedValue, (filter, index) => h(
        FilterItem,
        {
          key: filter.guid,
          className: 'filter-item',
          filter,
          fields,
          index,
          disabled,
          onChange: handleChange,
          onCancel: handleCancel,
        },
      )),
    ),
  );
};

FilterGroupPure.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    dataType: PropTypes.oneOf(_.keys(dataTypes)).isRequired,
    operators: PropTypes.arrayOf(PropTypes.oneOf(_.keys(operators))).isRequired,
    options: PropTypes.arrayOf(PropTypes.string),
  })),
  value: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    operator: PropTypes.oneOf(_.keys(operators)),
    value: PropTypes.any,
  })),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

FilterGroupPure.defaultProps = {
  fields: [],
  value: [],
  disabled: false,
  onChange: _.noop,
};

const wrap = _.compose(
  (Component) => withStyles(Component, getStyles),
);

export const FilterGroup = wrap(FilterGroupPure);
