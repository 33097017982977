import _ from 'underscore';
import s from 'underscore.string';
import moment from 'moment';
import { colors } from '@cosman/config';

export const historialChartDataConverter = (chartData, key = 'dailyCost',
  { chartColorMapping = {}, title = '', yAxisName = 'Cost ($)', stacked = true,
    valueFormatter = (v) => (`$${s.numberFormat(v, 2) || 0}`),
    seriesNameFormatter = (seriesName) => (`${seriesName ? `${seriesName}: ` : ''}`),
  }) => {
  if (_.isEmpty(chartData)) {
    return { title };
  }
  const dateSet = new Set();
  const convertedChartData = _.mapObject(chartData, (series) => {
    const dateValueMap = {};
    const dailyCost = series[key];
    _.each(dailyCost, ({ date, value }) => {
      const convertedDate = date.length === 10 ? date : moment(date).format('YYYY-MM-DD');
      dateSet.add(convertedDate);
      dateValueMap[convertedDate] = value;
    });
    return { ...series, [key]: dateValueMap };
  });

  const dateList = Array.from(dateSet).sort();
  const seriesChartDataList = [];
  _.map(convertedChartData, (series, seriesName) => {
    if (chartColorMapping[seriesName] === undefined) {
      // eslint-disable-next-line no-param-reassign
      chartColorMapping[seriesName] = colors.getPieChartColor(_.size(chartColorMapping));
    }
    const seriesChartData = {
      color: chartColorMapping[seriesName],
      name: seriesName,
      lineStyle: {
        color: chartColorMapping[seriesName],
      },
      type: 'line',
      smooth: true,
      connectNulls: false,
    };
    if (stacked) {
      seriesChartData.areaStyle = {};
      seriesChartData.stack = 'total';
    }
    const dateValueMap = series[key];
    const values = [];
    dateList.forEach((date) => {
      const v = dateValueMap[date];
      if (v !== null && v !== undefined) {
        values.push(v);
      } else {
        values.push(0); // if set null, the chart will look strange.
      }
    });
    seriesChartData.data = values;
    seriesChartDataList.push(seriesChartData);
  });
  return {
    title,
    option: {
      yAxisName,
      labels: dateList,
      xAxisType: 'category',
      series: seriesChartDataList.sort((a, b) => b.data[0] - a.data[0]), // sort by the first day value
      tooltip: stacked ? {
        confine: true,
        appendToBody: true,
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
        },
        backgroundColor: 'rgba(0,0,0,0.5);',
        borderColor: 'transparent',
        textStyle: {
          color: '#fff',
        },
        formatter: (params) => {
          const date = params[0].name;
          const colorSpan = (color) => `<span style="display:inline-block;border-radius:10px;width:10px;height:10px;background:${color};"></span>`;
          const serieses = _.chain(params)
            .map((param) => `
          ${colorSpan(param.color)}
          ${seriesNameFormatter(param.seriesName)} 
          ${valueFormatter(param.value)}`)
            .join('<br />')
            .value();
          return `DateTime(UTC): ${date}<br />${serieses}`;
        },
      } : {
        confine: true,
        appendToBody: true,
        trigger: 'item',
        axisPointer: {
          type: 'cross',
        },
        backgroundColor: 'rgba(0,0,0,0.5);',
        borderColor: 'transparent',
        textStyle: {
          color: '#fff',
        },
        formatter: (params) => {
          const date = params.name;
          const colorSpan = (color) => `<span style="display:inline-block;border-radius:10px;width:10px;height:10px;background:${color};"></span>`;
          return `DateTime(UTC): ${date}<br />
                ${colorSpan(params.color)}
                ${seriesNameFormatter(params.seriesName)} 
                ${valueFormatter(params.value)}
              `;
        },
      },
    },
  };
};
