import _ from 'underscore';
import { useAccount as userAccountBase, useMsal } from '@azure/msal-react';

const parseAlias = (email) => {
  const regex = /([^\n]+)(@[^\n]+)/;
  const [, user, domain] = regex.exec(email);
  const strategy = strategies[domain] || strategies['@others'];

  return strategy(user);
};

const parseFromUser = (user, regex) => {
  const match = regex.exec(user);
  const alias = _.isEmpty(match) ? user : match[1];

  return alias;
};

const strategies = {
  '@microsoft.com': (user) => user,
  '@prdtrs01.prod.outlook.com': (user) => parseFromUser(user, /([^\n]+)+(_jit|_debug)/),
  '@others': (user, email) => email,
};

export const useAccount = () => {
  const { accounts } = useMsal();
  const account = userAccountBase(accounts[0] || {});

  return {
    ...account,
    alias: parseAlias(account.username),
  };
};
