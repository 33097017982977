import _ from 'underscore';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { h, c } from '@cosman/utils';
import { withStyles } from '@cosman/functions';
import { aggregations } from '../../../../config';
import { Button } from '../../../../basic';
import { getStyles } from './index.styles';

const TableGroupingOptionsPure = (props) => {
  const {
    className,
    groupBy,
    groupByOptions,
    onSelect,
  } = props;

  const handleSelect = useCallback((event) => {
    onSelect(event);
  }, [onSelect]);

  const handleDeselect = useCallback((event) => {
    event.stopPropagation();
    onSelect({});
  }, [onSelect]);

  return h(
    'div',
    { className },
    h(
      'h2',
      { className: 'label' },
      'Group by',
    ),
    h(
      'p',
      { className: 'description' },
      'Select predefined set of columns, to summarize the metrics.',
    ),
    h(
      'ul',
      {
        className: 'options',
      },
      _.map(groupByOptions, (option) => h(
        'li',
        {
          key: option.name,
          className: c('option-item', { selected: groupBy === option.name }),
          onClick: () => handleSelect({ option }),
        },
        h(
          'div',
          { className: 'pair' },
          h('div', { className: 'name' }, 'Name: '),
          h('div', { className: 'value' }, option.label),
        ),
        h(
          'div',
          { className: 'pair' },
          h(
            'div',
            { className: 'name' },
            'Columns: ',
          ),
          h(
            'div',
            { className: 'value', title: _.map(option.columns, (column) => column.label).join(', ') },
            _.map(option.columns, (column, index) => h(
              React.Fragment,
              { key: index },
              h('strong', {}, column.label),
              index < option.columns.length - 1 && h('span', { dangerouslySetInnerHTML: { __html: ',&nbsp;' } }),
            )),
          ),
        ),
        groupBy === option.name && h(
          'div',
          { className: 'action' },
          h(Button, {
            iconName: 'CalculatorMultiply',
            onClick: handleDeselect,
          }),
        ),
      )),
    ),
  );
};

TableGroupingOptionsPure.propTypes = {
  groupBy: PropTypes.string,
  groupByOptions: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    columns: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
    })).isRequired,
    aggregations: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
      method: PropTypes.oneOf(_.keys(aggregations)),
    })).isRequired,
  })),
  onSelect: PropTypes.func,
};

TableGroupingOptionsPure.defaultProps = {
  groupBy: null,
  groupByOptions: undefined,
  onSelect: _.noop,
};

const wrap = _.compose(
  (Component) => withStyles(Component, getStyles),
);

export const TableGroupingOptions = wrap(TableGroupingOptionsPure);
