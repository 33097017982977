import _ from 'underscore';
import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { cosmanDataActions } from '@cosman/data-actions';
import { getDisplayName } from '@cosman/utils';

export const FavoriteContext = React.createContext({
  favorites: [],
  addFavorite: _.noop,
  cancelFavorite: _.noop,
  isLoading: false,
});

export const withFavoriteContext = (Component) => {
  const {
    useFavorite,
  } = cosmanDataActions;

  const HoC = React.memo((props) => {
    const {
      favorites,
      addFavorite,
      cancelFavorite,
      isLoading,
    } = useFavorite();

    return React.createElement(
      FavoriteContext.Provider,
      { value: { favorites, addFavorite, cancelFavorite, isLoading } },
      React.createElement(Component, props),
    );
  });

  HoC.displayName = `withFavoriteContext(${getDisplayName(Component)})`;

  return hoistNonReactStatics(HoC, Component);
};
