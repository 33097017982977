import _ from 'underscore';
import axios from 'axios';
import { v4 as generateGuid } from 'uuid';
import { context } from '../context';

export class BaseService {
  constructor({
    serviceName,
  }) {
    this.context = context;
    this.context.loadAppSettings();
    this.http = axios.create({ baseURL: context.getBaseUrl(serviceName) });
    this.instrumentation = context.instrumentation;
    this.intercept();
    this.injectMockPolicy();
  }

  get(url, options) {
    const requestId = generateGuid();
    const method = 'get';

    this.logInfo({ requestId, url, options, method, status: 'Started' });

    return this.http.get(url, options).then((response) => {
      this.logInfo({ requestId, url, options, method, status: `Http ${response.status}` });
      return response.data;
    }).catch((e) => {
      this.context.observer.trigger('error', e);
      this.logInfo({ requestId, url, options, method, status: `Http ${e.status}` });
      this.logError({ requestId, url, options, method, error: e });
      throw (e);
    });
  }

  post(url, payload, options) {
    const requestId = generateGuid();
    const method = 'post';

    this.logInfo({ requestId, url, payload, options, method, status: 'Started' });

    return this.http.post(url, payload, options).then((response) => {
      this.logInfo({ requestId, url, payload, options, method, status: `Http ${response.status}` });
      return response.data;
    }).catch((e) => {
      this.context.observer.trigger('error', e);
      this.logInfo({ requestId, url, payload, options, method, status: `Http ${e.status}` });
      this.logError({ requestId, url, payload, options, method, error: e });
      throw (e);
    });
  }

  delete(url, payload, options) {
    const requestId = generateGuid();
    const method = 'delete';
    this.logInfo({ requestId, url, payload, options, method, status: 'Started' });

    return this.http({
      method,
      url,
      data: payload,
      ...options,
    }).then((response) => {
      this.logInfo({ requestId, url, payload, options, method, status: `Http ${response.status}` });
      return response.data;
    }).catch((e) => {
      this.context.observer.trigger('error', e);
      this.logInfo({ requestId, url, payload, options, method, status: `Http ${e.status}` });
      this.logError({ requestId, url, payload, options, method, error: e });
      throw (e);
    });
  }

  intercept() {
    this.http.interceptors.request.use((request) => {
      return this.injectToken(request, this.acquireToken());
    });
  }

  injectToken(request, token) {
    return _.assign({}, request, !_.isEmpty(token) && { headers: { Authorization: `Bearer ${token}` } });
  }

  injectMockPolicy() {
    throw new Error('The mock policy is not implemented');
  }

  acquireToken() {
    const acquiredToken = this.context.acquireToken('bearerToken');
    const accessToken = _.isFunction(acquiredToken) ? acquiredToken() : acquiredToken;

    return accessToken;
  }

  logInfo(data) {
    this.instrumentation.logInfo({ ...data, timestamp: new Date().valueOf() });
  }

  logError(data) {
    this.instrumentation.logError({ ...data, timestamp: new Date().valueOf() });
  }
}
