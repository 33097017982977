import { v4 as generateGuid } from 'uuid';
import { mockDataActions } from '@cosman/mock';
import { mockPolicy } from '../../mock/blueshift-buildout/mock-policy';
import { BaseService } from '../base-service';

export class BlueshiftBuildoutService extends BaseService {
  constructor() {
    super({ serviceName: 'BlueshiftBuildout' });
  }

  injectMockPolicy() {
    mockDataActions(this.http, mockPolicy);
  }

  async patch(url, payload, options) {
    const requestId = generateGuid();
    const method = 'patch';

    this.logInfo({ requestId, url, payload, options, method, status: 'Started' });

    try {
      const response = await this.http.patch(url, payload, options);
      this.logInfo({ requestId, url, payload, options, method, status: `Http ${response.status}` });

      return response.data;
    } catch (e) {
      this.context.observer.trigger('error', e);
      this.logInfo({ requestId, url, payload, options, method, status: `Http ${e.status}` });
      this.logError({ requestId, url, payload, options, method, error: e });

      throw (e);
    }
  }
}

export const blueshiftBuildoutService = new BlueshiftBuildoutService();
