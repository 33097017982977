import _ from 'underscore';
import { useMemo } from 'react';
import { useLocation } from 'react-router';

export const useQueryParams = () => {
  const location = useLocation();
  const { search } = location;

  const params = useMemo(() => {
    return _
      .chain(search.slice(1).split('&'))
      .map((item) => {
        const regex = /([^=]+)=([^?&]*)/;
        const result = regex.exec(item);
        return result ? [result[1], decodeURIComponent(result[2])] : null;
      })
      .compact()
      .object()
      .value();
  }, [search]);
  return params;
};
