/* eslint-disable no-console */
// using application insights to provide a default implementation

import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import { globalSettings } from '@cosman/core';

const { environment, instrumentationSettings } = globalSettings;
const { logLevel, logEnableConsoleOutput, enableAppInsights, appInsightsInstrumentationKey } = instrumentationSettings;

const appInsights = enableAppInsights ? new ApplicationInsights({
  config: { instrumentationKey: appInsightsInstrumentationKey, enableAutoRouteTracking: true },
}) : null;

appInsights && appInsights.loadAppInsights();
appInsights && appInsights.trackPageView();

const LogLevels = {
  debug: SeverityLevel.Verbose,
  info: SeverityLevel.Information,
  warn: SeverityLevel.Warning,
  error: SeverityLevel.Error,
  fatal: SeverityLevel.Critical,
};

const log = (level, data, props = {}) => {
  if (level >= logLevel) {
    appInsights && appInsights.trackTrace({
      message: data,
      severityLevel: level,
      properties: { source: 'client', environment, ...props },
    });
    if (logEnableConsoleOutput) {
      console.log(data, props);
    }
  }
};

const logDebug = (data, props) => {
  log(LogLevels.debug, data, props);
};

const logInfo = (data, props) => {
  log(LogLevels.info, data, props);
};

const logWarn = (data, props) => {
  log(LogLevels.warn, data, props);
};

const logError = (data, props) => {
  log(LogLevels.error, data, props);
};

const logFatal = (data, props) => {
  log(LogLevels.fatal, data, props);
};

const trackException = (ex, props) => {
  if (ex) {
    appInsights && appInsights.trackException({
      exception: ex,
      properties: { source: 'client', environment, ...props },
    });
  }
  if (logEnableConsoleOutput) {
    console.log(ex, props);
  }
};

const trackEvent = (name, props) => {
  appInsights && appInsights.trackEvent({
    name,
    properties: { source: 'client', environment, ...props },
  });
  if (logEnableConsoleOutput) {
    console.log(name, props);
  }
};

export const instrumentation = {
  LogLevels,
  logDebug,
  logInfo,
  logWarn,
  logError,
  logFatal,
  trackException,
  trackEvent,
};
