import _ from 'underscore';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useMsal } from '@azure/msal-react';
import { withDefaultStyles } from '@cosman/functions';
import { getStyles } from './index.styles';

const UserRoleFilterPure = (props) => {
  const {
    classes,
    children,
    requiredRoles,
    onFilter,
  } = props;

  const { instance } = useMsal();

  const handleLogout = useCallback(() => {
    localStorage.removeItem('accessToken');
    instance.logoutRedirect();
  }, [instance]);

  if (onFilter(...requiredRoles)) {
    return children;
  }

  return React.createElement(
    'div',
    { className: classes.component },
    'You do not have access to Cosman. Please contact ',
    React.createElement('a', { className: 'contact', href: 'mailto:cosmanadmin@microsoft.com' }, 'cosmanadmin@microsoft.com'),
    ' for help. ',
    React.createElement('button', { className: 'logout', type: 'button', onClick: handleLogout }, 'Log out'),
  );
};

UserRoleFilterPure.propTypes = {
  classes: PropTypes.object.isRequired,
  requiredRoles: PropTypes.arrayOf(PropTypes.string),
  onFilter: PropTypes.func,
};

UserRoleFilterPure.defaultProps = {
  requiredRoles: ['App.Read'],
  onFilter: () => false,
};

const wrap = _.compose(
  (Component) => withDefaultStyles(Component, getStyles),
);

export const UserRoleFilter = wrap(UserRoleFilterPure);
