export const getStyles = () => ({
  display: 'flex',
  position: 'relative',
  width: '250px',

  '& .select-label': {
    alignItems: 'center',
    background: '#ffffff',
    border: 'solid 1px #605e5c',
    borderRadius: '2px',
    boxSizing: 'border-box',
    cursor: 'default',
    display: 'flex',
    height: '32px',
    outline: 'none',
    padding: '0px 32px 0px 9px',
    width: '100%',
  },

  '& .select-label span': {
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },

  '& .select-label .icon-component': {
    height: '100%',
    position: 'absolute',
    right: '0px',
    top: '0px',
    width: '32px',
  },

  '& .select-label:focus': {
    border: 'solid 2px #0078d4',
    borderRadius: '0px',
    paddingLeft: '8px',
  },

  '& .select-label:hover': {
    background: 'rgba(0, 0, 0, 0.1)',
  },

  '&.disabled .select-label': {
    borderColor: '#d0d0d0',
    color: '#d0d0d0',
  },

  '&.disabled .select-label:focus': {
    border: 'solid 1px #d0d0d0',
    paddingLeft: '9px',
    color: '#d0d0d0',
  },

  '&.disabled .select-label:hover': {
    background: 'transparent',
  },
});
