import _ from 'underscore';
import { h, c } from '@cosman/utils';
import React from 'react';
import injectSheet, { ThemeProvider } from 'react-jss';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { colors as colorsDef } from '@cosman/config';

const getDisplayName = (Component) => {
  return (Component.displayName || Component.name || 'Component').toLowerCase();
};

const injectClassName = (converter) => (Component) => {
  const Wrapper = React.forwardRef((props, ref) => {
    const { classes, className } = props;
    const newProps = _.omit(props, 'classes', 'className');

    return h(
      Component,
      { ...newProps, className: converter({ classes, className }), ref },
    );
  });

  Wrapper.displayName = getDisplayName(Component);
  Wrapper.defaultProps = { ...Component.defaultProps };
  Wrapper.InnerComponent = Component;

  hoistNonReactStatics(Wrapper, Component);

  return Wrapper;
};

export const withStyles = (
  Component,
  getStyles = () => {},
  getGlobalStyles = () => {},
) => {
  const theme = {
    colors: colorsDef,
  };

  const NamedComponent = injectClassName(({
    className,
    classes = {},
  }) => {
    return c(classes.component, className);
  })(Component);

  const StyledComponent = injectSheet(({
    colors = {},
  }) => ({
    component: getStyles({ colors }),
    '@global': getGlobalStyles({ colors }),
  }))(NamedComponent);

  const Wrapper = (props) => {
    return h(
      ThemeProvider,
      { theme },
      h(StyledComponent, { ...props }),
    );
  };

  hoistNonReactStatics(Wrapper, Component);

  return Wrapper;
};
