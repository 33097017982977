import _ from 'underscore';
import { v4 as generateGuid } from 'uuid';
import { CancelToken } from 'axios';
import { mockDataActions } from '@cosman/mock';
import { mockPolicy } from '../../mock/cosman-copilot/mock-policy';
import { BaseService } from '../base-service';

const parseSSEFlow = (data) => {
  const dataList = _.chain(data.split('\n'))
    .map((line) => line.trim())
    .filter((line) => line.length > 0)
    .filter((line) => line.startsWith('data:'))
    .map((line) => line.replace('data:', ''))
    .value();
  return dataList;
};

export class CosmanCopilotService extends BaseService {
  constructor() {
    super({ serviceName: 'CosmanCopilot' });
  }

  injectMockPolicy() {
    mockDataActions(this.http, mockPolicy);
  }

  // Post with Server Sent Event Response
  postWithSSEResponse(url, payload, options, onData, getCancel) {
    const requestId = generateGuid();
    const method = 'post';
    const source = CancelToken.source();
    getCancel(source.cancel);
    const { token } = source;
    const newOptions = {
      ...options,
      onDownloadProgress: async (progressEvent) => {
        const { currentTarget } = progressEvent;
        const { response } = currentTarget;
        const dataList = _.chain(parseSSEFlow(response).map((item) => {
          try {
            return JSON.parse(item);
          } catch { // caused by incomplete json
            return null;
          }
        }).filter((item) => item !== null)).value();
        onData(dataList);
      },
      cancelToken: token,
    };

    this.logInfo({ requestId, url, payload, newOptions, method, status: 'Started' });

    return this.http.post(url, payload, newOptions).catch((e) => {
      this.context.observer.trigger('error', e);
      this.logInfo({ requestId, url, newOptions, method, status: `Http ${e.status}` });
      this.logError({ requestId, url, newOptions, method, error: e });
      throw (e);
    });
  }
}

export const cosmanCopilotService = new CosmanCopilotService();
