import _ from 'underscore';

export const getEncodedParams = (params) => {
  const query = _
    .chain(params)
    .keys()
    .filter((key) => !_.isNull(params[key]) && !_.isUndefined(params[key]))
    .map((key) => `${key}=${encodeURIComponent(params[key])}`)
    .value();

  return query.join('&');
};
