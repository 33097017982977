import React from 'react';
import {
  GroupIcon,
  ProcessingIcon,
  KnowledgeArticleIcon,
  DataflowsIcon,
  FileSystemIcon,
} from '@fluentui/react-icons-mdl2';

export const SVGIcons = {
  icons: {
    group: React.createElement(GroupIcon),
    dataflows: React.createElement(DataflowsIcon),
    processing: React.createElement(ProcessingIcon),
    knowledgearticle: React.createElement(KnowledgeArticleIcon),
    filesystem: React.createElement(FileSystemIcon),
  },
};
