import _ from 'underscore';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { h } from '@cosman/utils';
import { withStyles } from '@cosman/functions';
import { Button } from '../../../../basic';
import { operators, dataTypes } from '../../../../config';
import { Select } from '../../../Select';
import { Input } from '../../../Input';
import { getStyles } from './index.styles';

const FieldEditorPure = (props) => {
  const {
    className,
    fields,
    filter,
    onChange,
  } = props;

  const [state, setState] = useState(filter);

  const field = useMemo(() => {
    return _.find(fields, ({ name }) => name === state.name);
  }, [fields, state.name]);

  const valueOptions = useMemo(() => {
    return _.map(field.options, (option) => ({ name: option, label: option }));
  }, [field]);

  const operatorOptions = useMemo(() => {
    return _.map(field && field.operators, (operator) => ({ name: operator, label: operators[operator].label }));
  }, [field]);

  const handleChangeOperator = useCallback((event) => {
    setState({ ...state, operator: _.chain(event.options).first().get('name').value() });
  }, [state]);

  const handleChangeSelect = useCallback((event) => {
    setState((previous) => ({ ...previous, value: _.chain(event.options).first().get('name').value() }));
  }, []);

  const handleChangeString = useCallback((event) => {
    setState((previous) => ({ ...previous, value: event.value }));
  }, []);

  const handleChangeNumber = useCallback((event) => {
    setState((previous) => ({ ...previous, value: event.value }));
  }, []);

  const handleApply = useCallback(() => {
    const { guid } = state;
    const updated = { ..._.omit(state, 'guid'), guid };

    onChange({ filter: updated });
  }, [onChange, state]);

  useEffect(() => {
    if (_.isEmpty(state.operator)) {
      setState((previous) => ({ ...previous, operator: (_.first(operatorOptions) || {}).name }));
    }
  }, [operatorOptions, state.operator]);

  return h(
    'div',
    { className },
    h(
      'h2',
      { className: 'field-editor-header' },
      field.label,
    ),
    h(
      'div',
      { className: 'field-editor-settings' },
      h(Select, {
        className: 'operator',
        options: operatorOptions,
        onChange: handleChangeOperator,
        value: state.operator && [state.operator],
      }),
      field.dataType === 'enum' && h(Select, {
        options: valueOptions,
        value: state.value && [state.value],
        onChange: handleChangeSelect,
      }),
      (field.dataType === 'string') && h(Input, {
        value: state.value,
        onChange: handleChangeString,
      }),
      (field.dataType === 'number') && h(Input, {
        value: state.value,
        onChange: handleChangeNumber,
      }),
    ),
    h(
      'div',
      { className: 'field-editor-actions' },
      h(
        Button,
        {
          className: 'apply-button',
          label: 'Apply',
          buttonType: 'primary',
          onClick: handleApply,
        },
      ),
    ),
  );
};

FieldEditorPure.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    dataType: PropTypes.oneOf(_.keys(dataTypes)).isRequired,
    operators: PropTypes.arrayOf(PropTypes.oneOf(_.keys(operators))).isRequired,
    options: PropTypes.arrayOf(PropTypes.string),
  })),
  filter: PropTypes.shape({
    name: PropTypes.string,
    operator: PropTypes.oneOf(_.keys(operators)),
    value: PropTypes.any,
  }),
  onChange: PropTypes.func,
};

FieldEditorPure.defaultProps = {
  fields: [],
  filter: null,
  onChange: _.noop,
};

const wrap = _.compose(
  (Component) => withStyles(Component, getStyles),
);

export const FieldEditor = wrap(FieldEditorPure);
