/* eslint-disable no-param-reassign */

import _ from 'underscore';
import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { h, c } from '@cosman/utils';
import { withStyles } from '@cosman/functions';
import { Button, Error, Icon } from '../../../../basic';
import { Select } from '../../../../controls';
import { getStyles } from './index.styles';

const TableColumnSelectorOptionsPure = (props) => {
  const {
    className,
    columns,
    selectedColumns,
    onSave,
    onCancel,
  } = props;

  const [showError, setShowError] = useState(false);
  const [editingColumns, setEditingColumns] = useState(_.isEmpty(selectedColumns) ? _.map(columns, ({ name }) => name) : selectedColumns);
  const [sourceIndex, setSourceIndex] = useState(null);
  const [targetIndex, setTargetIndex] = useState(null);

  const options = useMemo(() => {
    return _.chain(columns).sortBy(({ label }) => label).map(({ name, label }) => ({ name, label })).value();
  }, [columns]);

  const errors = useMemo(() => {
    const result = [];

    if (_.any(editingColumns, _.isEmpty)) {
      result.push('All columns must be selected.');
    }

    if (_.chain(editingColumns || []).uniq().compact().value().length !== _.compact(editingColumns).length) {
      result.push('Duplicate columns are not allowed.');
    }

    if (_.isEmpty(editingColumns) || editingColumns.length <= 0) {
      result.push('You need to add at least one column.');
    }

    return result;
  }, [editingColumns]);

  const handleSelect = useCallback((event) => {
    const { index } = event;
    const option = (event.options && event.options[0]) || {};

    setEditingColumns((previous) => [...previous.slice(0, index), option.name, ...previous.slice(index + 1)]);
  }, []);

  const handleAppend = useCallback(() => {
    setEditingColumns((previous) => [...previous, null]);
  }, []);

  const handleRemove = useCallback((event) => {
    setEditingColumns((previous) => [...previous.slice(0, event.index), ...previous.slice(event.index + 1)]);
  }, []);

  const handleSave = useCallback(() => {
    setShowError(true);

    if (_.isEmpty(errors)) {
      onSave({ columns: editingColumns });
    }
  }, [errors, editingColumns, onSave]);

  const handleDragStart = useCallback((event) => {
    setSourceIndex(event.index);
  }, []);

  const handleDragEnter = useCallback((event) => {
    setTargetIndex(event.index);
  }, []);

  const handleDragLeave = useCallback(() => {
    setTargetIndex(null);
  }, []);

  const handleDragEffect = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const handleDrop = useCallback(() => {
    setTargetIndex(null);

    setEditingColumns((previous) => {
      if (sourceIndex === targetIndex || sourceIndex + 1 === targetIndex) {
        return previous;
      }

      const result = [...previous];
      const source = result[sourceIndex];

      if (targetIndex < sourceIndex) {
        result.splice(sourceIndex, 1);
        result.splice(targetIndex, 0, source);
      } else {
        result.splice(targetIndex, 0, source);
        result.splice(sourceIndex, 1);
      }

      return result;
    });
  }, [sourceIndex, targetIndex]);

  return h(
    'div',
    { className },
    h(
      'h2',
      { className: 'label' },
      'Column options',
    ),
    h(
      'p',
      { className: 'description' },
      'Add or remove columns, to change the column order, drag and drop a field.',
    ),
    showError && !_.isEmpty(errors) && h(
      'div',
      { className: 'errors' },
      _.map(errors, (error) => h(Error, { key: error, message: error, severity: 'warning' })),
    ),
    h(
      'ul',
      {
        className: 'options',
        onDragEnter: handleDragEffect,
        onDragOver: handleDragEffect,
        onDrop: handleDrop,
      },
      _.map(editingColumns, (name, index) => h(
        React.Fragment,
        { key: `${index}-${name}` },
        index === 0 && h('li', {
          className: c('option-item-gap', { active: targetIndex === 0 }),
          onDragEnter: (event) => handleDragEnter({ ...event, index: 0 }),
          onDragLeave: handleDragLeave,
        }, h('span', { className: 'placeholder' })),
        h(
          'li',
          { className: 'option-item', draggable: true, onDragStart: (event) => handleDragStart({ ...event, index }) },
          h(Icon, { className: 'order', iconName: 'GripperDotsVertical' }),
          h(Select, {
            className: 'column-select',
            options: _.filter(options, (option) => option.name === name || !_.any(editingColumns, (column) => column === option.name)),
            value: name && [name],
            onChange: (event) => handleSelect({ ...event, index }),
          }),
          h(Button, { className: 'remove', iconName: 'CalculatorMultiply', onClick: () => handleRemove({ index }) }),
        ),
        h('li', {
          className: c('option-item-gap', { active: targetIndex === index + 1 }),
          onDragEnter: (event) => handleDragEnter({ ...event, index: index + 1 }),
          onDragLeave: handleDragLeave,
        }, h('span', { className: 'placeholder' })),
      )),
    ),
    h(
      'div',
      { className: 'create-new' },
      h(Button, { iconName: 'Add', label: 'Add a column', onClick: handleAppend }),
    ),
    h(
      'div',
      { className: 'actions' },
      h(Button, { className: 'cancel', label: 'Cancel', onClick: onCancel }),
      h(Button, { className: 'ok', label: 'OK', buttonType: 'primary', onClick: handleSave }),
    ),
  );
};

TableColumnSelectorOptionsPure.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  selectedColumns: PropTypes.arrayOf(PropTypes.string),
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

TableColumnSelectorOptionsPure.defaultProps = {
  columns: [],
  selectedColumns: [],
  onSave: _.noop,
  onCancel: _.noop,
};

const wrap = _.compose(
  (Component) => withStyles(Component, getStyles),
);

export const TableColumnSelectorOptions = wrap(TableColumnSelectorOptionsPure);
