import _ from 'underscore';
import hoistNonReactStatics from 'hoist-non-react-statics';
import React, { useState, useCallback } from 'react';
import { getDisplayName } from '@cosman/utils';

export const AccessControlContext = React.createContext({
  createAccessControlService: _.noop,
});

class AccessControlService {
  constructor(roles = []) {
    this.roles = roles;
    this.dynRoles = [];
  }

  hasRole(role) {
    return this.roles.includes(role) || this.dynRoles.includes(role);
  }

  injectDynamicRoles(dynRoles, promise) {
    if (promise) {
      this.dynRoles.push(dynRoles);
    }
    return this;
  }
}

export const withAccessControlContext = (Component) => {
  const resolveRoles = (roles) => {
    return _.isString(roles) ? _.compact(roles.split(',')) : [];
  };

  const HoC = React.memo((props) => {
    const [assignedRoles, setAssignedRoles] = useState(resolveRoles(localStorage.getItem('roles')));

    window.addEventListener('storage', (event) => {
      if (event.key === 'roles') {
        setAssignedRoles(resolveRoles(localStorage.getItem('roles')));
      }
    });

    const createAccessControlService = useCallback((dynRoles, promise) => {
      return new AccessControlService(assignedRoles).injectDynamicRoles(dynRoles, promise);
    }, [assignedRoles]);

    return React.createElement(
      AccessControlContext.Provider,
      { value: { createAccessControlService } },
      React.createElement(Component, props),
    );
  });

  HoC.displayName = `withAccessControlContext(${getDisplayName(Component)})`;
  return hoistNonReactStatics(HoC, Component);
};
