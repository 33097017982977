import _ from 'underscore';
import PropTypes from 'prop-types';
import { Icon as IconBase } from '@fluentui/react';
import { h, c } from '@cosman/utils';
import { withStyles } from '@cosman/functions';
import { getStyles } from './index.styles';

const IconPure = (props) => {
  const {
    className,
    title,
    iconName,
  } = props;

  return h(
    'div',
    { className: c(className, 'icon-component') },
    h(IconBase, { title, iconName }),
  );
};

IconPure.propTypes = {
  title: PropTypes.string,
  iconName: PropTypes.string,
};

IconPure.defaultProps = {
  title: null,
  iconName: 'WebAppBuilderFragment',
};

const wrap = _.compose(
  (Component) => withStyles(Component, getStyles),
);

export const Icon = wrap(IconPure);
