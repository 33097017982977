import _ from 'underscore';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { Callout, DirectionalHint } from '@fluentui/react';
import { h } from '@cosman/utils';
import { withStyles } from '@cosman/functions';
import { aggregations } from '../../../../config';
import { Button } from '../../../../basic';
import { TableGroupingOptions } from '../TableGroupingOptions';
import { getStyles } from './index.styles';

const TableGroupingPure = (props) => {
  const {
    className,
    groupBy,
    groupByOptions,
    onChange,
  } = props;

  const [opened, setOpened] = useState(false);

  const handleToggleDialogue = useCallback(() => {
    setOpened((previous) => !previous);
  }, []);

  const handleSelect = useCallback((event) => {
    onChange({ groupBy: event && event.option ? event.option.name : null });
    setOpened(false);
  }, [onChange]);

  return h(
    'div',
    { className },
    h(Button, {
      className: 'toggle-button',
      label: 'Group by',
      iconName: 'GroupList',
      onClick: handleToggleDialogue,
    }),
    opened && h(
      Callout,
      {
        className: 'select-options',
        target: `.${className.replace(/\s+/g, '.')} .toggle-button`,
        gapSpace: 0,
        calloutWidth: 360,
        onDismiss: handleToggleDialogue,
        directionalHint: DirectionalHint.bottomRightEdge,
      },
      h(
        TableGroupingOptions,
        {
          groupBy,
          groupByOptions,
          onSelect: handleSelect,
        },
      ),
    ),
  );
};

TableGroupingPure.propTypes = {
  groupBy: PropTypes.string,
  groupByOptions: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    columns: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
    })).isRequired,
    aggregations: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
      method: PropTypes.oneOf(_.keys(aggregations)),
    })).isRequired,
  })),
  onChange: PropTypes.func,
};

TableGroupingPure.defaultProps = {
  groupBy: null,
  groupByOptions: undefined,
  onChange: _.noop,
};

const wrap = _.compose(
  (Component) => withStyles(Component, getStyles),
);

export const TableGrouping = wrap(TableGroupingPure);
