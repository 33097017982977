import _ from 'underscore';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo } from 'react';
import { h, c } from '@cosman/utils';
import { withStyles } from '@cosman/functions';
import { Button } from '../../basic';
import { Select } from '../../controls/Select';
import { getStyles } from './index.styles';

const PaginationPure = (props) => {
  const {
    className,
    pageIndex,
    pageSize,
    pageSizeOptions: originalPageSizeOptions,
    pageCount,
    siblingCount,
    enablePageSizeOptions,
    onChange,
  } = props;

  const pageSizeOptions = useMemo(() => {
    return originalPageSizeOptions.map((option) => ({ name: option.toString(), label: option.toString() }));
  }, [originalPageSizeOptions]);

  const selectedPageSize = useMemo(() => {
    return [pageSize.toString()];
  }, [pageSize]);

  const paginationRange = useMemo(() => {
    const range = (start, end) => {
      return Array.from({ length: end - start + 1 }, (value, index) => index + start);
    };

    if (2 * siblingCount + 5 >= pageCount) {
      return range(1, pageCount);
    }

    const leftSiblingPageNumber = Math.max(pageIndex - siblingCount, 1);
    const rightSiblingPageNumber = Math.min(pageIndex + siblingCount, pageCount);

    if (leftSiblingPageNumber <= 3) {
      return [...range(1, 2 + 1 + 2 * siblingCount), 'RightDots', pageCount];
    }

    if (rightSiblingPageNumber >= pageCount - 2) {
      return [1, 'LeftDots', ...range(pageCount - (3 + 2 * siblingCount) + 1, pageCount)];
    }

    return [1, 'LeftDots', ...range(leftSiblingPageNumber, rightSiblingPageNumber), 'RightDots', pageCount];
  }, [pageIndex, siblingCount, pageCount]);

  const handleChange = useCallback((event) => {
    const result = _.assign({}, { pageIndex: event.pageIndex }, enablePageSizeOptions && { pageSize: event.pageSize });

    onChange(result);
  }, [enablePageSizeOptions, onChange]);

  const handleClickNext = useCallback(() => {
    handleChange({
      pageIndex: Math.min(pageIndex + 1, pageCount),
      pageSize,
    });
  }, [pageIndex, handleChange, pageSize, pageCount]);

  const handleClickPrevious = useCallback(() => {
    handleChange({
      pageIndex: Math.max(pageIndex - 1, 1),
      pageSize,
    });
  }, [pageIndex, handleChange, pageSize]);

  const handleChangePageSize = useCallback((event) => {
    handleChange({
      pageIndex: 1,
      pageSize: parseInt(_.chain(event.options).first().get('name').value(), 10),
    });
  }, [handleChange]);

  const handleClickNextPages = useCallback(() => {
    handleChange({
      pageIndex: pageIndex < 4 + siblingCount ? Math.min(4 + 3 * siblingCount, pageCount) : Math.min(pageIndex + siblingCount * 2 + 1, pageCount),
      pageSize,
    });
  }, [pageIndex, handleChange, pageSize, siblingCount, pageCount]);

  const handleClickPreviousPages = useCallback(() => {
    handleChange({
      pageIndex: pageIndex > pageCount - siblingCount - 3 ? Math.max(pageCount - 3 * siblingCount - 3, 1) : Math.max(pageIndex - siblingCount * 2 - 1, 1),
      pageSize,
    });
  }, [pageIndex, handleChange, pageSize, siblingCount, pageCount]);

  const getPageItemProps = useCallback((page) => {
    const json = {
      key: page,
      label: '...',
    };

    if (page === 'LeftDots') {
      return _.assign({}, json, { onClick: handleClickPreviousPages });
    }

    if (page === 'RightDots') {
      return _.assign({}, json, { onClick: handleClickNextPages });
    }

    return _.assign(json, {
      label: page.toString(),
      className: c({ selected: page === pageIndex }),
      onClick: () => handleChange({ pageIndex: page, pageSize }),
    });
  }, [pageIndex, handleChange, handleClickNextPages, handleClickPreviousPages, pageSize]);

  useEffect(() => {
    handleChange({ pageIndex, pageSize });
  }, [pageIndex, handleChange, pageSize]);

  return h(
    'div',
    { className: c(className, 'pagination-component') },
    enablePageSizeOptions && h(
      'div',
      { className: 'pagesize' },
      h(
        Select,
        {
          className: 'pagesize-selector',
          options: pageSizeOptions,
          onChange: handleChangePageSize,
          dropdownWidth: 'auto',
          value: selectedPageSize,
        },
      ),
      h(
        'div',
        { className: 'pagesize-label' },
        'records per page',
      ),
    ),
    h(
      'div',
      { className: 'page-selectors' },
      h(
        Button,
        {
          iconName: 'ChevronLeftMed',
          disabled: pageIndex === 1 || pageCount === 0,
          className: c({ disabled: pageIndex === 1 }),
          onClick: handleClickPrevious,
        },
      ),
      _.map(paginationRange, (page) => h(Button, getPageItemProps(page))),
      h(
        Button,
        {
          iconName: 'ChevronRightMed',
          disabled: pageIndex === pageCount || pageCount === 0,
          className: c({ disabled: pageIndex === pageCount }),
          onClick: handleClickNext,
        },
      ),
    ),
  );
};

PaginationPure.propTypes = {
  pageIndex: PropTypes.number,
  pageSize: PropTypes.number,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
  pageCount: PropTypes.number,
  siblingCount: PropTypes.number,
  enablePageSizeOptions: PropTypes.bool,
  onChange: PropTypes.func,
};

PaginationPure.defaultProps = {
  pageIndex: 1,
  pageSize: 10,
  pageSizeOptions: [10, 20, 50, 100, 200],
  pageCount: 0,
  siblingCount: 5,
  enablePageSizeOptions: true,
  onChange: _.noop,
};

const wrap = _.compose(
  (Component) => withStyles(Component, getStyles),
);

export const Pagination = wrap(PaginationPure);
