import _ from 'underscore';
import { serverContext } from '@cosman/server-context';

const settings = _.extend({}, serverContext);

const requiredSetting = (fieldName, config) => {
  if (!config[fieldName]) {
    throw new Error(['config ', fieldName], ' is required but not provided');
  }
};

const getInstrumentationSettings = () => {
  const config = _.extend({}, settings);

  config.logLevel = !config.logLevel ? 0 : parseInt(config.logLevel, 10);
  config.logEnableConsoleOutput = !config.logEnableConsoleOutput ? false : config.logEnableConsoleOutput === 'true';
  config.enableAppInsights = !config.enableAppInsights ? false : config.enableAppInsights === 'true';

  if (config.enableAppInsights) {
    requiredSetting('appInsightsInstrumentationKey', config);
  }

  return config;
};

const createConfiguration = () => {
  const modules = [];
  const configuration = {};

  const getConfiguratioin = (name) => {
    return configuration[name];
  };

  const addJsonFile = (json) => {
    _.assign(configuration, json);
    modules.push(json);
  };

  return {
    addJsonFile,
    getConfiguratioin,
  };
};

export const globalSettings = {
  version: settings.version || '0.0.1',
  environment: settings.environment || 'Development',
  instrumentationSettings: getInstrumentationSettings(),
  createConfiguration,
};
