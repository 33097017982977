/* eslint-disable no-console */

export class Observer {
  constructor() {
    const handlers = {};
    this.getHandlers = () => {
      return handlers;
    };
  }

  on(eventName, fn) {
    const handlers = this.getHandlers();
    if (handlers[eventName]) {
      handlers[eventName].push(fn);
    } else {
      handlers[eventName] = [fn];
    }
  }

  off(eventName, fn) {
    const handlers = this.getHandlers();
    const fns = handlers[eventName];
    if (fns) {
      for (let i = 0; i < fns.length; i += 1) {
        if (fns[i] === fn) {
          fns.splice(i, 1);
        }
      }
    }
  }

  trigger(eventName, ...params) {
    const handlers = this.getHandlers();
    const fns = handlers[eventName];
    if (fns) {
      for (let i = 0; i < fns.length; i += 1) {
        try {
          fns[i](params);
        } catch (e) {
          console.error(e);
        }
      }
    }
  }
}
