export const getStyles = () => ({
  alignItems: 'center',
  display: 'flex',

  '& .gap': {
    background: 'rgba(0, 0, 0, 0.1)',
    height: '20px',
    margin: '0px -1px',
    width: '2px',
  },

  '& .left-rounded .button-label': {
    borderTopLeftRadius: '32px',
    borderBottomLeftRadius: '32px',
  },

  '& .left-rounded .filter-value': {
    color: '#0078d4',
    fontWeight: '600',
  },

  '& .left-rounded.disabled .filter-value': {
    color: '#d0d0d0',
  },

  '& .right-rounded .button-label': {
    borderTopRightRadius: '32px',
    borderBottomRightRadius: '32px',
    fontSize: '12px',
  },

  '& .surrounded .button-label': {
    borderRadius: '32px',
  },
});
