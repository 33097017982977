import { useMemo } from 'react';
import moment from 'moment';

/**
 * Test if this time range is recent
 *
 * This is used to determine if NRT data should be used.
 * @param {string} startDate range start
 * @param {string} endDate range end
 * @param {number} firstDayThreshold the day after which the date is considered recent
 */
export const useIsRecentDay = (startDate, endDate, firstDayThreshold = 3) => {
  const isRecentDay = useMemo(() => {
    const startDateMoment = moment(startDate);
    const endDateMoment = moment(endDate);
    const recentThreshold = moment().subtract(firstDayThreshold, 'days');
    return startDateMoment.isAfter(recentThreshold) && endDateMoment.isAfter(recentThreshold);
  }, [startDate, endDate, firstDayThreshold]);
  return isRecentDay;
};

/**
 * Get the number of days in the time range
 *
 * @param {string} startDate start date
 * @param {string} endDate end date
 * @returns number
 */
export const useDateCount = (startDate, endDate) => {
  const dateCount = useMemo(() => {
    const startDateMoment = moment(startDate);
    const endDateMoment = moment(endDate);
    const diff = endDateMoment.diff(startDateMoment, 'days');
    return (diff < 0 ? 0 : diff) + 1;
  }, [startDate, endDate]);
  return dateCount;
};
