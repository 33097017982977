import _ from 'underscore';
import { mockDataActions } from '@cosman/mock';
import { v4 as generateGuid } from 'uuid';
import { mockPolicy } from '../../mock/cosman/mock-policy';
import { BaseService } from '../base-service';

export class CosmanEUService extends BaseService {
  constructor() {
    super({ serviceName: 'CosmanEU' });
  }

  injectMockPolicy() {
    mockDataActions(this.http, mockPolicy);
  }

  get(url, options) {
    const requestId = generateGuid();
    const method = 'get';

    this.logInfo({ requestId, url, options, method, status: 'Started' });

    return this.http.get(url, options).then((response) => {
      this.logInfo({ requestId, url, options, method, status: `Http ${response.status}` });
      return response.data;
    }).catch((e) => {
      this.logInfo({ requestId, url, options, method, status: `Http ${e.status}` });
      throw (e);
    });
  }

  post(url, payload, options) {
    const requestId = generateGuid();
    const method = 'post';

    this.logInfo({ requestId, url, payload, options, method, status: 'Started' });

    return this.http.post(url, payload, options).then((response) => {
      this.logInfo({ requestId, url, payload, options, method, status: `Http ${response.status}` });
      return response.data;
    }).catch((e) => {
      this.logInfo({ requestId, url, payload, options, method, status: `Http ${e.status}` });
      throw (e);
    });
  }

  acquireToken() {
    const acquiredToken = this.context.acquireToken('streamViewerToken');
    const accessToken = _.isFunction(acquiredToken) ? acquiredToken() : acquiredToken;

    return accessToken;
  }
}

export const cosmanEUService = new CosmanEUService();
