import _ from 'underscore';
import { useRef, useCallback } from 'react';

export const useDebounce = (callback, delay) => {
  const ref = useRef();
  ref.current = callback;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const result = useCallback(_.debounce((...params) => {
    return ref.current(params);
  }, delay), []);

  return result;
};
