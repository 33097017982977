import _ from 'underscore';
import s from 'underscore.string';
import { cosmanService as http, efficiencyEngineService } from '@cosman/data-actions';
import { useEffect, useState } from 'react';
import { sortBy } from './sortTableData';
import { nanColumnValue } from './consts';

// cosmosStorage, cosmosProcessing,blueshiftStorage,blueshiftProcessing
const getNoneLeafNodeCosmosAndBlueshiftData = ({
  nodeType,
  nodeId,
  startDate,
  endDate,
  granularity,
}) => {
  const validTopLevelFabrics = [ // Only these fabrics are valid for top level node, this is to avoid counting cost twice
    'cosmosStorage',
    'cosmosProcessing',
    'blueshiftStorage',
    'blueshiftProcessing',
  ];

  return http.get('/v1.0/cost/Overview/NoneLeafNode', {
    params: {
      nodeType,
      nodeId,
      startDate,
      endDate,
      granularity,
    },
  }).then((resp) => {
    const convertedCostByFabric = {};
    const convertedCostByOrg = {};
    _.mapObject(resp, ({ costByOrgs }, fabric) => {
      const dailyCostListByFabric = [];
      convertedCostByFabric[fabric] = {
        realizedCumulativeCost: _.reduce(costByOrgs, (memo, data) => {
          return memo + data.realizedCumulativeCost;
        }, 0),
        estimatedAnnualCost: _.reduce(costByOrgs, (memo, data) => { return memo + data.estimatedAnnualCost; }, 0),
        averageDailyUsage: _.reduce(costByOrgs, (memo, data) => { return memo + data.averageDailyUsage; }, 0),
      };
      _.mapObject(costByOrgs, (v, org) => {
        convertedCostByOrg[org] = convertedCostByOrg[org] || {
          realizedCumulativeCost: 0,
          estimatedAnnualCost: 0,
          averageDailyUsage: 0,
          serviceTreeId: v.serviceTreeId,
          nodeType: v.nodeType,
          owner: v.owner,
          dailyCost: [],
        };
        if (validTopLevelFabrics.indexOf(fabric) !== -1) { // Only these fabrics are valid for top level node, this is to avoid counting cost twice
          const orgData = convertedCostByOrg[org];
          _.each(v.dailyCost, (c) => {
            const point = { date: c.date.substring(0, 10), value: c.value };
            dailyCostListByFabric.push(point);
            orgData.dailyCost.push(point);
          });
          orgData.realizedCumulativeCost += v.realizedCumulativeCost;
          orgData.estimatedAnnualCost += v.estimatedAnnualCost;
          // below is used for table data
          orgData[fabric] = orgData[fabric] || {
            realizedCumulativeCost: 0,
            estimatedAnnualCost: 0,
            averageDailyUsage: 0,
          };
          const costOfOrgFabric = orgData[fabric];
          costOfOrgFabric.realizedCumulativeCost += v.realizedCumulativeCost;
          costOfOrgFabric.estimatedAnnualCost += v.estimatedAnnualCost;
          costOfOrgFabric.averageDailyUsage += v.averageDailyUsage;
        }
      });
      const costGroupByDate = _.groupBy(dailyCostListByFabric, (x) => (x.date));
      const costMap = _.mapObject(costGroupByDate, (v) => {
        return _.reduce(v, (memo, data) => { return memo + data.value || 0; }, 0);
      });
      convertedCostByFabric[fabric].dailyCost = _.map(costMap, (value, date) => {
        return { date, value };
      });
    });
    _.mapObject(convertedCostByOrg, ({ dailyCost }, org) => {
      const costGroupByDate = _.groupBy(dailyCost, (x) => (x.date));
      const costMap = _.mapObject(costGroupByDate, (v) => {
        return _.reduce(v, (memo, data) => { return memo + data.value || 0; }, 0);
      });
      convertedCostByOrg[org].dailyCost = _.map(costMap, (value, date) => {
        return { date, value };
      });
    });
    return {
      costByOrg: convertedCostByOrg,
      costByFabric: {
        'Cosmos Storage': convertedCostByFabric.cosmosStorage,
        'Cosmos Processing': convertedCostByFabric.cosmosProcessing,
        'Blueshift Storage': convertedCostByFabric.blueshiftStorage,
        'Blueshift Store': convertedCostByFabric.blueshiftStore,
        'Blueshift Read': convertedCostByFabric.blueshiftRead,
        'Blueshift Write': convertedCostByFabric.blueshiftWrite,
        'Blueshift Processing': convertedCostByFabric.blueshiftProcessing,
        'Blueshift Standard Token': convertedCostByFabric.blueshiftStandardToken,
        'Blueshift Eco Token': convertedCostByFabric.blueshiftEcoToken,
      },
    };
  });
};

const getNoneLeafNodeKustoCostData = (
  {
    nodeType,
    nodeId,
    startDate,
    endDate,
    granularity,
  },
) => {
  return efficiencyEngineService.get('/api/cost/orgcost', {
    params: {
      nodeType,
      nodeId,
      startDate,
      endDate,
      granularity,
      platformName: 'Kusto',
    },
  }).then(({ CostByOrgs }) => {
    const costByFabric = {};
    const costByOrg = {};
    const dateCostListByFabric = [];
    costByFabric.Kusto = {
      realizedCumulativeCost: 0,
      estimatedAnnualCost: 0,
      averageDailyUsage: 0,
    };
    _.mapObject(CostByOrgs, (v, org) => {
      const { Kusto } = costByFabric;
      Kusto.realizedCumulativeCost += v.RealizedCumulativeCost;
      Kusto.estimatedAnnualCost += v.EstimatedAnnualCost;
      Kusto.averageDailyUsage += v.averageDailyUsage;
      costByOrg[org] = costByOrg[org] || {
        realizedCumulativeCost: 0,
        estimatedAnnualCost: 0,
        averageDailyUsage: 0,
      };
      const orgData = costByOrg[org];
      orgData.serviceTreeId = v.ServiceTreeId;
      orgData.nodeType = v.NodeType;
      orgData.owner = v.Owner;
      orgData.dailyCost = _.map(v.DailyCost, (c) => {
        const point = { date: c.Date.substring(0, 10), value: c.Value };
        dateCostListByFabric.push(point);
        return point;
      });
      orgData.realizedCumulativeCost += v.RealizedCumulativeCost;
      orgData.estimatedAnnualCost += v.EstimatedAnnualCost;
      orgData.averageDailyUsage += v.averageDailyUsage || 0;
      orgData.kusto = {
        realizedCumulativeCost: 0,
        estimatedAnnualCost: 0,
        averageDailyUsage: 0,
      };
      const costOfOrgFabric = orgData.kusto;
      costOfOrgFabric.realizedCumulativeCost += v.RealizedCumulativeCost;
      costOfOrgFabric.estimatedAnnualCost += v.EstimatedAnnualCost;
      costOfOrgFabric.averageDailyUsage += v.averageDailyUsage || 0;
    });
    const costGroupByDate = _.groupBy(dateCostListByFabric, (x) => (x.date));
    const costMap = _.mapObject(costGroupByDate, (v) => {
      return _.reduce(v, (memo, data) => { return memo + data.value || 0; }, 0);
    });
    costByFabric.Kusto.dailyCost = _.map(costMap, (value, date) => {
      return { date, value };
    });
    return {
      costByOrg,
      costByFabric,
    };
  });
};

const getNoneLeafNodeAriaCostData = (
  {
    nodeType,
    nodeId,
    startDate,
    endDate,
    granularity,
  },
) => {
  return http.get('/v1.0/cost/Aria/NoneLeafNode', {
    params: {
      nodeType,
      nodeId,
      startDate,
      endDate,
      granularity,
    },
  }).then(({ ariaCostByOrgs }) => {
    const costByFabric = {};
    const costByOrg = {};
    const dateCostListByFabric = [];
    costByFabric.Aria = {
      realizedCumulativeCost: 0,
      estimatedAnnualCost: 0,
      averageDailyUsage: 0,
    };
    _.mapObject(ariaCostByOrgs, (v, org) => {
      const { Aria } = costByFabric;
      Aria.realizedCumulativeCost += v.realizedCumulativeCost;
      Aria.estimatedAnnualCost += v.estimatedAnnualCost;
      Aria.averageDailyUsage += v.averageDailyUsage;
      costByOrg[org] = costByOrg[org] || {
        realizedCumulativeCost: 0,
        estimatedAnnualCost: 0,
        averageDailyUsage: 0,
      };
      const orgData = costByOrg[org];
      orgData.serviceTreeId = v.serviceTreeId;
      orgData.nodeType = v.nodeType;
      orgData.owner = v.owner;
      orgData.dailyCost = _.map(v.dailyCost, (c) => {
        const point = { date: c.date.substring(0, 10), value: c.value };
        dateCostListByFabric.push(point);
        return point;
      });
      orgData.realizedCumulativeCost += v.realizedCumulativeCost;
      orgData.estimatedAnnualCost += v.estimatedAnnualCost;
      orgData.averageDailyUsage += v.averageDailyUsage || 0;
      orgData.aria = orgData.aria || {
        realizedCumulativeCost: 0,
        estimatedAnnualCost: 0,
        averageDailyUsage: 0,
      };
      const costOfOrgFabric = orgData.aria;
      costOfOrgFabric.realizedCumulativeCost += v.realizedCumulativeCost;
      costOfOrgFabric.estimatedAnnualCost += v.estimatedAnnualCost;
      costOfOrgFabric.averageDailyUsage += v.averageDailyUsage || 0;
    });
    const costGroupByDate = _.groupBy(dateCostListByFabric, (x) => (x.date));
    const costMap = _.mapObject(costGroupByDate, (v) => {
      return _.reduce(v, (memo, data) => { return memo + data.value || 0; }, 0);
    });
    costByFabric.Aria.dailyCost = _.map(costMap, (value, date) => {
      return { date, value };
    });
    return {
      costByOrg,
      costByFabric,
    };
  });
};

const getNoneLeafNodeMDMCostData = (
  {
    nodeType,
    nodeId,
    startDate,
    endDate,
    granularity,
  },
) => {
  return efficiencyEngineService.get('/api/cost/orgcost', {
    params: {
      nodeType,
      nodeId,
      startDate,
      endDate,
      granularity,
      platformName: 'MDM',
    },
  }).then(({ CostByOrgs }) => {
    const costByFabric = {};
    const costByOrg = {};
    const dateCostListByFabric = [];
    costByFabric.MDM = {
      realizedCumulativeCost: 0,
      estimatedAnnualCost: 0,
      averageDailyUsage: 0,
    };
    _.mapObject(CostByOrgs, (v, org) => {
      const { MDM } = costByFabric;
      MDM.realizedCumulativeCost += v.RealizedCumulativeCost;
      MDM.estimatedAnnualCost += v.EstimatedAnnualCost;
      MDM.averageDailyUsage += v.averageDailyUsage;
      costByOrg[org] = costByOrg[org] || {
        realizedCumulativeCost: 0,
        estimatedAnnualCost: 0,
        averageDailyUsage: 0,
      };
      const orgData = costByOrg[org];
      orgData.serviceTreeId = v.ServiceTreeId;
      orgData.nodeType = v.NodeType;
      orgData.owner = v.Owner;
      orgData.dailyCost = _.map(v.DailyCost, (c) => {
        const point = { date: c.Date.substring(0, 10), value: c.Value };
        dateCostListByFabric.push(point);
        return point;
      });
      orgData.realizedCumulativeCost += v.RealizedCumulativeCost;
      orgData.estimatedAnnualCost += v.EstimatedAnnualCost;
      orgData.averageDailyUsage += v.averageDailyUsage || 0;
      orgData.mDM = orgData.mDM || {
        realizedCumulativeCost: 0,
        estimatedAnnualCost: 0,
        averageDailyUsage: 0,
      };
      const costOfOrgFabric = orgData.mDM;
      costOfOrgFabric.realizedCumulativeCost += v.RealizedCumulativeCost;
      costOfOrgFabric.estimatedAnnualCost += v.EstimatedAnnualCost;
      costOfOrgFabric.averageDailyUsage += v.averageDailyUsage || 0;
    });
    const costGroupByDate = _.groupBy(dateCostListByFabric, (x) => (x.date));
    const costMap = _.mapObject(costGroupByDate, (v) => {
      return _.reduce(v, (memo, data) => { return memo + data.value || 0; }, 0);
    });
    costByFabric.MDM.dailyCost = _.map(costMap, (value, date) => {
      return { date, value };
    });
    return {
      costByOrg,
      costByFabric,
    };
  });
};

const getNoneLeafNodeMDSCostData = (
  {
    nodeType,
    nodeId,
    startDate,
    endDate,
    granularity,
  },
) => {
  return efficiencyEngineService.get('/api/cost/orgcost', {
    params: {
      nodeType,
      nodeId,
      startDate,
      endDate,
      granularity,
      platformName: 'Geneva',
    },
  }).then(({ CostByOrgs }) => {
    const costByFabric = {};
    const costByOrg = {};
    const dateCostListByFabric = [];
    costByFabric.MDS = {
      realizedCumulativeCost: 0,
      estimatedAnnualCost: 0,
      averageDailyUsage: 0,
    };
    _.mapObject(CostByOrgs, (v, org) => {
      const { MDS } = costByFabric;
      MDS.realizedCumulativeCost += v.RealizedCumulativeCost;
      MDS.estimatedAnnualCost += v.EstimatedAnnualCost;
      MDS.averageDailyUsage += v.averageDailyUsage;
      costByOrg[org] = costByOrg[org] || {
        realizedCumulativeCost: 0,
        estimatedAnnualCost: 0,
        averageDailyUsage: 0,
      };
      const orgData = costByOrg[org];
      orgData.serviceTreeId = v.ServiceTreeId;
      orgData.nodeType = v.NodeType;
      orgData.owner = v.Owner;
      orgData.dailyCost = _.map(v.DailyCost, (c) => {
        const point = { date: c.Date.substring(0, 10), value: c.Value };
        dateCostListByFabric.push(point);
        return point;
      });
      orgData.realizedCumulativeCost += v.RealizedCumulativeCost;
      orgData.estimatedAnnualCost += v.EstimatedAnnualCost;
      orgData.averageDailyUsage += v.averageDailyUsage || 0;
      orgData.mDS = orgData.mDS || {
        realizedCumulativeCost: 0,
        estimatedAnnualCost: 0,
        averageDailyUsage: 0,
      };
      const costOfOrgFabric = orgData.mDS;
      costOfOrgFabric.realizedCumulativeCost += v.RealizedCumulativeCost;
      costOfOrgFabric.estimatedAnnualCost += v.EstimatedAnnualCost;
      costOfOrgFabric.averageDailyUsage += v.averageDailyUsage || 0;
    });
    const costGroupByDate = _.groupBy(dateCostListByFabric, (x) => (x.date));
    const costMap = _.mapObject(costGroupByDate, (v) => {
      return _.reduce(v, (memo, data) => { return memo + data.value || 0; }, 0);
    });
    costByFabric.MDS.dailyCost = _.map(costMap, (value, date) => {
      return { date, value };
    });
    return {
      costByOrg,
      costByFabric,
    };
  });
};

const convertNoneLeafNodeData = (resp) => {
  if (!_.any(resp, ({ costByFabric }) => {
    return _.any(costByFabric, (v) => {
      return v.realizedCumulativeCost > 0;
    });
  })) {
    return {
      costTrendByFabric: {},
      costDistributionByFabric: {},
      costTrendByOrg: {},
      costDistributionByOrg: {},
      summaryTableDataByOrg: [],
      summaryTableDataByFabric: [],
    };
  }
  const costDistributionByFabric = _.chain(resp).pluck('costByFabric')
    .reduce((result, fabricData) => {
      _.mapObject(fabricData, (v, fabric) => {
        // eslint-disable-next-line no-param-reassign
        result[fabric] = v;
      });
      return result;
    }, {}).value();

  const costTrendByFabric = _.mapObject(costDistributionByFabric, (fabricData) => {
    const costGroupByDate = _.groupBy(fabricData.dailyCost, (x) => (x.date));
    const costMap = _.mapObject(costGroupByDate, (v) => {
      return _.reduce(v, (memo, data) => { return memo + data.value || 0; }, 0);
    });
    return {
      ...fabricData,
      dailyCost: _.map(costMap, (value, date) => {
        return { date, value };
      }),
    };
  });

  const totalRowByFabric = {
    name: 'Total',
    drillDownName: '',
    realizedCumulativeCost: 0,
    estimatedAnnualCost: 0,
    averageDailyUsage: nanColumnValue,
    estimatedAnnualUsage: nanColumnValue,
  };

  let summaryTableDataByFabric = _.map(costDistributionByFabric, (fabricData, fabric) => {
    const row = {
      name: fabric,
      drillDownName: '',
      realizedCumulativeCost: `${s.numberFormat(fabricData.realizedCumulativeCost, 0)}`,
      estimatedAnnualCost: `${s.numberFormat(fabricData.estimatedAnnualCost, 0)}`,
      averageDailyUsage: nanColumnValue,
      estimatedAnnualUsage: nanColumnValue,
    };
    switch (fabric) {
      case 'Cosmos Storage':
        row.averageDailyUsage = `${s.numberFormat(fabricData.averageDailyUsage, 0)} TiBs`;
        row.estimatedAnnualUsage = `${s.numberFormat(fabricData.averageDailyUsage, 0)} TiBs`;
        break;
      case 'Cosmos Processing':
        row.averageDailyUsage = `${s.numberFormat(fabricData.averageDailyUsage, 0)} Tokens`;
        row.estimatedAnnualUsage = `${s.numberFormat(fabricData.averageDailyUsage * 365, 0)} Tokens`;
        break;
      case 'Blueshift Storage':
        row.averageDailyUsage = nanColumnValue;
        row.estimatedAnnualUsage = nanColumnValue;
        row.drillDownName = 'Total';
        break;
      case 'Blueshift Store':
        row.averageDailyUsage = `${s.numberFormat(fabricData.averageDailyUsage, 0)} TiBs`;
        row.estimatedAnnualUsage = `${s.numberFormat(fabricData.averageDailyUsage, 0)} TiBs`;
        row.name = '';
        row.drillDownName = 'Store';
        break;
      case 'Blueshift Read':
        row.averageDailyUsage = fabricData.averageDailyUsage;
        row.estimatedAnnualUsage = `${s.numberFormat(row.averageDailyUsage * 365, 0)}`;
        row.averageDailyUsage = `${s.numberFormat(row.averageDailyUsage, 0)}`;
        row.name = '';
        row.drillDownName = 'Read Operation';
        break;
      case 'Blueshift Write':
        row.averageDailyUsage = fabricData.averageDailyUsage;
        row.estimatedAnnualUsage = `${s.numberFormat(row.averageDailyUsage * 365, 0)}`;
        row.averageDailyUsage = `${s.numberFormat(row.averageDailyUsage, 0)}`;
        row.name = '';
        row.drillDownName = 'Write Operation';
        break;
      case 'Blueshift Processing':
        row.averageDailyUsage = nanColumnValue;
        row.estimatedAnnualUsage = nanColumnValue;
        row.drillDownName = 'Total';
        break;
      case 'Blueshift Standard Token':
        row.averageDailyUsage = fabricData.averageDailyUsage;
        row.estimatedAnnualUsage = `${s.numberFormat(row.averageDailyUsage * 365, 0)} AUs`;
        row.averageDailyUsage = `${s.numberFormat(row.averageDailyUsage, 0)} AUs`;
        row.name = '';
        row.drillDownName = 'Standard Token';
        break;
      case 'Blueshift Eco Token':
        row.averageDailyUsage = fabricData.averageDailyUsage;
        row.estimatedAnnualUsage = `${s.numberFormat(row.averageDailyUsage * 365, 0)} AUs`;
        row.averageDailyUsage = `${s.numberFormat(row.averageDailyUsage, 0)} AUs`;
        row.name = '';
        row.drillDownName = 'Eco Token';
        break;
      default:
    }
    if (row.drillDownName !== 'Total') { // Avoid adding cost twice, only add top-level fabrics
      totalRowByFabric.realizedCumulativeCost += fabricData.realizedCumulativeCost;
      totalRowByFabric.estimatedAnnualCost += fabricData.estimatedAnnualCost;
    }
    return row;
  });
  totalRowByFabric.realizedCumulativeCost = s.numberFormat(totalRowByFabric.realizedCumulativeCost, 0);
  totalRowByFabric.estimatedAnnualCost = s.numberFormat(totalRowByFabric.estimatedAnnualCost, 0);
  summaryTableDataByFabric = [totalRowByFabric].concat(summaryTableDataByFabric);

  delete costDistributionByFabric['Blueshift Standard Token'];
  delete costTrendByFabric['Blueshift Standard Token'];
  delete costDistributionByFabric['Blueshift Eco Token'];
  delete costTrendByFabric['Blueshift Eco Token'];
  delete costDistributionByFabric['Blueshift Store'];
  delete costTrendByFabric['Blueshift Store'];
  delete costDistributionByFabric['Blueshift Read'];
  delete costTrendByFabric['Blueshift Read'];
  delete costDistributionByFabric['Blueshift Write'];
  delete costTrendByFabric['Blueshift Write'];

  const fabricKeyNameList = ['cosmosStorage', 'cosmosProcessing', 'blueshiftStorage', 'blueshiftProcessing', 'kusto', 'mDM', 'mDS', 'aria'];
  const costDistributionByOrg = {};
  _.map(resp, ({ costByOrg }) => {
    _.map(costByOrg, (OrgData, org) => {
      if (costDistributionByOrg[org] === undefined) {
        costDistributionByOrg[org] = {
          ...OrgData,
        };
      } else {
        const costOfOrg = costDistributionByOrg[org];
        costOfOrg.realizedCumulativeCost += OrgData.realizedCumulativeCost;
        costOfOrg.estimatedAnnualCost += OrgData.estimatedAnnualCost;
        costOfOrg.averageDailyUsage += OrgData.averageDailyUsage;
        costOfOrg.serviceTreeId = costOfOrg.serviceTreeId || OrgData.serviceTreeId;
        costOfOrg.nodeType = costOfOrg.nodeType || OrgData.nodeType;
        costOfOrg.owner = costOfOrg.owner || OrgData.owner;
        costOfOrg.dailyCost = costOfOrg.dailyCost.concat(OrgData.dailyCost);
        _.each(fabricKeyNameList, (fabric) => {
          if (OrgData[fabric]) {
            costOfOrg[fabric] = OrgData[fabric];
          }
        });
      }
    });
  });

  const costTrendByOrg = _.mapObject(costDistributionByOrg, (orgData) => {
    const costGroupByDate = _.groupBy(orgData.dailyCost, (x) => (x.date));
    const costMap = _.mapObject(costGroupByDate, (v) => {
      return _.reduce(v, (memo, data) => { return memo + data.value || 0; }, 0);
    });
    return {
      ...orgData,
      dailyCost: _.map(costMap, (value, date) => { // overwrite dailyCost.dailyCost
        return { date, value };
      }),
    };
  });

  const totalRowByOrg = {
    id: null,
    name: 'Total',
    nodeType: null,
    owner: '',
    totalRealizedCumulativeCost: 0,
  };

  _.each(fabricKeyNameList, (fabric) => {
    totalRowByOrg[`${fabric}RealizedCumulativeCost`] = 0;
  });

  let summaryTableDataByOrg = _.map(costDistributionByOrg, (orgData, org) => {
    const row = {
      id: orgData.serviceTreeId,
      name: org,
      nodeType: orgData.nodeType,
      owner: orgData.owner,
      totalRealizedCumulativeCost: 0,
    };
    _.each(fabricKeyNameList, (fabric) => {
      const cost = (orgData[fabric] && orgData[fabric].realizedCumulativeCost) || 0;
      row[`${fabric}RealizedCumulativeCost`] = parseInt(cost, 10);
      row.totalRealizedCumulativeCost += cost;
      totalRowByOrg[`${fabric}RealizedCumulativeCost`] += cost;
      totalRowByOrg.totalRealizedCumulativeCost += cost;
    });
    row.totalRealizedCumulativeCost = parseInt(row.totalRealizedCumulativeCost, 10);
    return row;
  });
  _.each(fabricKeyNameList, (fabric) => {
    totalRowByOrg[`${fabric}RealizedCumulativeCost`] = parseInt(totalRowByOrg[`${fabric}RealizedCumulativeCost`], 10);
  });
  totalRowByOrg.totalRealizedCumulativeCost = parseInt(totalRowByOrg.totalRealizedCumulativeCost, 10);
  summaryTableDataByOrg = [totalRowByOrg].concat(summaryTableDataByOrg);
  summaryTableDataByOrg = sortBy(summaryTableDataByOrg, 'totalRealizedCumulativeCost', false);

  return {
    costDistributionByFabric,
    costTrendByFabric,
    costDistributionByOrg,
    costTrendByOrg,
    summaryTableDataByOrg,
    summaryTableDataByFabric,
  };
};

export const useOverviewData = (nodeType, nodeId, startDate, endDate, granularity) => {
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [data, setData] = useState({});

  useEffect(() => {
    setIsDataLoading(true);
    const ref = { cancelled: false };
    const callback = () => { ref.cancelled = true; };

    if (!startDate || !endDate) {
      return callback;
    }
    Promise.all([
      getNoneLeafNodeCosmosAndBlueshiftData(
        {
          nodeType,
          nodeId,
          startDate,
          endDate,
          granularity,
        },
      ),
      getNoneLeafNodeKustoCostData(
        {
          nodeType,
          nodeId,
          startDate,
          endDate,
          granularity,
        },
      ),
      getNoneLeafNodeMDMCostData(
        {
          nodeType,
          nodeId,
          startDate,
          endDate,
          granularity,
        },
      ),
      getNoneLeafNodeMDSCostData(
        {
          nodeType,
          nodeId,
          startDate,
          endDate,
          granularity,
        },
      ),
      getNoneLeafNodeAriaCostData(
        {
          nodeType,
          nodeId,
          startDate,
          endDate,
          granularity,
        },
      ),
    ]).then((resp) => {
      if (ref.cancelled) {
        return;
      }
      setData(convertNoneLeafNodeData(resp));
      setIsDataLoading(false);
    }).catch(() => {
      if (!ref.cancelled) { // if error happened and request is not cancelled, then clear data and loading state
        setData({});
        setIsDataLoading(false);
      }
    });
    return callback;
  }, [startDate, endDate, nodeId, nodeType, granularity]);
  return [data, isDataLoading];
};
